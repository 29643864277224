import React, { useState } from "react";
import { Tooltip, Button, Modal, Space } from "antd";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";
import SellersDataModal from "./_sellers_data_modal";
// erdem gy
const ManualCalculation = ({
  setAsinFetcher,
  baseOffersData,
  targetOffersData,
  setBasePriceNotConverted,
  basePriceNotConverted,
  preferenceData,
  asin,
  mainData,
}) => {
  const [isSellerModal, setSellerModal] = useState(false);

  return (
    <>
      <SellersDataModal
        isSellerModal={isSellerModal}
        setSellerModal={setSellerModal}
        setAsinFetcher={setAsinFetcher}
      />

      <div className="product__cmp_info">
        <div className="lft__Card">
          <div className="crdd_hdr">
            <div className="crdd__title">
              <span>{translate("Buy Costs")}</span>
            </div>
          </div>

          <div className="mnll__box">
            <div className="calc__inppt">
              <div className="field__label">{translate("Buying Price")}</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="number"
                  placeholder={translate("10 etc.")}
                />
              </div>
            </div>
            <div className="calc__inppt">
              <div className="field__label">{translate("Inbound Cost")}</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="number"
                  placeholder={translate("10 etc.")}
                />
              </div>
            </div>
            <div className="calc__inppt">
              <div className="field__label">{translate("Currency")}</div>
              <div className="field__wrap">
                <div class="select__field">
                  <select
                    class="select nice-select app d_block"
                    name="currency"
                  >
                    <option value="USD">USD</option>
                    <option value="GBP">GBP</option>
                    <option value="TRY">TRY</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="mnll__fftter">
            <button className="button-stroke button-small onex_wl__button">
              {translate("Calculate")}
            </button>
          </div>
        </div>

        <div className="middle__icn">
          <img src="/assets/img/icons8-sorting-arrows-horizontal-64.png" />
        </div>

        <div className="rght__Card">
          <div className="crdd_hdr tt">
            <div className="crdd__title">
              <span>{translate("Target market")}</span>
              <a
                target="_blank"
                href={`https://${preferenceData?.target_domain}/dp/${asin}`}
              >
                {preferenceData?.target_domain}
              </a>
            </div>
          </div>

          <div className="smm_price gtth">
            <div className="calc__inppt">
              <div className="field__label">{translate("Selling Price")}</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="number"
                  placeholder={translate("10 etc.")}
                  value={25}
                />
              </div>
            </div>{" "}
            {targetOffersData?.currency}
          </div>

          <div className="divider__tt"></div>

          <div className="inf__fee_card">
            <div className="lft">
              <h5>{translate("Buybox Price")}</h5>
              <span>
                {targetOffersData?.buyBoxPrice || "N/A"}{" "}
                {targetOffersData?.currency}
              </span>
            </div>
            <div className="lft">
              <h5>{translate("Lowest Price")}</h5>
              <span>
                {targetOffersData?.lowestPrice || "N/A"}{" "}
                {targetOffersData?.currency}
              </span>
            </div>
            <div className="rght">
              <div className="rght_iitm">
                <h5>FBA</h5>
                <span>
                  {targetOffersData?.lowestFbaPrice || "N/A"}{" "}
                  {targetOffersData?.currency}
                </span>
              </div>
              <div className="rght_iitm">
                <h5>FBM</h5>
                <span>
                  {targetOffersData?.lowestFbmPrice || "N/A"}{" "}
                  {targetOffersData?.currency}
                </span>
              </div>
            </div>
          </div>

          <div className="inf__fee_card">
            <div className="lft">
              <h5>{translate("Total Offer Count")}</h5>
              <span>
                {targetOffersData?.fbaOffersCount +
                  targetOffersData?.fbmOffersCount}
              </span>
            </div>
            <div className="rght">
              <div className="rght_iitm">
                <h5>FBA</h5>
                <span>{targetOffersData?.fbaOffersCount}</span>
              </div>
              <div className="rght_iitm">
                <h5>FBM</h5>
                <span>{targetOffersData?.fbmOffersCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManualCalculation;

const translations = [
  {
    id: "Dashboard",
    EN: "Dashboard",
    TR: "Panel",
    DE: "Übersicht",
  },
  {
    id: "Overview2",
    EN: "Dashboard",
    TR: "Panel",
    DE: "Übersicht",
  },
  {
    id: "Chrome Store",
    EN: "Chrome Store",
    TR: "Chrome Mağazası",
    DE: "Chrome Store",
  },

  {
    id: "Marketplaces",
    EN: "Marketplaces",
    TR: "Pazar Yerleri",
    DE: "Marktplätze",
  },
  {
    id: "Home",
    EN: "Home",
    TR: "Ana Sayfa",
    DE: "Startseite",
  },
  {
    id: "About us",
    EN: "About us",
    TR: "Hakkımızda",
    DE: "Über uns",
  },
  {
    id: "About Us",
    EN: "About Us",
    TR: "Hakkımızda",
    DE: "Über uns",
  },
  {
    id: "Support",
    EN: "Support",
    TR: "Destek",
    DE: "Unterstützung",
  },
  {
    id: "Pricing",
    EN: "Pricing",
    TR: "Fiyatlandırma",
    DE: "Preise",
  },
  {
    id: "Settings",
    EN: "Settings",
    TR: "Ayarlar",
    DE: "Einstellungen",
  },
  {
    id: "My Plan",
    EN: "My Plan",
    TR: "Planım",
    DE: "Mein Plan",
  },
  {
    id: "Plan",
    EN: "Plan",
    TR: "Plan",
    DE: "Plan",
  },
  {
    id: "Tickets",
    EN: "Tickets",
    TR: "Destek Taleplerim",
    DE: "Tickets",
  },
  {
    id: "Tickets-mm",
    EN: "Tickets",
    TR: "Ticket",
    DE: "Tickets",
  },
  {
    id: "Notifications",
    EN: "Notifications",
    TR: "Bildirimler",
    DE: "Benachrichtigungen",
  },
  {
    id: "Blog Details",
    EN: "Blog Details",
    TR: "Blog Detayları",
    DE: "Blogdetails",
  },
  {
    id: "Privacy & Terms",
    EN: "Privacy & Terms",
    TR: "Gizlilik ve Şartlar",
    DE: "Datenschutz & Bedingungen",
  },
  {
    id: "Personal Info",
    EN: "Personal Info",
    TR: "Kişisel Bilgi",
    DE: "Persönliche Informationen",
  },
  {
    id: "Personal info",
    EN: "Personal info",
    TR: "Kişisel bilgi",
    DE: "Persönliche Informationen",
  },
  {
    id: "Security",
    EN: "Security",
    TR: "Güvenlik",
    DE: "Sicherheit",
  },
  {
    id: "Balance",
    EN: "Balance",
    TR: "Bakiye",
    DE: "Guthaben",
  },
  {
    id: "View all",
    EN: "View all",
    TR: "Tümü",
    DE: "Alle",
  },
  {
    id: "Clear here",
    EN: "Clear here",
    TR: "Temizle",
    DE: "Hier löschen",
  },
  {
    id: "Log out",
    EN: "Log out",
    TR: "Çıkış Yap",
    DE: "Abmelden",
  },
  {
    id: "Log Out",
    EN: "Log Out",
    TR: "Çıkış Yap",
    DE: "Abmelden",
  },
  {
    id: "Are you sure want to log out?",
    EN: "Are you sure want to log out?",
    TR: "Çıkış yapmak istediğinizden emin misiniz?",
    DE: "Möchten Sie sich wirklich abmelden?",
  },
  {
    id: "Logout successful!..",
    EN: "Logout successful!..",
    TR: "Çıkış başarılı!..",
    DE: "Abmeldung erfolgreich!..",
  },
  {
    id: "Compare and filter prices - Increase your revenue with Kepicker.",
    EN: "Compare and filter prices - Increase your revenue with Kepicker.",
    TR: "Fiyatları karşılaştırın ve filtreleyin - Kepicker ile gelirinizi artırın.",
    DE: "Preise vergleichen und filtern - Steigern Sie Ihren Umsatz mit Kepicker.",
  },
  {
    id: "Copyright © Kepicker LLC. All rights reserved",
    EN: "Copyright © Kepicker LLC. All rights reserved",
    TR: "Telif Hakkı © Kepicker LLC. Tüm hakları saklıdır.",
    DE: "Urheberrecht © Kepicker LLC. Alle Rechte vorbehalten",
  },
  {
    id: "Mark all as read",
    EN: "Mark all as read",
    TR: "Hepsini okundu olarak işaretle",
    DE: "Alle als gelesen markieren",
  },
  {
    id: "Clear all",
    EN: "Clear all",
    TR: "Hepsini temizle",
    DE: "Alle löschen",
  },
  {
    id: "Notifications are marked as read!..",
    EN: "Notifications are marked as read!..",
    TR: "Bildirimler okundu olarak işaretlendi!..",
    DE: "Benachrichtigungen wurden als gelesen markiert!..",
  },
  {
    id: "Notifications have been cleared successfully!..",
    EN: "Notifications have been cleared successfully!..",
    TR: "Bildirimler başarıyla temizlendi!..",
    DE: "Benachrichtigungen wurden erfolgreich gelöscht!..",
  },
  {
    id: "Failure to Pay!",
    EN: "Failure to Pay!",
    TR: "Ödeme Başarısız!",
    DE: "Zahlung fehlgeschlagen!",
  },
  {
    id: "Payment Declined!",
    EN: "Payment Declined!",
    TR: "Ödeme Reddedildi!",
    DE: "Zahlung abgelehnt!",
  },
  {
    id: "There was an issue with your payment. Try again later.",
    EN: "There was an issue with your payment. Try again later.",
    TR: "Ödemenizde bir sorun yaşandı. Daha sonra tekrar deneyin.",
    DE: "Bei Ihrer Zahlung gab es ein Problem. Versuchen Sie es später erneut.",
  },
  {
    id: "Try Again",
    EN: "Try Again",
    TR: "Yeniden Deneyin",
    DE: "Erneut versuchen",
  },
  {
    id: "Congratulation!",
    EN: "Congratulation!",
    TR: "Tebrikler!",
    DE: "Herzlichen Glückwunsch!",
  },
  {
    id: "Payment Successful!",
    EN: "Payment Successful!",
    TR: "Ödeme Başarılı!",
    DE: "Zahlung erfolgreich!",
  },
  {
    id: "Product",
    EN: "Product",
    TR: "Ürün",
    DE: "Produkt",
  },
  {
    id: "Billing cycle",
    EN: "Billing cycle",
    TR: "Fatura Dönemi",
    DE: "Abrechnungszyklus",
  },
  {
    id: "Monthly",
    EN: "Monthly",
    TR: "Aylık",
    DE: "Monatlich",
  },
  {
    id: "Transaction ID",
    EN: "Transaction ID",
    TR: "İşlem Kimliği",
    DE: "Transaktions-ID",
  },
  {
    id: "Date",
    EN: "Date",
    TR: "Tarih",
    DE: "Datum",
  },
  {
    id: "Amount Paid",
    EN: "Amount Paid",
    TR: "Ödenen Tutar",
    DE: "Bezahlter Betrag",
  },
  {
    id: "Payment method",
    EN: "Payment method",
    TR: "Ödeme Yöntemi",
    DE: "Zahlungsmethode",
  },
  {
    id: "Credit card",
    EN: "Credit card",
    TR: "Kredi Kartı",
    DE: "Kreditkarte",
  },
  {
    id: "Go to your plan",
    EN: "Go to your plan",
    TR: "Planınıza gidin",
    DE: "Gehe zu deinem Plan",
  },
  {
    id: "Price",
    EN: "Price",
    TR: "Fiyat",
    DE: "Preis",
  },
  {
    id: "Product Price",
    EN: "Product Price",
    TR: "Ürün Fiyatı",
    DE: "Produktpreis",
  },
  {
    id: "Price Type",
    EN: "Price Type",
    TR: "Fiyat Türü",
    DE: "Preisart",
  },
  {
    id: "Checkout Now",
    EN: "Checkout Now",
    TR: "Şimdi Öde",
    DE: "Jetzt bezahlen",
  },
  {
    id: "Buy",
    EN: "Buy",
    TR: "Satın Al",
    DE: "Kaufen",
  },
  {
    id: "Tokens",
    EN: "Tokens",
    TR: "Jetonlar",
    DE: "Tokens",
  },
  {
    id: "Please choose the amount you need.",
    EN: "Please choose the amount you need.",
    TR: "İhtiyacınız olan miktarı seçiniz.",
    DE: "Bitte wählen Sie die benötigte Menge aus.",
  },
  {
    id: "Settings have been saved successfully!..",
    EN: "Settings have been saved successfully!..",
    TR: "Ayarlar başarıyla kaydedildi!..",
    DE: "Die Einstellungen wurden erfolgreich gespeichert!..",
  },
  {
    id: "Marketplace settings",
    EN: "Marketplace settings",
    TR: "Pazar Yeri Ayarları",
    DE: "Marktplatzeinstellungen",
  },
  {
    id: "Your adjustments here will also be applied to your extension.",
    EN: "Your adjustments here will also be applied to your extension.",
    TR: "Burada yapacağınız ayarlar aynı zamanda uzantınıza da uygulanacaktır.",
    DE: "Ihre Anpassungen hier werden auch auf Ihre Erweiterung angewendet.",
  },
  {
    id: "General settings",
    EN: "General settings",
    TR: "Genel Ayarlar",
    DE: "Allgemeine Einstellungen",
  },
  {
    id: "Shipping deal",
    EN: "Shipping deal",
    TR: "Kargo Anlaşması",
    DE: "Versandvereinbarung",
  },
  {
    id: "Storefront / Seller / Merchant ID",
    EN: "Storefront / Seller / Merchant ID",
    TR: "Mağaza ID / Satıcı Kimliği",
    DE: "Schaufenster / Verkäufer / Händler-ID",
  },
  {
    id: "If you want your Amazon storefront to be protected by our extension, you need to enter your storefront ID here. If you want to know how to get your storefront ID, click on the icon.",
    EN: "If you want your Amazon storefront to be protected by our extension, you need to enter your storefront ID here. If you want to know how to get your storefront ID, click on the icon.",
    TR: "Amazon mağaza ön yüzünüzün uzantımız tarafından korunmasını istiyorsanız, mağaza ID'nizi buraya girmeniz gerekmektedir. Mağaza ID'nizi nasıl alacağınızı öğrenmek istiyorsanız, simgeye tıklayın.",
    DE: "Wenn Sie möchten, dass Ihr Amazon-Schaufenster von unserer Erweiterung geschützt wird, müssen Sie Ihre Schaufenster-ID hier eingeben. Wenn Sie wissen möchten, wie Sie Ihre Schaufenster-ID erhalten, klicken Sie auf das Symbol.",
  },
  {
    id: "Base Market",
    EN: "Base Market",
    TR: "Ana Pazar",
    DE: "Basismarkt",
  },
  {
    id: "Target Market",
    EN: "Target Market",
    TR: "Hedef Pazar",
    DE: "Zielmarkt",
  },
  {
    id: "Connect Marketplaces",
    EN: "Connect Marketplaces",
    TR: "Pazar Yerlerini Bağla",
    DE: "Marktplätze verbinden",
  },
  {
    id: "Notification settings",
    EN: "Notification settings",
    TR: "Bildirim Ayarları",
    DE: "Benachrichtigungseinstellungen",
  },
  {
    id: "You can manage your notification settings here.",
    EN: "You can manage your notification settings here.",
    TR: "Bildirim ayarlarınızı buradan yönetebilirsiniz.",
    DE: "Sie können Ihre Benachrichtigungseinstellungen hier verwalten.",
  },
  {
    id: "Enter your address",
    EN: "Enter your address",
    TR: "Adresinizi girin",
    DE: "Geben Sie Ihre Adresse ein",
  },
  {
    id: "Address Details",
    EN: "Address Details",
    TR: "Adres Detayları",
    DE: "Adressdetails",
  },
  {
    id: "Email",
    EN: "Email",
    TR: "E-posta",
    DE: "E-Mail",
  },
  {
    id: "Phone",
    EN: "Phone",
    TR: "Telefon",
    DE: "Telefon",
  },
  {
    id: "Phone number",
    EN: "Phone number",
    TR: "Telefon numarası",
    DE: "Telefonnummer",
  },
  {
    id: "Enter your last name",
    EN: "Enter your last name",
    TR: "Soyadınızı girin",
    DE: "Ihren Nachnamen eingeben",
  },
  {
    id: "Last Name",
    EN: "Last Name",
    TR: "Soyadı",
    DE: "Nachname",
  },
  {
    id: "Enter your first name",
    EN: "Enter your first name",
    TR: "Adınızı girin",
    DE: "Geben Sie Ihren Vornamen ein",
  },
  {
    id: "First Name",
    EN: "First Name",
    TR: "Adı",
    DE: "Vorname",
  },
  {
    id: "Account info",
    EN: "Account info",
    TR: "Hesap bilgisi",
    DE: "Konto-Info",
  },
  {
    id: "We would like to know you better and offer better solutions to your problems.",
    EN: "We would like to know you better and offer better solutions to your problems.",
    TR: "Sizi daha iyi tanımak ve sorunlarınıza daha iyi çözümler sunmak isteriz.",
    DE: "Wir möchten Sie besser kennenlernen und bessere Lösungen für Ihre Probleme anbieten.",
  },
  {
    id: "Enter your old Password",
    EN: "Enter your old Password",
    TR: "Eski şifrenizi girin",
    DE: "Geben Sie Ihr altes Passwort ein",
  },
  {
    id: "Old Password",
    EN: "Old Password",
    TR: "Eski Şifre",
    DE: "Altes Passwort",
  },
  {
    id: "New Password",
    EN: "New Password",
    TR: "Yeni Şifre",
    DE: "Neues Passwort",
  },
  {
    id: "Enter your new Password",
    EN: "Enter your new Password",
    TR: "Yeni şifrenizi girin",
    DE: "Geben Sie Ihr neues Passwort ein",
  },
  {
    id: "Change password",
    EN: "Change password",
    TR: "Şifre değiştir",
    DE: "Passwort ändern",
  },
  {
    id: "Do not forget to choose strong passwords for your security.",
    EN: "Do not forget to choose strong passwords for your security.",
    TR: "Güvenliğiniz için güçlü şifreler seçmeyi unutmayın.",
    DE: "Ihre Sicherheit erfordert die Auswahl starker Passwörter.",
  },
  {
    id: "Login and security",
    EN: "Login and security",
    TR: "Giriş ve güvenlik",
    DE: "Anmeldung und Sicherheit",
  },
  {
    id: "Redirecting to login page in 1 second..",
    EN: "Redirecting to login page in 1 second..",
    TR: "1 saniye içinde giriş sayfasına yönlendiriliyor..",
    DE: "In 1 Sekunde zur Anmeldeseite umleiten..",
  },
  {
    id: "Warning",
    EN: "Warning",
    TR: "Uyarı",
    DE: "Warnung",
  },
  {
    id: "Please login again",
    EN: "Please login again",
    TR: "Lütfen yeniden giriş yapın",
    DE: "Bitte melden Sie sich erneut an",
  },
  {
    id: "Something went wrong!",
    EN: "Something went wrong!",
    TR: "Bir şeyler ters gitti!",
    DE: "Etwas ist schief gelaufen!",
  },
  {
    id: "Wait for:",
    EN: "Wait for:",
    TR: "Bekle:",
    DE: "Warte:",
  },
  {
    id: "seconds...",
    EN: "seconds...",
    TR: "saniye...",
    DE: "Sekunden...",
  },
  {
    id: "Please enter a valid email address!",
    EN: "Please enter a valid email address!",
    TR: "Lütfen geçerli bir e-posta adresi girin!",
    DE: "Bitte geben Sie eine gültige E-Mail-Adresse ein!",
  },
  {
    id: "Password must contain at least one lowercase letter!",
    EN: "Password must contain at least one lowercase letter!",
    TR: "Şifre en az bir küçük harf içermelidir!",
    DE: "Das Passwort muss mindestens einen Kleinbuchstaben enthalten!",
  },
  {
    id: "Password must contain at least one number!",
    EN: "Password must contain at least one number!",
    TR: "Şifre en az bir rakam içermelidir!",
    DE: "Das Passwort muss mindestens eine Zahl enthalten!",
  },
  {
    id: "Passwords must match!",
    EN: "Passwords must match!",
    TR: "Şifreler uyuşmalıdır!",
    DE: "Passwörter müssen übereinstimmen!",
  },
  {
    id: "Password must be at least 8 characters long!",
    EN: "Password must be at least 8 characters long!",
    TR: "Şifre en az 8 karakter uzunluğunda olmalıdır!",
    DE: "Das Passwort muss mindestens 8 Zeichen lang sein!",
  },
  {
    id: "Please enter an email address!",
    EN: "Please enter an email address!",
    TR: "Lütfen bir e-posta adresi girin!",
    DE: "Bitte geben Sie eine E-Mail-Adresse ein!",
  },
  {
    id: "Password must contain at least one letter!",
    EN: "Password must contain at least one letter!",
    TR: "Şifre en az bir harf içermelidir!",
    DE: "Das Passwort muss mindestens einen Buchstaben enthalten!",
  },
  {
    id: "Passwords do not match!",
    EN: "Passwords do not match!",
    TR: "Şifreler uyuşmuyor!",
    DE: "Passwörter stimmen nicht überein!",
  },
  {
    id: "About",
    EN: "About",
    TR: "Hakkında",
    DE: "Über",
  },
  {
    id: "Big plus to your productivity with Kepicker tools - Kepicker simplifies product research and It helps improve Amazon marketing strategies.",
    EN: "Big plus to your productivity with Kepicker tools - Kepicker simplifies product research and It helps improve Amazon marketing strategies.",
    TR: "Kepicker araçlarıyla üretkenliğinize büyük katkı - Kepicker ürün araştırmasını basitleştirir ve Amazon pazarlama stratejilerini geliştirmeye yardımcı olur.",
    DE: "Großer Pluspunkt für Ihre Produktivität mit Kepicker-Tools - Kepicker vereinfacht die Produktrecherche und hilft bei der Verbesserung der Amazon-Marketingstrategien.",
  },
  {
    id: "Find the most profitable products to sell on Amazon.",
    EN: "Find the most profitable products to sell on Amazon.",
    TR: "Amazon'da Satabileceğiniz EN KARLI Ürünleri Bulun!",
    DE: "Finden Sie die profitabelsten Produkte, um auf Amazon zu verkaufen.",
  },
  {
    id: "Kepicker simplifies product research and",
    EN: "Kepicker simplifies product research and",
    TR: "Kepicker ürün araştırmasını basitleştirir ve",
    DE: "Kepicker vereinfacht die Produktrecherche und",
  },
  {
    id: "It helps improve marketing strategies.",
    EN: "It helps improve marketing strategies.",
    TR: "Pazarlama stratejilerini geliştirmeye yardımcı olur.",
    DE: "Marketingstrategien zu verbessern.",
  },
  {
    id: "Big plus to your productivity",
    EN: "Big plus to your productivity",
    TR: "En Yeni Amazon Ürün Araştırma Aracı",
    DE: "Großer Pluspunkt für Ihre Produktivität",
  },
  {
    id: "At the heart of our enterprise lies a dedicated team of seasoned software developers, accomplished designers, and Amazon aficionados. Our overarching mission is to furnish Amazon users with a comprehensive, all-in-one solution meticulously crafted to address their diverse requirements.",
    EN: "At the heart of our enterprise lies a dedicated team of seasoned software developers, accomplished designers, and Amazon aficionados. Our overarching mission is to furnish Amazon users with a comprehensive, all-in-one solution meticulously crafted to address their diverse requirements.",
    TR: "İşletmemizin merkezinde; deneyimli yazılım geliştiricileri, başarılı tasarımcılar ve profesyonel Amazon satıcılarından oluşan özverili bir ekip yer almaktadır. Temel amaçlarımızdan biri, Amazon kullanıcılarının çeşitli ihtiyaçlarını karşılamak için özenle hazırlanmış kapsamlı bir çözüm sunmaktır.",
    DE: "Im Herzen unseres Unternehmens befindet sich ein engagiertes Team erfahrener Softwareentwickler, erfahrener Designer und Amazon-Aficionados. Unsere übergreifende Mission besteht darin, Amazon-Nutzern eine umfassende, alles-in-einem-Lösung zu bieten, die sorgfältig entwickelt wurde, um ihre vielfältigen Anforderungen zu erfüllen.",
  },
  {
    id: "We remain unwavering in our commitment to the continual refinement and expansion of our software platform.",
    EN: "We remain unwavering in our commitment to the continual refinement and expansion of our software platform.",
    TR: "Yazılım platformumuzu sürekli olarak geliştirmeye ve genişletmeye olan bağlılığımızda kararlıyız.",
    DE: "Wir bleiben in unserem Engagement für die kontinuierliche Weiterentwicklung und Erweiterung unserer Softwareplattform unerschütterlich.",
  },
  {
    id: "In its initial phase, our system empowers users with the ability to effortlessly extract their product listings, facilitating in-depth comparisons with various Amazon marketplaces. We provide vital information, including real-time stock status, accurate pricing details, and other pivotal product particulars.",
    EN: "In its initial phase, our system empowers users with the ability to effortlessly extract their product listings, facilitating in-depth comparisons with various Amazon marketplaces. We provide vital information, including real-time stock status, accurate pricing details, and other pivotal product particulars.",
    TR: "Başlangıç aşamasında, sistemimiz kullanıcılara ürün listelerini zahmetsizce çıkarma yeteneği sunarken, çeşitli Amazon pazarlarında derinlemesine karşılaştırmalar yapmayı kolaylaştırıyor. Gerçek zamanlı stok durumu, doğru fiyatlandırma detayları ve diğer önemli ürün bilgileri dahil olmak üzere, hayati öneme sahip bilgiler sağlıyoruz.",
    DE: "In der Anfangsphase ermöglicht unser System den Benutzern, ihre Produktlisten mühelos zu extrahieren und detaillierte Vergleiche mit verschiedenen Amazon-Marktplätzen durchzuführen. Wir stellen wichtige Informationen zur Verfügung, einschließlich Echtzeit-Lagerbestand, genaue Preisdetails und andere entscheidende Produktinformationen.",
  },
  {
    id: "Looking forward, our strategic vision entails harnessing state-of-the-art artificial intelligence integrated within our software, coupled with the immense potential of big data. This innovation will enable us to guide newcomers to the Amazon landscape who may be navigating uncharted waters in the realm of plugins. Our overarching objective is to offer curated recommendations for high-performing products, simplifying the wealth accumulation process while elevating the Amazon marketplace to unparalleled heights.",
    EN: "Looking forward, our strategic vision entails harnessing state-of-the-art artificial intelligence integrated within our software, coupled with the immense potential of big data. This innovation will enable us to guide newcomers to the Amazon landscape who may be navigating uncharted waters in the realm of plugins. Our overarching objective is to offer curated recommendations for high-performing products, simplifying the wealth accumulation process while elevating the Amazon marketplace to unparalleled heights.",
    TR: "Geleceğe yönelik stratejik vizyonumuz, yazılımımıza entegre edilmiş son teknoloji yapay zeka ile büyük verinin geniş potansiyelini birleştiriyor. Bu yenilikçi yaklaşım, Amazon ortamında daha önce keşfedilmemiş alanlarda yeni başlayanlara rehberlik etmemizi sağlayacak. Genel amaçlarımız arasında, yüksek performanslı ürünler için özenle seçilmiş öneriler sunmak ve Amazon pazarını benzersiz bir seviyeye taşırken zenginlik biriktirme sürecini basitleştirmek bulunmaktadır.",
    DE: "Unser strategischer Ausblick sieht vor, modernste künstliche Intelligenz in unsere Software zu integrieren, gekoppelt mit dem immensen Potenzial von Big Data. Diese Innovation wird es uns ermöglichen, Neulinge im Amazon-Landschaft zu führen, die sich möglicherweise in den unerforschten Gewässern des Plugin-Reichs bewegen. Unser übergreifendes Ziel ist es, kuratierte Empfehlungen für leistungsstarke Produkte anzubieten, den Prozess der Vermögensbildung zu vereinfachen und gleichzeitig den Amazon-Marktplatz auf unerreichte Höhen zu heben.",
  },
  {
    id: "We take immense pride in delivering a feature-rich experience that remains competitively priced in contrast to rival offerings. During the beta phase, should any unforeseen issues arise, please do not hesitate to contact us. We stand ready to promptly address any losses incurred due to errors, prioritizing your experience above all else.",
    EN: "We take immense pride in delivering a feature-rich experience that remains competitively priced in contrast to rival offerings. During the beta phase, should any unforeseen issues arise, please do not hesitate to contact us. We stand ready to promptly address any losses incurred due to errors, prioritizing your experience above all else.",
    TR: "Rakip tekliflere kıyasla rekabetçi fiyatlar sunmayı ve özellikler açısından zengin bir deneyim sağlamayı gururla taahhüt ediyoruz. Beta aşamasında beklenmedik sorunlar yaşanırsa, lütfen bize başvurmakta tereddüt etmeyin. Hatalardan kaynaklanan herhangi bir zararı hızla çözmeye hazırız ve deneyiminizi her şeyin üzerinde tutuyoruz.",
    DE: "Wir sind unglaublich stolz darauf, eine funktionsreiche Erfahrung zu liefern, die im Vergleich zu konkurrierenden Angeboten wettbewerbsfähig ist. Während der Beta-Phase zögern Sie bitte nicht, uns zu kontaktieren, sollten unvorhergesehene Probleme auftreten. Wir sind bereit, Verluste aufgrund von Fehlern schnell zu beheben und stellen Ihre Erfahrung über alles andere.",
  },
  {
    id: "We extend our sincerest well-wishes to all, as you embark on a prosperous and rewarding journey in the world of online sales.",
    EN: "We extend our sincerest well-wishes to all, as you embark on a prosperous and rewarding journey in the world of online sales.",
    TR: "Tüm kullanıcılarımıza içtenlikle en iyi dileklerimizi sunuyoruz ve çevrimiçi satış dünyasında başarılı ve ödüllendirici bir yolculuğa çıkarken sizlere başarılar diliyoruz.",
    DE: "Wir senden allen unsere aufrichtigsten Glückwünsche, während Sie sich auf eine erfolgreiche und lohnende Reise in die Welt des Online-Verkaufs begeben.",
  },
  {
    id: "Have more time to focus on other things. Our extension is designed for everyone. From beginners to advanced. No need to be an expert to get more earnings.",
    EN: "Have more time to focus on other things. Our extension is designed for everyone. From beginners to advanced. No need to be an expert to get more earnings.",
    TR: "Fazla zamanınızı diğer şeylere odaklanmak için ayırın. Uzantımız herkes için tasarlanmıştır. Başlangıçtan ileri seviyeye.. Daha fazla kazanmak için çok profesyonel olmanıza gerek yok. Zaten asıl konu doğru ürünü bulmak!",
    DE: "Haben Sie mehr Zeit, sich auf andere Dinge zu konzentrieren. Unsere Erweiterung ist für alle konzipiert. Vom Anfänger bis zum Fortgeschrittenen. Sie müssen kein Experte sein, um mehr zu verdienen.",
  },
  {
    id: "Why use Kepicker?",
    EN: "Why use Kepicker?",
    TR: "Neden Kepicker Aracını Kullanmalısınız?",
    DE: "Warum Kepicker nutzen?",
  },
  {
    id: "Deliver the results that matter most!",
    EN: "Deliver the results that matter most!",
    TR: "En önemli sonuçları sunun!",
    DE: "Liefern Sie die wichtigsten Ergebnisse!",
  },
  {
    id: "Empowered Productivity",
    EN: "Empowered Productivity",
    TR: "Kolay ve Pratik",
    DE: "Gestärkte Produktivität",
  },
  {
    id: "Get rid of complexity with its modern & user-friendly interface.",
    EN: "Get rid of complexity with its modern & user-friendly interface.",
    TR: "Modern ve kullanıcı dostu arayüzü ile karmaşıklıktan kurtulun.",
    DE: "Befreien Sie sich von Komplexität mit seiner modernen und benutzerfreundlichen Oberfläche.",
  },
  {
    id: "Faster Than Others",
    EN: "Faster Than Others",
    TR: "Daha Hızlı",
    DE: "Schneller",
  },
  {
    id: "It works faster than similar extensions.",
    EN: "It works faster than similar extensions.",
    TR: "Benzer uzantılardan daha hızlı çalışır.",
    DE: "Es funktioniert schneller als ähnliche Erweiterungen.",
  },
  {
    id: "Save Time",
    EN: "Save Time",
    TR: "Zamandan Tasarruf",
    DE: "Zeit sparen",
  },
  {
    id: "It will help you find the right products as soon as possible.",
    EN: "It will help you find the right products as soon as possible.",
    TR: "Size en kısa sürede doğru ürünleri bulmanıza yardımcı olur.",
    DE: "Es hilft Ihnen, die richtigen Produkte so schnell wie möglich zu finden.",
  },
  {
    id: "Start growing your Amazon account today - increase leads.",
    EN: "Start growing your Amazon account today - increase leads.",
    TR: "Amazon hesabınızı bugün büyütmeye başlayın - potansiyel müşteri sayınızı artırın.",
    DE: "Beginnen Sie noch heute mit dem Wachstum Ihres Amazon-Kontos - erhöhen Sie die Leads.",
  },
  {
    id: "Follow Us on Social Media",
    EN: "Follow Us on Social Media",
    TR: "Bizi Sosyal Medyada Takip Edin",
    DE: "Folge uns in den sozialen Medien",
  },
  {
    id: "Stay up to date with us!",
    EN: "Stay up to date with us!",
    TR: "Her zaman yeniliklerimizden haberdar olun!",
    DE: "Bleiben Sie auf dem Laufenden mit uns!",
  },
  {
    id: "Learn More",
    EN: "Learn More",
    TR: "Daha Fazla Bilgi Edinin",
    DE: "Mehr erfahren",
  },
  {
    id: "Learn more",
    EN: "Learn more",
    TR: "Daha fazla bilgi edinin",
    DE: "Mehr erfahren",
  },
  {
    id: "Great way to grow your earnings without spending too much time and money.",
    EN: "Great way to grow your earnings without spending too much time and money.",
    TR: "Çok fazla zaman ve para harcamadan kazancınızı artırmanın harika bir yolu.",
    DE: "Eine großartige Möglichkeit, Ihre Einnahmen zu steigern, ohne zu viel Zeit und Geld auszugeben.",
  },
  {
    id: "Start growing your earnings!",
    EN: "Start growing your earnings!",
    TR: "Kazancınızı artırmaya başlayın!",
    DE: "Beginnen Sie mit dem Wachstum Ihrer Einnahmen!",
  },
  {
    id: "The newest software in class, design and business value.",
    EN: "The newest software in class, design and business value.",
    TR: "Sınıfının en yeni yazılımı; kullanımı kolay ve pratik.",
    DE: "Die Neueste Software! In Design und Geschäftswert in der Klasse.",
  },
  {
    id: "Let’s Talk",
    EN: "Let’s Talk",
    TR: "E-posta Gönderin",
    DE: "Lass uns reden",
  },
  {
    id: "Let our product specialists help you pick a perfect plan according to your team, requirements and use case.",
    EN: "Let our product specialists help you pick a perfect plan according to your team, requirements and use case.",
    TR: "Uzmanlarımız; gereksinimleriniz ve kullanım durumunuza göre uygun paketi seçmenize yardımcı olabilir.",
    DE: "Lassen Sie unsere Produktspezialisten Ihnen bei der Auswahl eines perfekten Plans entsprechend Ihrem Team, Ihren Anforderungen und Ihrem Anwendungsfall helfen.",
  },
  {
    id: "Not sure which pricing plan to pick?",
    EN: "Not sure which pricing plan to pick?",
    TR: "Hangi paketi seçeceğinize karar veremediniz mi?",
    DE: "Nicht sicher, welches Paket Sie wählen sollen?",
  },
  {
    id: "Let's code",
    EN: "Let's code",
    TR: "İletişime Geçin",
    DE: "Lass uns programmieren",
  },
  {
    id: "Or have an idea? Let's see if we can work together.",
    EN: "Or have an idea? Let's see if we can work together.",
    TR: "Ya da bir fikriniz mi var? Birlikte çalışabiliriz.",
    DE: "Oder haben Sie eine Idee? Mal sehen, ob wir zusammenarbeiten können.",
  },
  {
    id: "Looking for a custom solution?",
    EN: "Looking for a custom solution?",
    TR: "Özel bir çözüm mü arıyorsunuz?",
    DE: "Eine maßgeschneiderte Lösung suchen?",
  },
  {
    id: "Downgrade plan",
    EN: "Downgrade plan",
    TR: "Planı Düşür",
    DE: "Plan herabstufen",
  },
  {
    id: "Current plan",
    EN: "Current plan",
    TR: "Mevcut plan",
    DE: "Aktueller Plan",
  },
  {
    id: "Upgrade plan",
    EN: "Upgrade plan",
    TR: "Planımı Yükselt",
    DE: "Plan aktualisieren",
  },
  {
    id: "Create free account",
    EN: "Create free account",
    TR: "Ücretsiz hesap oluştur",
    DE: "Kostenloses Konto erstellen",
  },
  {
    id: "Don't miss the affordable price advantages exclusive to the beta period!",
    EN: "Don't miss the affordable price advantages exclusive to the beta period!",
    TR: "Beta dönemine özel uygun fiyat avantajlarını kaçırmayın!",
    DE: "Beta dönemine özgü uygun fiyat avantajlarını kaçırmayın!",
  },
  {
    id: "Plans designed to grow your business.",
    EN: "Plans designed to grow your business.",
    TR: "İşinizi büyütmek için tasarlanmış paketler.",
    DE: "Ihr Geschäft zu erweitern, entwickelte Pläne.",
  },
  {
    id: "Ready to have a better Amazon experience?",
    EN: "Ready to have a better Amazon experience?",
    TR: "Daha iyi bir Amazon deneyimine hazır mısınız?",
    DE: "Bereit für ein besseres Amazon-Erlebnis?",
  },
  {
    id: "Ready to have a better Amazon experience? Great way to grow your earnings without spending too much time and money. Do it easily, quickly and practically!",
    EN: "Ready to have a better Amazon experience? Great way to grow your earnings without spending too much time and money. Do it easily, quickly and practically!",
    TR: "Daha iyi bir Amazon deneyimine hazır mısınız? Kazancınızı çok fazla zaman ve para harcamadan büyütmek için harika bir yol. Hızlı, kolay ve pratik bir şekilde yapın!",
    DE: "Bereit für ein besseres Amazon-Erlebnis? Tolle Möglichkeit, Ihre Einnahmen zu steigern, ohne zu viel Zeit und Geld auszugeben. Tun Sie es leicht, schnell und praktisch!",
  },
  {
    id: "No credit card required.",
    EN: "No credit card required.",
    TR: "Kredi kartı gerekmiyor.",
    DE: "Keine Kreditkarte erforderlich.",
  },
  {
    id: "Our Privacy Policy ensures that both you and our other users' personal data is secure. This policy clearly outlines what information is collected, how it's used, and how it's safeguarded. We respect your privacy and work diligently to protect your information to the best of our ability.",
    EN: "Our Privacy Policy ensures that both you and our other users' personal data is secure. This policy clearly outlines what information is collected, how it's used, and how it's safeguarded. We respect your privacy and work diligently to protect your information to the best of our ability.",
    TR: "Gizlilik Politikamız, hem sizin hem de diğer kullanıcılarımızın kişisel verilerinin güvende olduğunu sağlar. Bu politika, hangi bilgilerin toplandığını, nasıl kullanıldığını ve nasıl korunduğunu açıkça belirtir. Gizliliğinizi saygıyla karşılıyor ve bilgilerinizi en iyi şekilde korumak için özenle çalışıyoruz.",
    DE: "Unsere Datenschutzrichtlinie stellt sicher, dass sowohl Ihre persönlichen Daten als auch die anderer Benutzer sicher sind. Diese Richtlinie legt klar dar, welche Informationen gesammelt werden, wie sie verwendet werden und wie sie geschützt werden. Wir respektieren Ihre Privatsphäre und arbeiten nach bestem Wissen und Gewissen daran, Ihre Informationen zu schützen.",
  },
  {
    id: "Privacy Policy & Terms of Use",
    EN: "Privacy Policy & Terms of Use",
    TR: "Gizlilik Politikası ve Kullanım Koşulları",
    DE: "Datenschutzrichtlinie & Nutzungsbedingungen",
  },
  {
    id: "Go Home",
    EN: "Go Home",
    TR: "Ana Sayfa'ya Dön",
    DE: "Zurück zur Startseite",
  },
  {
    id: "Privacy Policy",
    EN: "Privacy Policy",
    TR: "Gizlilik Politikası",
    DE: "Datenschutzrichtlinie",
  },
  {
    id: "Terms of Use",
    EN: "Terms of Use",
    TR: "Kullanım Koşulları",
    DE: "Nutzungsbedingungen",
  },
  {
    id: "Kepicker - Start growing your Amazon account today!",
    EN: "Kepicker - Start growing your Amazon account today!",
    TR: "Kepicker - Amazon hesabınızı büyütmeye bugün başlayın!",
    DE: "Kepicker - Starten Sie heute mit dem Wachstum Ihres Amazon-Kontos!",
  },
  {
    id: "Say hello to Industry’s newest Asin extractor. Compare all Amazon marketplaces - increase leads with Kepicker. Let's find the most profitable products!",
    EN: "Say hello to Industry’s newest Asin extractor. Compare all Amazon marketplaces - increase leads with Kepicker. Let's find the most profitable products!",
    TR: "Endüstrinin en yeni Asin çıkartıcısına merhaba deyin. Tüm Amazon pazarlarını karşılaştırın - Kepicker ile potansiyel müşteri sayısını artırın. Hadi en karlı ürünleri bulalım!",
    DE: "Sektörün en yeni Asin çıkartıcısına merhaba deyin. Tüm Amazon pazarlarını karşılaştırın - Kepicker ile potansiyel müşteri sayısını artırın. Hadi en karlı ürünleri bulalım!",
  },
  {
    id: "Grow your Amazon Marketplace.",
    EN: "Grow your Amazon Marketplace.",
    TR: "Amazon Pazarınızı büyütün!",
    DE: "Wachsen Sie Ihren Amazon-Marktplatz.",
  },
  {
    id: "Kepicker Tools.",
    EN: "Kepicker Tools.",
    TR: "Kepicker Araçları.",
    DE: "Kepicker Tools.",
  },
  {
    id: "Get Started",
    EN: "Get Started",
    TR: "Başlayalım mı?",
    DE: "Loslegen",
  },
  {
    id: "Get to Know Us",
    EN: "Get to Know Us",
    TR: "Bizi Tanıyın.",
    DE: "Lernen Sie uns kennen",
  },
  {
    id: "Potential to Reduce Time",
    EN: "Potential to Reduce Time",
    TR: "Zamandan Tasarruf",
    DE: "Zeitersparnispotenzial",
  },
  {
    id: "Tools Ready Now",
    EN: "Tools Ready Now",
    TR: "Kullanılmaya Hazır Araç",
    DE: "Werkzeuge bereit",
  },
  {
    id: "Kepicker Tools & Main Features",
    EN: "Kepicker Tools & Main Features",
    TR: "Kepicker Araçları ve Temel Özellikler",
    DE: "Kepicker Tools & Hauptmerkmale",
  },
  {
    id: "Do it easily, quickly and practically",
    EN: "Do it easily, quickly and practically",
    TR: "Kolay, hızlı ve pratik bir Amazon yazılımı",
    DE: "Einfach, schnell und praktisch erledigen",
  },
  {
    id: "Premium features designed to help your business take-off",
    EN: "Premium features designed to help your business take-off",
    TR: "İşinizin ilerlemesine yardımcı olmak için tasarlanmış premium özellikler",
    DE: "Premium-Funktionen, die Ihrem Unternehmen zum Durchbruch verhelfen",
  },

  {
    id: "With this tool, you can quickly pull all Asins on the current page.",
    EN: "With this tool, you can quickly pull all Asins on the current page.",
    TR: "Amazon sayfalarındaki ASIN'leri (Amazon Standard Identification Numbers) hızlıca toplayıp indirebilir, kaydedebilir veya görüntüleyerek zamandan tasarruf edin.",
    DE: "Mit diesem Tool können Sie schnell alle Asins auf der aktuellen Seite abrufen.",
  },
  {
    id: "This tool becomes active while on the seller page. When this tool starts, it automatically runs in the background, page by page, and collects all the seller's Asins.",
    EN: "This tool becomes active while on the seller page. When this tool starts, it automatically runs in the background, page by page, and collects all the seller's Asins.",
    TR: "Bu araç, satıcı sayfasında iken aktif hale gelir. Bu araç çalışmaya başladığında arka planda sayfa sayfa gezinir ve satıcının  tüm Asin'lerini toplar.",
    DE: "Dieses Tool wird auf der Verkäuferseite aktiv. Wenn dieses Tool startet, läuft es automatisch im Hintergrund, Seite für Seite, und sammelt alle Asins des Verkäufers.",
  },
  {
    id: "Compare Marketplaces",
    EN: "Compare Marketplaces",
    TR: "Market Karşılaştırma",
    DE: "Marktplätze vergleichen",
  },
  {
    id: "It allows you to find the most profitable and suitable product by comparing the Asin lists you have drawn between two markets.",
    EN: "It allows you to find the most profitable and suitable product by comparing the Asin lists you have drawn between two markets.",
    TR: "Seçtiğiniz 2 pazar arasında Asin listelerini karşılaştırarak en karlı ve uygun ürünleri bulmanıza olanak tanır.",
    DE: "Es ermöglicht Ihnen, das profitabelste und geeignetste Produkt zu finden, indem Sie die von Ihnen zwischen zwei Märkten gezogenen Asin-Listen vergleichen.",
  },
  {
    id: "Product Based Info",
    EN: "Product Based Info",
    TR: "Ürün Botu",
    DE: "Produkt-Bot",
  },
  {
    id: "When you open a product page, it offers the Roi calculation and other price informations about the current product embedded in the page.",
    EN: "When you open a product page, it offers the Roi calculation and other price informations about the current product embedded in the page.",
    TR: "Bir ürün sayfasını açtığınızda, mevcut ürünle ilgili Roi sonuçlarını ve diğer fiyat bilgilerini detaylı bir şekilde sunar.",
    DE: "Wenn Sie eine Produktseite öffnen, bietet es die Roi-Berechnung und andere Preisinformationen zum aktuellen Produkt, die in der Seite eingebettet sind.",
  },
  {
    id: "Kepicker Calculator",
    EN: "Kepicker Calculator",
    TR: "Kepicker Hesaplayıcı",
    DE: "Kepicker-Rechner",
  },
  {
    id: "You can make your own calculations by manually entering the source and target market price and shipping price.",
    EN: "You can make your own calculations by manually entering the source and target market price and shipping price.",
    TR: "Kaynak ve hedef pazar fiyatı ile nakliye ücretini manuel olarak girerek kendi hesaplamalarınızı yapabilirsiniz.",
    DE: "Sie können Ihre eigenen Berechnungen vornehmen, indem Sie die Preis- und Versandkosten für den Quell- und Zielmarkt manuell eingeben.",
  },
  {
    id: "Uploader Tool",
    EN: "Uploader Tool",
    TR: "Uploader Aracı",
    DE: "Uploader-Tool",
  },
  {
    id: "Do you have a file containing the Asin list? You can compare products by uploading your file to this tool.",
    EN: "Do you have a file containing the Asin list? You can compare products by uploading your file to this tool.",
    TR: "Asin listesi içeren bir dosyanız mı var? Bu araca dosyanızı yükleyerek ürünleri karşılaştırabilirsiniz.",
    DE: "Eine Datei mit der Asin-Liste vorhanden? Sie können Produkte vergleichen, indem Sie Ihre Datei in dieses Tool hochladen.",
  },
  {
    id: "Stock Fetcher",
    EN: "Stock Fetcher",
    TR: "Stok Çekici",
    DE: "Bestandsabruf",
  },
  {
    id: "With this tool, you can get detailed stock data of a product.",
    EN: "With this tool, you can get detailed stock data of a product.",
    TR: "Bu araç ile bir ürünün detaylı stok verilerini alabilirsiniz.",
    DE: "Mit diesem Tool können Sie detaillierte Lagerdaten eines Produkts abrufen.",
  },
  {
    id: "Historical Data",
    EN: "Historical Data",
    TR: "Geçmiş Data",
    DE: "Historische Daten",
  },
  {
    id: "With this tool, you can see detailed information of historical Keepa data for a product.",
    EN: "With this tool, you can see detailed information of historical Keepa data for a product.",
    TR: "Bu araç ile bir ürünün geçmiş Keepa verilerinin detaylı bilgilerini görebilirsiniz.",
    DE: "Mit diesem Tool können Sie detaillierte Informationen zu historischen Keepa-Daten für ein Produkt anzeigen.",
  },
  {
    id: "Coming Soon Features & Tools",
    EN: "Coming Soon Features & Tools",
    TR: "Yakında Gelecek Özellikler ve Araçlar",
    DE: "Demnächst verfügbare Funktionen und Tools",
  },
  {
    id: "We will continue to improve the extension and add newer features to the extension.",
    EN: "We will continue to improve the extension and add newer features to the extension.",
    TR: "Eklentiyi geliştirmeye devam edeceğiz ve yeni özellikler ekleyeceğiz.",
    DE: "Um ihnen einen besseren Service zu bieten, werden wir die Erweiterung verbessern und neue Funktionen hinzufügen.",
  },
  {
    id: "Check out our upcoming updates.",
    EN: "Check out our upcoming updates.",
    TR: "Yaklaşan güncellemelerimize göz atın.",
    DE: "Schauen Sie sich unsere kommenden Updates an.",
  },
  {
    id: "Feature",
    EN: "Feature",
    TR: "Özellik",
    DE: "Funktion",
  },
  {
    id: "Asins to Google Sheets",
    EN: "Asins to Google Sheets",
    TR: "Asin'leri Google Tablosuna Gönder",
    DE: "Asins zu Google Sheets",
  },
  {
    id: "You will be able to send the Asin lists you find to your Google Sheet account.",
    EN: "You will be able to send the Asin lists you find to your Google Sheet account.",
    TR: "Bulduğunuz Asin listelerini Google Tablo hesabınıza gönderebileceksiniz.",
    DE: "Sie können die von Ihnen gefundenen Asin-Listen an Ihr Google Sheet-Konto senden.",
  },
  {
    id: "Tool",
    EN: "Tool",
    TR: "Araç",
    DE: "Werkzeug",
  },
  {
    id: "Product Recommendation",
    EN: "Product Recommendation",
    TR: "Ürün Tavsiyesi",
    DE: "Produktempfehlung",
  },
  {
    id: "Our advanced algorithm will be able to recommend you a product with high sales potential every day.",
    EN: "Our advanced algorithm will be able to recommend you a product with high sales potential every day.",
    TR: "Gelişmiş algoritmamız size her gün yüksek satış potansiyeline sahip bir ürün tavsiye edebilecek.",
    DE: "Unser fortschrittlicher Algorithmus wird Ihnen täglich ein Produkt mit hohem Verkaufspotenzial empfehlen können.",
  },
  {
    id: "New Product Options",
    EN: "New Product Options",
    TR: "Yeni Ürün Seçenekleri",
    DE: "Neue Produktoptionen",
  },
  {
    id: "You will soon be able to add the products you find to your favorite lists.",
    EN: "You will soon be able to add the products you find to your favorite lists.",
    TR: "Bulduğunuz ürünleri yakında favori listelerinize ekleyebileceksiniz.",
    DE: "In Kürze können Sie die von Ihnen gefundenen Produkte zu Ihren Favoriten hinzufügen.",
  },
  {
    id: "You will be able to add a note about the product you want.",
    EN: "You will be able to add a note about the product you want.",
    TR: "İstediğiniz ürün hakkında bir not ekleyebileceksiniz.",
    DE: "Sie werden eine Notiz über das gewünschte Produkt hinzufügen können.",
  },
  {
    id: "Wholesaler Tool",
    EN: "Wholesaler Tool",
    TR: "Toptancı Aracı",
    DE: "Großhandels-Tool",
  },
  {
    id: "Compare UPC + EAN Listings",
    EN: "Compare UPC + EAN Listings",
    TR: "UPC + EAN Listelerini Karşılaştır",
    DE: "UPC + EAN Listelerini Karşılaştır",
  },
  {
    id: "You will soon be able to compare EAC and UPC listings as well.",
    EN: "You will soon be able to compare EAC and UPC listings as well.",
    TR: "Yakında EAC ve UPC listelerini de karşılaştırabileceksiniz.",
    DE: "Yakında EAC ve UPC listelerini de karşılaştırabileceksiniz.",
  },
  {
    id: "More Advanced Comparison",
    EN: "More Advanced Comparison",
    TR: "Daha Gelişmiş Karşılaştırma",
    DE: "Mehr fortgeschrittener Vergleich",
  },
  {
    id: "Comparison with detailed discount types",
    EN: "Comparison with detailed discount types",
    TR: "Ayrıntılı indirim türleriyle karşılaştırma",
    DE: "Vergleich mit detaillierten Rabattarten",
  },
  {
    id: "Customization Table Columns (Coloring / Show / Hide)",
    EN: "Customization Table Columns (Coloring / Show / Hide)",
    TR: "Tablo Sütunlarını Özelleştirin (Renklendirme / Göster / Gizle)",
    DE: "Anpassung von Tabellenspalten (Färbung / Anzeigen / Ausblenden)",
  },
  {
    id: "Kepicker empowers to find better products, faster.",
    EN: "Kepicker empowers to find better products, faster.",
    TR: "Kepicker, daha iyi ürünleri daha hızlı bulmanıza olanak tanır.",
    DE: "Kepicker ermöglicht es, Profitable Produkte schneller zu finden.",
  },
  {
    id: "The all-in-one finding and comparing products platform that saves you time + money. Most comparison sites provide basic information, with Kepicker, we want to help you research the most profitable products. That way we can focus on providing you with the best user experience, service, and settlement.",
    EN: "The all-in-one finding and comparing products platform that saves you time + money. Most comparison sites provide basic information, with Kepicker, we want to help you research the most profitable products. That way we can focus on providing you with the best user experience, service, and settlement.",
    TR: "İşte size zaman + para tasarrufu sağlayan, tüm Amazon marketlerini karşılaştıran ve en karlı ürünleri bulmanızı sağlayan bir yazılım. Çoğu Amazon yazılımı size güven vermez, Kepicker ile en karlı ürünleri araştırmanıza yardımcı olmak istiyoruz. Bunun için size en iyi kullanıcı deneyimini, hizmeti ve şeffaf bilgileri sunmaya çalışıyoruz.",
    DE: "Die All-in-One-Plattform für das Finden und Vergleichen von Produkten, die Zeit und Geld spart. Die meisten Vergleichsseiten bieten grundlegende Informationen an. Mit Kepicker möchten wir Ihnen bei der Recherche der profitabelsten Produkte helfen. Auf diese Weise können wir uns darauf konzentrieren, Ihnen das beste Benutzererlebnis, Service und Abwicklung zu bieten.",
  },
  {
    id: "Boost your productivity!",
    EN: "Boost your productivity!",
    TR: "Verimliliğinizi artırın!",
    DE: "Steigern Sie Ihre Produktivität!",
  },
  {
    id: "Kepicker Will Offer You the Following.",
    EN: "Kepicker Will Offer You the Following.",
    TR: "Amazon Pazarınızı Büyütmenin Akıllı Yolu",
    DE: "Kepicker bietet Ihnen Folgendes.",
  },
  {
    id: "The app offers all the basic features you'll need for product research.",
    EN: "The app offers all the basic features you'll need for product research.",
    TR: "Uygulama, ürün araştırması için ihtiyacınız olan tüm temel özellikleri sunar.",
    DE: "Die App bietet alle grundlegenden Funktionen, die Sie für die Produktrecherche benötigen.",
  },
  {
    id: "Custom Product",
    EN: "Custom Product",
    TR: "Bulunan Ürün",
    DE: "Kundenspezifisches Produkt",
  },
  {
    id: "United Kingdom",
    EN: "United Kingdom",
    TR: "Birleşik Krallık",
    DE: "Vereinigtes Königreich",
  },
  {
    id: "United States",
    EN: "United States",
    TR: "Amerika Birleşik Devletleri",
    DE: "Vereinigte Staaten",
  },
  {
    id: "Canada",
    EN: "Canada",
    TR: "Kanada",
    DE: "Kanada",
  },
  {
    id: "Germany",
    EN: "Germany",
    TR: "Almanya",
    DE: "Deutschland",
  },
  {
    id: "Ready to get started?",
    EN: "Ready to get started?",
    TR: "Amazon satışlarınızı artırmak mı istiyorsunuz?",
    DE: "Bereit, loszulegen?",
  },
  {
    id: "Let's do it. Let's find!",
    EN: "Let's do it. Let's find!",
    TR: "Amazon'da Bir Adım Önde Olun!",
    DE: "Auf geht's. Lass uns finden!",
  },
  {
    id: "Say hello to Industry’s newest product finder.",
    EN: "Say hello to Industry’s newest product finder.",
    TR: "Sektörün en yeni ürün karşılaştırma aracına merhaba diyin.",
    DE: "Begrüßen Sie den neuesten Produktfinder der Branche.",
  },
  {
    id: "Get Early Access",
    EN: "Get Early Access",
    TR: "İşinizi Büyütün",
    DE: "Frühen Zugang erhalten",
  },
  {
    id: "Blogs, Latest News",
    EN: "Blogs, Latest News",
    TR: "Rehberler, Makaleler ve İpuçları",
    DE: "Anleitungen, Artikel & Tipps",
  },
  {
    id: "Here our blogs provide some tips and guides for you to find the best products for your marketplace.",
    EN: "Here our blogs provide some tips and guides for you to find the best products for your marketplace.",
    TR: "Blog sayfamız pazarınız için en iyi ürünleri bulmanıza yardımcı olacak bazı ipuçları ve rehberler sunuyor.",
    DE: "Hier bieten unsere Blogs einige Tipps und Anleitungen, um die besten Produkte für Ihren Marktplatz zu finden.",
  },
  {
    id: "Latest News ‍& Updates",
    EN: "Latest News ‍& Updates",
    TR: "En Son Haberler ‍& Güncellemeler",
    DE: "Neueste Nachrichten ‍& Updates",
  },
  {
    id: "Help Center",
    EN: "Help Center",
    TR: "Yardım Merkezi",
    DE: "Hilfe-Center",
  },
  {
    id: "Contact us to learn how we can help your business or get answer to technical questions about Kepicker.",
    EN: "Contact us to learn how we can help your business or get answer to technical questions about Kepicker.",
    TR: "İşinize nasıl yardımcı olabileceğimizi öğrenmek veya Kepicker hakkındaki teknik sorularınıza cevap almak için bize ulaşın.",
    DE: "Kontaktieren Sie uns, um zu erfahren, wie wir Ihr Unternehmen unterstützen können, oder um Antworten auf technische Fragen zu Kepicker zu erhalten.",
  },
  {
    id: "Browse the Help Center",
    EN: "Browse the Help Center",
    TR: "Yardım merkezine göz atın",
    DE: "Hilfe-Center durchsuchen",
  },
  {
    id: "Which marketplaces do your extension work on?",
    EN: "Which marketplaces do your extension work on?",
    TR: "Uzantınız hangi pazarlarda çalışır?",
    DE: "Auf welchen Marktplätzen funktioniert Ihre Erweiterung?",
  },
  {
    id: "Our extension aims to make comparisons in the 16 marketplaces where Amazon is located, regardless of the source store selection.",
    EN: "Our extension aims to make comparisons in the 16 marketplaces where Amazon is located, regardless of the source store selection.",
    TR: "Uzantımız, Amazon'un bulunduğu 16 pazarda karşılaştırmalar yapmayı amaçlar, kaynak mağaza seçimine bakılmaksızın.",
    DE: "Unsere Erweiterung zielt darauf ab, Vergleiche auf den 16 Märkten durchzuführen, auf denen Amazon tätig ist, unabhängig von der Auswahl des Quellgeschäfts.",
  },
  {
    id: "What does Kepicker provide me?",
    EN: "What does Kepicker provide me?",
    TR: "Kepicker bana ne sağlar?",
    DE: "Was bietet mir Kepicker?",
  },
  {
    id: "Kepicker is a company that focuses on the Online Arbitrage model for sellers selling on Amazon and produces software to meet the needs of sellers using this trading system.",
    EN: "Kepicker is a company that focuses on the Online Arbitrage model for sellers selling on Amazon and produces software to meet the needs of sellers using this trading system.",
    TR: "Kepicker, Amazon'da satış yapan satıcılar için Online Arbitraj modeline odaklanan ve bu ticaret sistemini kullanan satıcıların ihtiyaçlarını karşılamak için yazılım üreten bir şirkettir.",
    DE: "Kepicker ist ein Unternehmen, das sich auf das Online-Arbitrage-Modell für Verkäufer spezialisiert, die auf Amazon verkaufen, und Software entwickelt, um die Bedürfnisse von Verkäufern zu erfüllen, die dieses Handelssystem nutzen.",
  },
  {
    id: "In how many stores can I use the extension?",
    EN: "In how many stores can I use the extension?",
    TR: "Uzantıyı kaç mağazada kullanabilirim?",
    DE: "In wie vielen Geschäften kann ich die Erweiterung verwenden?",
  },
  {
    id: "You can use the Kepicker extension on one store in each account.",
    EN: "You can use the Kepicker extension on one store in each account.",
    TR: "Her hesapta bir mağazada Kepicker uzantısını kullanabilirsiniz.",
    DE: "Sie können die Kepicker-Erweiterung in jedem Konto in einem Geschäft verwenden.",
  },
  {
    id: "Can I find unlimited products?",
    EN: "Can I find unlimited products?",
    TR: "Sınırsız ürün bulabilir miyim?",
    DE: "Unbegrenzte Produkte finden?",
  },
  {
    id: "As you can see on the pricing page, we have limits. You can buy the package you need from the pricing page and set sail for very profitable products by taking advantage of these quotas.",
    EN: "As you can see on the pricing page, we have limits. You can buy the package you need from the pricing page and set sail for very profitable products by taking advantage of these quotas.",
    TR: "Fiyatlandırma sayfasında gördüğünüz gibi sınırlamalarımız var. İhtiyacınız olan paketi fiyatlandırma sayfasından satın alabilir ve bu kotalardan faydalanarak çok karlı ürünlere yelken açabilirsiniz.",
    DE: "Wie Sie auf der Preisgestaltungsseite sehen können, haben wir Begrenzungen. Sie können das Paket, das Sie benötigen, auf der Preisgestaltungsseite kaufen und von diesen Quoten profitieren, um sehr profitable Produkte anzubieten.",
  },
  {
    id: "Please fill all fields!",
    EN: "Please fill all fields!",
    TR: "Lütfen tüm alanları doldurun!",
    DE: "Bitte füllen Sie alle Felder aus!",
  },
  {
    id: "Please enter a valid email address!",
    EN: "Please enter a valid email address!",
    TR: "Lütfen geçerli bir e-posta adresi girin!",
    DE: "Bitte geben Sie eine gültige E-Mail-Adresse ein!",
  },
  {
    id: "Please enter a message longer than 10 characters",
    EN: "Please enter a message longer than 10 characters",
    TR: "Lütfen 10 karakterden uzun bir mesaj girin",
    DE: "Bitte geben Sie eine Nachricht ein, die länger als 10 Zeichen ist",
  },
  {
    id: "Please enter a subject longer than 5 characters",
    EN: "Please enter a subject longer than 5 characters",
    TR: "Lütfen 5 karakterden uzun bir konu girin",
    DE: "Bitte geben Sie einen Betreff ein, der länger als 5 Zeichen ist",
  },
  {
    id: "Your message has been sent successfully!..",
    EN: "Your message has been sent successfully!..",
    TR: "Mesajınız başarıyla gönderildi!..",
    DE: "Ihre Nachricht wurde erfolgreich gesendet!..",
  },
  {
    id: "Got A Question? Let's Talk! - Contact us to learn how we can help your business or get answers to technical questions about Kepicker.",
    EN: "Got A Question? Let's Talk! - Contact us to learn how we can help your business or get answers to technical questions about Kepicker.",
    TR: "Sorunuz mu var? Konuşalım! - İşinize nasıl yardımcı olabileceğimizi öğrenmek veya Kepicker ile ilgili teknik sorularınıza cevap almak için bize ulaşın.",
    DE: "Eine Frage? Lass uns sprechen! - Kontaktieren Sie uns, um zu erfahren, wie wir Ihrem Unternehmen helfen können oder um Antworten auf technische Fragen zu Kepicker zu erhalten.",
  },
  {
    id: "How can we help",
    EN: "How can we help",
    TR: "Size nasıl yardımcı olabiliriz",
    DE: "Wie können wir Ihnen helfen",
  },
  {
    id: "Got A Question? Let's Talk!",
    EN: "Got A Question? Let's Talk!",
    TR: "Sorunuz mu var? Konuşalım!",
    DE: "Eine Frage? Lass uns sprechen!",
  },
  {
    id: "FAQs",
    EN: "FAQs",
    TR: "Sıkça Sorulan Sorular",
    DE: "Häufig gestellte Fragen",
  },
  {
    id: "Frequently asked questions",
    EN: "Frequently asked questions",
    TR: "Sıkça sorulan sorular",
    DE: "Häufig gestellte Fragen",
  },
  {
    id: "Get In Touch",
    EN: "Get In Touch",
    TR: "İletişime Geçin",
    DE: "Kontakt aufnehmen",
  },
  {
    id: "Full Name",
    EN: "Full Name",
    TR: "Tam adınız",
    DE: "Vollständiger Name",
  },
  {
    id: "Email address",
    EN: "Email address",
    TR: "E-posta adresiniz",
    DE: "E-Mail-Adresse",
  },
  {
    id: "Subject",
    EN: "Subject",
    TR: "Konu",
    DE: "Betreff",
  },
  {
    id: "Message",
    EN: "Message",
    TR: "Mesaj",
    DE: "Nachricht",
  },
  {
    id: "Say something please...",
    EN: "Say something please...",
    TR: "Lütfen bir şey yazın...",
    DE: "Bitte sagen Sie etwas...",
  },
  {
    id: "Send message",
    EN: "Send message",
    TR: "Mesajı gönder",
    DE: "Nachricht senden",
  },
  {
    id: "Stuck? Sometimes we just need to talk to a human.",
    EN: "Stuck? Sometimes we just need to talk to a human.",
    TR: "Sorularınız mı var? Uzmanlığımıza ve yeteneğimize güvenin..",
    DE: "Festgefahren? Manchmal müssen wir einfach mit einem Menschen sprechen.",
  },
  {
    id: "Our Blog and FAQ pages will be fed by different links for a while!",
    EN: "Our Blog and FAQ pages will be fed by different links for a while!",
    TR: "Blog ve SSS sayfalarımız bir süre farklı kaynaklarda yer alacaktır!",
    DE: "Unsere Blog- und FAQ-Seiten werden eine Weile lang mit unterschiedlichen Links versorgt!",
  },
  {
    id: "Connect with us on",
    EN: "Connect with us on",
    TR: "Bizimle iletişime geçin",
    DE: "Verbinde dich mit uns auf",
  },
  {
    id: "Latest News and Updates about Kepicker.",
    EN: "Latest News and Updates about Kepicker.",
    TR: "Kepicker hakkında En Son Haberler ve Güncellemeler..",
    DE: "Neueste Nachrichten und Updates zu Kepicker.",
  },
  {
    id: "Nevermind, I got it",
    EN: "Nevermind, I got it",
    TR: "Giriş Yap",
    DE: "Macht nichts, ich habe verstanden",
  },
  {
    id: "Submit",
    EN: "Submit",
    TR: "Gönder",
    DE: "Gesendet",
  },
  {
    id: "New password",
    EN: "New password",
    TR: "Yeni şifre",
    DE: "Neues Passwort",
  },
  {
    id: "Password",
    EN: "Password",
    TR: "Şifre",
    DE: "Passwort",
  },
  {
    id: "Verify code",
    EN: "Verify code",
    TR: "Kodu doğrula",
    DE: "Code überprüfen",
  },
  {
    id: "Verify",
    EN: "Verify",
    TR: "Doğrula",
    DE: "Bestätigen",
  },
  {
    id: "For security purposes, no withdrawals are permitted for 24 hours after password changed.",
    EN: "For security purposes, no withdrawals are permitted for 24 hours after password changed.",
    TR: "Güvenlik nedeniyle, şifre değiştirildikten sonra 24 saat boyunca para çekme işlemine izin verilmez.",
    DE: "Aus Sicherheitsgründen sind 24 Stunden nach der Passwortänderung keine Abhebungen gestattet.",
  },
  {
    id: "Forgot password",
    EN: "Forgot password",
    TR: "Şifreni mi unuttun?",
    DE: "Passwort vergessen",
  },
  {
    id: "Something went wrong, please try again!",
    EN: "Something went wrong, please try again!",
    TR: "Bir şeyler yanlış gitti, lütfen tekrar deneyin!",
    DE: "Etwas ist schief gelaufen, bitte versuchen Sie es erneut!",
  },
  {
    id: "Password changed successfully!",
    EN: "Password changed successfully!",
    TR: "Şifre başarıyla değiştirildi!",
    DE: "Passwort erfolgreich geändert!",
  },
  {
    id: "Please check your email for verification code!",
    EN: "Please check your email for verification code!",
    TR: "Onay kodu için e-postanızı kontrol edin!",
    DE: "Bitte überprüfen Sie Ihre E-Mail auf den Bestätigungscode!",
  },
  {
    id: "Login",
    EN: "Login",
    TR: "Giriş Yap",
    DE: "Anmelden",
  },
  {
    id: "Forgot password?",
    EN: "Forgot password?",
    TR: "Şifremi unuttum?",
    DE: "Passwort vergessen?",
  },
  {
    id: "Sign in to Kepicker",
    EN: "Sign in to Kepicker",
    TR: "Hoşgeldiniz..",
    DE: "Melden Sie sich bei Kepicker an",
  },
  {
    id: "Sign up for free",
    EN: "Sign up for free",
    TR: "Ücretsiz kaydol",
    DE: "Kostenlos anmelden",
  },
  {
    id: "Sign in to Kepicker - The all-in-one finding and comparing products platform that saves you time + money. Let's do it. Let's find!",
    EN: "Sign in to Kepicker - The all-in-one finding and comparing products platform that saves you time + money. Let's do it. Let's find!",
    TR: "Kepicker'a giriş yapın - Sizi zaman + para tasarrufu sağlayan ürünleri bulma ve karşılaştırma platformu. Hadi yapalım. Bulalım!",
    DE: "Melden Sie sich bei Kepicker an - Die All-in-One-Plattform zum Finden und Vergleichen von Produkten, die Zeit und Geld spart. Machen wir es. Finden wir!",
  },
  {
    id: "Login successful, please wait..",
    EN: "Login successful, please wait..",
    TR: "Giriş başarılı, lütfen bekleyin..",
    DE: "Anmeldung erfolgreich, bitte warten..",
  },
  {
    id: "Sign up",
    EN: "Sign up",
    TR: "Üye ol",
    DE: "Registrieren",
  },
  {
    id: "By signing up I agree that I’m 18 years of age or older, to the",
    EN: "By signing up I agree that I’m 18 years of age or older, to the",
    TR: "Üye olurken 18 yaşında veya daha büyük olduğumu ve",
    DE: "Mit der Anmeldung erkläre ich mich damit einverstanden, dass ich 18 Jahre alt oder älter bin, zu",
  },
  {
    id: "Repeat your new password",
    EN: "Repeat your new password",
    TR: "Yeni şifrenizi tekrarlayın",
    DE: "Geben Sie Ihr neues Passwort erneut ein",
  },
  {
    id: "Repeat New Password",
    EN: "Repeat New Password",
    TR: "Yeni Şifreyi Tekrarlayın",
    DE: "Neues Passwort wiederholen",
  },
  {
    id: "Enter your password",
    EN: "Enter your password",
    TR: "Şifrenizi girin",
    DE: "Geben Sie Ihr Passwort ein",
  },
  {
    id: "Already have an account?",
    EN: "Already have an account?",
    TR: "Zaten bir hesabınız mı var?",
    DE: "Bereits ein Konto?",
  },
  {
    id: "Don’t have an account? Our extension is designed for everyone. From beginners to advanced. No need to be an expert to get more earnings.",
    EN: "Don’t have an account? Our extension is designed for everyone. From beginners to advanced. No need to be an expert to get more earnings.",
    TR: "Hesabınız yok mu? Uzantımız herkes için tasarlandı. Başlangıçtan ileri seviyeye kadar. Daha fazla kazanmak için uzman olmanıza gerek yok.",
    DE: "Kein Konto? Unsere Erweiterung ist für jeden konzipiert. Vom Anfänger bis zum Fortgeschrittenen. Keine Notwendigkeit, Experte zu sein, um mehr zu verdienen.",
  },
  {
    id: "Please check your email for verification!",
    EN: "Please check your email for verification!",
    TR: "Doğrulama için e-postanızı kontrol edin!",
    DE: "Überprüfen Sie bitte Ihre E-Mails zur Bestätigung!",
  },
  {
    id: "Plan & Billing",
    EN: "Plan & Billing",
    TR: "Plan & Fatura",
    DE: "Plan & Abrechnung",
  },
  {
    id: "Market connection successful!",
    EN: "Market connection successful!",
    TR: "Pazar bağlantısı başarılı!",
    DE: "Marktverbindung erfolgreich!",
  },
  {
    id: "Market connection failed!",
    EN: "Market connection failed!",
    TR: "Pazar bağlantısı başarısız!",
    DE: "Marktverbindung fehlgeschlagen!",
  },
  {
    id: "Disconnect",
    EN: "Disconnect",
    TR: "Bağlantıyı Kes",
    DE: "Trennen",
  },
  {
    id: "Are you sure want to disconnect market?",
    EN: "Are you sure want to disconnect market?",
    TR: "Pazarı kesmek istediğinizden emin misiniz?",
    DE: "Markt wirklich trennen möchten?",
  },
  {
    id: "Please wait..",
    EN: "Please wait..",
    TR: "Lütfen bekleyin..",
    DE: "Bitte warten..",
  },
  {
    id: "Disconnection process started.",
    EN: "Disconnection process started.",
    TR: "Bağlantı kesme işlemi başlatıldı.",
    DE: "Trennungsvorgang gestartet.",
  },
  {
    id: "Removing the market was successful!",
    EN: "Removing the market was successful!",
    TR: "Pazarı kaldırma başarılı!",
    DE: "Markt wurde erfolgreich entfernt!",
  },
  {
    id: "Disconnect Successful!",
    EN: "Disconnect Successful!",
    TR: "Bağlantı Kesme Başarılı!",
    DE: "Trennen erfolgreich!",
  },
  {
    id: "Welcome back",
    EN: "Welcome back",
    TR: "Hoş geldin",
    DE: "Willkommen zurück",
  },
  {
    id: "Manage plan",
    EN: "Manage plan",
    TR: "Planı yönet",
    DE: "Plan verwalten",
  },
  {
    id: "Available Tokens",
    EN: "Available Tokens",
    TR: "Kullanılabilir Jetonlar",
    DE: "Verfügbare Tokens",
  },
  {
    id: "More tokens",
    EN: "More tokens",
    TR: "Daha fazla jeton",
    DE: "Weitere Tokens",
  },
  {
    id: "Connect your markets and get started.",
    EN: "Connect your markets and get started.",
    TR: "Pazarlarınızı bağlayın ve başlayın.",
    DE: "Verbinden Sie Ihre Märkte und legen Sie los.",
  },
  {
    id: "Active",
    EN: "Active",
    TR: "Aktif",
    DE: "Aktiv",
  },
  {
    id: "Inactive",
    EN: "Inactive",
    TR: "Pasif",
    DE: "Inaktiv",
  },
  {
    id: "North America",
    EN: "North America",
    TR: "Kuzey Amerika",
    DE: "Nordamerika",
  },
  {
    id: "Europe",
    EN: "Europe",
    TR: "Avrupa",
    DE: "Europa",
  },
  {
    id: "Far East",
    EN: "Far East",
    TR: "Uzak Doğu",
    DE: "Ferner Osten",
  },
  {
    id: "Users Management",
    EN: "Users Management",
    TR: "Kullanıcı Yönetimi",
    DE: "Benutzerverwaltung",
  },
  {
    id: "Reports",
    EN: "Reports",
    TR: "Raporlar",
    DE: "Berichte",
  },
  {
    id: "Versions",
    EN: "Versions",
    TR: "Sürümler",
    DE: "Versionen",
  },
  {
    id: "Paid",
    EN: "Paid",
    TR: "Ödenmiş",
    DE: "Bezahlt",
  },
  {
    id: "times",
    EN: "times",
    TR: "kez",
    DE: "Mal",
  },
  {
    id: "Users",
    EN: "Users",
    TR: "Kullanıcılar",
    DE: "Benutzer",
  },
  {
    id: "Open",
    EN: "Open",
    TR: "Açık",
    DE: "Offen",
  },
  {
    id: "Closed",
    EN: "Closed",
    TR: "Kapalı",
    DE: "Geschlossen",
  },
  {
    id: "Web support",
    EN: "Web support",
    TR: "Web destek",
    DE: "Webunterstützung",
  },
  {
    id: "Messages",
    EN: "Messages",
    TR: "Mesajlar",
    DE: "Nachrichten",
  },
  {
    id: "Asin info",
    EN: "Asin info",
    TR: "Asin bilgisi",
    DE: "Asin-Info",
  },
  {
    id: "Asin list saved",
    EN: "Asin list saved",
    TR: "Asin listesi kaydedildi",
    DE: "Asin-Liste gespeichert",
  },
  {
    id: "Asin saved",
    EN: "Asin saved",
    TR: "Asin kaydedildi",
    DE: "Asin gespeichert",
  },
  {
    id: "Asin calculated",
    EN: "Asin calculated",
    TR: "Asin hesaplandı",
    DE: "Asin berechnet",
  },
  {
    id: "Have a question - how can we help you? Get expert help using Kepicker, any time you need it.",
    EN: "Have a question - how can we help you? Get expert help using Kepicker, any time you need it.",
    TR: "Bir sorunuz mu var - size nasıl yardımcı olabiliriz? Kepicker'ı kullanarak her zaman ihtiyacınız olan uzman yardımını alın.",
    DE: "Haben Sie eine Frage - wie können wir Ihnen helfen? Holen Sie sich jederzeit Expertenhilfe mit Kepicker.",
  },
  {
    id: "All",
    EN: "All",
    TR: "Tümü",
    DE: "Alle",
  },
  {
    id: "Active",
    EN: "Active",
    TR: "Aktif",
    DE: "Aktiv",
  },
  {
    id: "Done",
    EN: "Done",
    TR: "Tamamlandı",
    DE: "Erledigt",
  },
  {
    id: "Search",
    EN: "Search",
    TR: "Ara",
    DE: "Suche",
  },
  {
    id: "New Ticket",
    EN: "New Ticket",
    TR: "Yeni Ticket",
    DE: "Neues Ticket",
  },
  {
    id: "Please fill in all fields!",
    EN: "Please fill in all fields!",
    TR: "Lütfen tüm alanları doldurun!",
    DE: "Bitte füllen Sie alle Felder aus!",
  },
  {
    id: "Ticket created successfully!",
    EN: "Ticket created successfully!",
    TR: "Ticket başarıyla oluşturuldu!",
    DE: "Ticket erfolgreich erstellt!",
  },
  {
    id: "New Ticket Form",
    EN: "New Ticket Form",
    TR: "Yeni Ticket Formu",
    DE: "Neues Ticket-Formular",
  },
  {
    id: "Select a topic",
    EN: "Select a topic",
    TR: "Bir konu seçin",
    DE: "Ein Thema auswählen",
  },
  {
    id: "Please select",
    EN: "Please select",
    TR: "Seçim yapınız",
    DE: "Please select",
  },
  {
    id: "Account and Payment",
    EN: "Account and Payment",
    TR: "Hesap ve Ödeme",
    DE: "Konto und Zahlung",
  },
  {
    id: "Bug / Issue",
    EN: "Bug / Issue",
    TR: "Hata / Sorun",
    DE: "Fehler / Problem",
  },
  {
    id: "Feature Request",
    EN: "Feature Request",
    TR: "Özellik İsteği",
    DE: "Feature-Anfrage",
  },
  {
    id: "Other",
    EN: "Other",
    TR: "Diğer",
    DE: "Andere",
  },
  {
    id: "Low",
    EN: "Low",
    TR: "Düşük",
    DE: "Niedrig",
  },
  {
    id: "Medium",
    EN: "Medium",
    TR: "Orta",
    DE: "Mittel",
  },
  {
    id: "High",
    EN: "High",
    TR: "Yüksek",
    DE: "Hoch",
  },
  {
    id: "Urgent",
    EN: "Urgent",
    TR: "Acil",
    DE: "Dringend",
  },
  {
    id: "Please enter a message!",
    EN: "Please enter a message!",
    TR: "Lütfen bir mesaj girin!",
    DE: "Bitte geben Sie eine Nachricht ein!",
  },
  {
    id: "Message sent successfully!",
    EN: "Message sent successfully!",
    TR: "Mesaj başarıyla gönderildi!",
    DE: "Nachricht erfolgreich gesendet!",
  },
  {
    id: "Subject",
    EN: "Subject",
    TR: "Konu",
    DE: "Betreff",
  },
  {
    id: "Topic",
    EN: "Topic",
    TR: "Konu",
    DE: "Thema",
  },
  {
    id: "Priority",
    EN: "Priority",
    TR: "Öncelik",
    DE: "Priorität",
  },
  {
    id: "Priority level",
    EN: "Priority level",
    TR: "Öncelik seviyesi",
    DE: "Prioritätsstufe",
  },
  {
    id: "Created / Updated date",
    EN: "Created / Updated date",
    TR: "Oluşturulma / Güncelleme tarihi",
    DE: "Erstellungs- / Aktualisierungsdatum",
  },
  {
    id: "Details",
    EN: "Details",
    TR: "Detaylar",
    DE: "Details",
  },
  {
    id: "Action",
    EN: "Action",
    TR: "Eylem",
    DE: "Aktion",
  },
  {
    id: "Status",
    EN: "Status",
    TR: "Durum",
    DE: "Status",
  },
  {
    id: "Download",
    EN: "Download",
    TR: "İndir",
    DE: "Herunterladen",
  },
  {
    id: "Amount",
    EN: "Amount",
    TR: "Miktar",
    DE: "Betrag",
  },
  {
    id: "Billing Date",
    EN: "Billing Date",
    TR: "Fatura Tarihi",
    DE: "Abrechnungsdatum",
  },
  {
    id: "Invoice",
    EN: "Invoice",
    TR: "Fatura",
    DE: "Rechnung",
  },
  {
    id: "You can switch plans according to your needs - we're so glad you're here.",
    EN: "You can switch plans according to your needs - we're so glad you're here.",
    TR: "İhtiyacınıza göre planınızı değiştirebilirsiniz - ödeme geçmişiniz ve plan detaylarınız...",
    DE: "Sie können die Tarife je nach Bedarf wechseln – wir freuen uns, dass Sie hier sind.",
  },
  {
    id: "plan",
    EN: "plan",
    TR: "plan",
    DE: "plan",
  },
  {
    id: "Plan page",
    EN: "Plan page",
    TR: "Plan sayfası",
    DE: "Planseite",
  },
  {
    id: "per month",
    EN: "per month",
    TR: "aylık",
    DE: "pro Monat",
  },
  {
    id: "Billing and invoicing",
    EN: "Billing and invoicing",
    TR: "Faturalarınız",
    DE: "Rechnungsstellung und Rechnungslegung",
  },
  {
    id: "Manage your plan details",
    EN: "Manage your plan details",
    TR: "Plan detaylarınızı yönetin",
    DE: "Verwalten Sie Ihre Plandetails",
  },
  {
    id: "Don’t have an account?",
    EN: "Don’t have an account?",
    TR: "Hesabınız yok mu?",
    DE: "Sie haben kein Konto?",
  },
  {
    id: "Don't have an account?",
    EN: "Don't have an account?",
    TR: "Hesabınız yok mu?",
    DE: "Sie haben kein Konto?",
  },
  {
    id: "Sign In",
    EN: "Sign In",
    TR: "Giriş Yap",
    DE: "Anmelden",
  },
  {
    id: "Update",
    EN: "Update",
    TR: "Güncelle",
    DE: "Aktualisieren",
  },
  {
    id: "Failed",
    EN: "Failed",
    TR: "Başarısız",
    DE: "Fehlgeschlagen",
  },
  {
    id: "Successful",
    EN: "Successful",
    TR: "Başarılı",
    DE: "Erfolgreich",
  },
  {
    id: "Token",
    EN: "Token",
    TR: "Jeton",
    DE: "Token",
  },
  {
    id: "Admin",
    EN: "Admin",
    TR: "Admin",
    DE: "Admin",
  },
  {
    id: "Pro",
    EN: "Pro",
    TR: "Pro",
    DE: "Pro",
  },
  {
    id: "Plus",
    EN: "Plus",
    TR: "Plus",
    DE: "Plus",
  },
  {
    id: "Basic",
    EN: "Basic",
    TR: "Temel",
    DE: "Grundlegend",
  },
  {
    id: "Free",
    EN: "Free",
    TR: "Ücretsiz",
    DE: "Kostenlos",
  },
  {
    id: "Wallet / Balance",
    EN: "Wallet / Balance",
    TR: "Cüzdan / Bakiye",
    DE: "Brieftasche / Guthaben",
  },
  {
    id: "Client",
    EN: "Client",
    TR: "Müşteri",
    DE: "Kunde",
  },
  {
    id: "Category",
    EN: "Category",
    TR: "Kategori",
    DE: "Kategorie",
  },
  {
    id: "Current Url",
    EN: "Current Url",
    TR: "Geçerli URL",
    DE: "Aktuelle URL",
  },
  {
    id: "Page",
    EN: "Page",
    TR: "Sayfa",
    DE: "Seite",
  },
  {
    id: "Created Date",
    EN: "Created Date",
    TR: "Oluşturulma Tarihi",
    DE: "Erstellungsdatum",
  },
  {
    id: "Delete",
    EN: "Delete",
    TR: "Sil",
    DE: "Löschen",
  },
  {
    id: "Extension report part messages",
    EN: "Extension report part messages",
    TR: "Eklentiden Gelen Mesajlar",
    DE: "Erweiterungsbericht Teilnachrichten",
  },
  {
    id: "Name",
    EN: "Name",
    TR: "Ad",
    DE: "Name",
  },
  {
    id: "Support / Tickets",
    EN: "Support / Tickets",
    TR: "Destek Talepleri",
    DE: "Support / Tickets",
  },
  {
    id: "Created date / Last updated",
    EN: "Created date / Last updated",
    TR: "Oluşturulma tarihi / Son güncelleme",
    DE: "Erstellungsdatum / Letzte Aktualisierung",
  },
  {
    id: "Type here...",
    EN: "Type here...",
    TR: "Buraya yazın...",
    DE: "Hier eingeben...",
  },
  {
    id: "New User",
    EN: "New User",
    TR: "Yeni Kullanıcı",
    DE: "Neuer Benutzer",
  },
  {
    id: "Web Support Form Messages",
    EN: "Web Support Form Messages",
    TR: "Web Destek Formu Mesajları",
    DE: "Web-Support-Formularnachrichten",
  },
  {
    id: "User deleted successfully!..",
    EN: "User deleted successfully!..",
    TR: "Kullanıcı başarıyla silindi!..",
    DE: "Benutzer erfolgreich gelöscht!..",
  },
  {
    id: "Enabled",
    EN: "Enabled",
    TR: "Etkin",
    DE: "Aktiviert",
  },
  {
    id: "Token Count",
    EN: "Token Count",
    TR: "Jeton Sayısı",
    DE: "Token-Anzahl",
  },
  {
    id: "Asin Count",
    EN: "Asin Count",
    TR: "Asin Sayısı",
    DE: "Asin-Anzahl",
  },
  {
    id: "User Role",
    EN: "User Role",
    TR: "Kullanıcı Rolü",
    DE: "Benutzerrolle",
  },
  {
    id: "Role",
    EN: "Role",
    TR: "Rol",
    DE: "Rolle",
  },
  {
    id: "User Form",
    EN: "User Form",
    TR: "Kullanıcı Formu",
    DE: "Benutzerformular",
  },
  {
    id: "Paid 1",
    EN: "Paid 1",
    TR: "Ücretli 1",
    DE: "Bezahlt 1",
  },
  {
    id: "Paid 2",
    EN: "Paid 2",
    TR: "Ücretli 2",
    DE: "Bezahlt 2",
  },
  {
    id: "Paid 3",
    EN: "Paid 3",
    TR: "Ücretli 3",
    DE: "Bezahlt 3",
  },
  {
    id: "Address",
    EN: "Address",
    TR: "Adres",
    DE: "Adresse",
  },
  {
    id: "Send asin Token",
    EN: "Send asin Token",
    TR: "Asin Jetonu Gönder",
    DE: "Asin-Token senden",
  },
  {
    id: "Send comparison Token",
    EN: "Send comparison Token",
    TR: "Karşılaştırma Jetonu Gönder",
    DE: "Vergleichs-Token senden",
  },
  {
    id: "Save",
    EN: "Save",
    TR: "Kaydet",
    DE: "Speichern",
  },
  {
    id: "No logs found",
    EN: "No logs found",
    TR: "Kayıt bulunamadı",
    DE: "Keine Protokolle gefunden",
  },
  {
    id: "Suspend User",
    EN: "Suspend User",
    TR: "Kullanıcıyı Askıya Al",
    DE: "Benutzer sperren",
  },
  {
    id: "Are you sure want to suspend this user?",
    EN: "Are you sure want to suspend this user?",
    TR: "Bu kullanıcıyı askıya almak istediğinizden emin misiniz?",
    DE: "Möchten Sie diesen Benutzer wirklich sperren?",
  },
  {
    id: "Suspend",
    EN: "Suspend",
    TR: "Askıya Al",
    DE: "Sperren",
  },
  {
    id: "Account suspension has started.",
    EN: "Account suspension has started.",
    TR: "Hesap askıya alma işlemi başladı.",
    DE: "Kontosperre wurde gestartet.",
  },
  {
    id: "Account suspension was successful!",
    EN: "Account suspension was successful!",
    TR: "Hesap askıya alma başarılı oldu!",
    DE: "Kontosperre war erfolgreich!",
  },
  {
    id: "Suspend Successful!",
    EN: "Suspend Successful!",
    TR: "Askıya Alma Başarılı!",
    DE: "Erfolgreich sperren!",
  },
  {
    id: "Plans",
    EN: "Plans",
    TR: "Planlar",
    DE: "Pläne",
  },
  {
    id: "Period",
    EN: "Period",
    TR: "Dönem",
    DE: "Zeitraum",
  },
  {
    id: "Registered",
    EN: "Registered",
    TR: "Kayıtlı",
    DE: "Registriert",
  },
  {
    id: "Logs",
    EN: "Logs",
    TR: "Kayıtlar",
    DE: "Protokolle",
  },
  {
    id: "Billing History",
    EN: "Billing History",
    TR: "Fatura Geçmişi",
    DE: "Abrechnungshistorie",
  },
  {
    id: "Version",
    EN: "Version",
    TR: "Sürüm",
    DE: "Version",
  },
  {
    id: "Description",
    EN: "Description",
    TR: "Açıklama",
    DE: "Beschreibung",
  },
  {
    id: "Update URL",
    EN: "Update URL",
    TR: "Güncelleme URL'si",
    DE: "Update-URL",
  },
  {
    id: "Mandatory",
    EN: "Mandatory",
    TR: "Zorunlu",
    DE: "Obligatorisch",
  },
  {
    id: "New Version",
    EN: "New Version",
    TR: "Yeni Sürüm",
    DE: "Neue Version",
  },
  {
    id: "Manage Update",
    EN: "Manage Update",
    TR: "Güncellemeyi Yönet",
    DE: "Update verwalten",
  },
  {
    id: "Update Content",
    EN: "Update Content",
    TR: "Güncelleme İçeriği",
    DE: "Update-Inhalt",
  },
  {
    id: "Version No.",
    EN: "Version No.",
    TR: "Sürüm No.",
    DE: "Versionsnummer",
  },
  {
    id: "Is an update required?",
    EN: "Is an update required?",
    TR: "Güncelleme gerekiyor mu?",
    DE: "Ist ein Update erforderlich?",
  },
  {
    id: "No",
    EN: "No",
    TR: "Hayır",
    DE: "Nein",
  },
  {
    id: "Yes",
    EN: "Yes",
    TR: "Evet",
    DE: "Ja",
  },
  {
    id: "Cancel",
    EN: "Cancel",
    TR: "İptal Et",
    DE: "Abbrechen",
  },
  {
    id: "Send",
    EN: "Send",
    TR: "Gönder",
    DE: "Senden",
  },
  {
    id: "Checkout",
    EN: "Checkout",
    TR: "Ödeme",
    DE: "Zur Kasse",
  },
  {
    id: "OK thanks.",
    EN: "OK thanks.",
    TR: "Tamam, teşekkürler.",
    DE: "OK, danke.",
  },
  {
    id: "Please do not show this screen again.",
    EN: "Please do not show this screen again.",
    TR: "Lütfen bu ekranı tekrar gösterme.",
    DE: "Bitte zeigen Sie diesen Bildschirm nicht erneut.",
  },
  {
    id: "Important Notice!",
    EN: "Important Notice!",
    TR: "Önemli Duyuru!",
    DE: "Wichtiger Hinweis!",
  },
  {
    id: "Our system is currently in Beta, and we have kept our pricing policy as affordable as possible. If you come across an issue while using the system, please let us know. Our support team will provide extra usage limits to compensate for any losses. Thank you for your understanding.",
    EN: "Our system is currently in Beta, and we have kept our pricing policy as affordable as possible. If you come across an issue while using the system, please let us know. Our support team will provide extra usage limits to compensate for any losses. Thank you for your understanding.",
    TR: "Sistemimiz şu anda Beta aşamasında ve fiyat politikamızı mümkün olduğunca uygun tuttuk. Sistem kullanımı sırasında bir sorunla karşılaşırsanız lütfen bize bildirin. Destek ekibimiz, kayıpları telafi etmek için ek kullanım sınırları sağlayacaktır. Anlayışınız için teşekkür ederiz.",
    DE: "Unser System befindet sich derzeit im Beta-Stadium, und wir haben unsere Preispolitik so erschwinglich wie möglich gestaltet. Wenn Sie während der Nutzung des Systems auf ein Problem stoßen, lassen Sie es uns bitte wissen. Unser Support-Team wird zusätzliche Nutzungsbeschränkungen zur Kompensation von Verlusten bereitstellen. Vielen Dank für Ihr Verständnis.",
  },
  {
    id: "No logs found!",
    EN: "No logs found!",
    TR: "Kayıt Bulunamadı!",
    DE: "Keine Protokolle gefunden!",
  },
  {
    id: "The extension is not yet live!",
    EN: "The extension is not yet live!",
    TR: "Eklenti henüz yayınlanmadı!",
    DE: "Die Erweiterung ist noch nicht live!",
  },
  {
    id: "and at least one lowercase letter.",
    EN: "and at least one lowercase letter.",
    TR: "ve en az bir küçük harf içermelidir.",
    DE: "und mindestens einen Kleinbuchstaben enthalten muss.",
  },
  {
    id: "and at least one number,",
    EN: "and at least one number,",
    TR: "ve en az bir rakam içermelidir.",
    DE: "und mindestens eine Zahl enthalten muss.",
  },
  {
    id: "must consist of at least 8 characters long,",
    EN: "must consist of at least 8 characters long,",
    TR: "en az 8 karakter uzunluğunda olmalıdır.",
    DE: "muss mindestens 8 Zeichen lang sein.",
  },
  {
    id: "Terms",
    EN: "Terms",
    TR: "Şartlar",
    DE: "Nutzungsbedingungen",
  },
  {
    id: "Tags Cloud",
    EN: "Tags Cloud",
    TR: "Etiket Bulutu",
    DE: "Stichworte Wolke",
  },
  {
    id: "Don't forget to connect your Amazon marketplaces, so the extension can compare your products!!",
    EN: "Don't forget to connect your marketplaces, so the extension can compare your products!!",
    TR: "Eklentinin ürünleri karşılaştırabilmesi için Amazon pazar yerlerinizi bağlamayı unutmayın!!",
    DE: "Vergessen Sie nicht, Ihre Amazon-Marktplätze zu verbinden, damit die Erweiterung Ihre Produkte vergleichen kann!",
  },
  {
    id: "Please connect your marketplaces first",
    EN: "Please connect your marketplaces first",
    TR: "Lütfen önce pazarlarınızı bağlayın",
    DE: "Bitte verbinden Sie zuerst Ihre Marktplätze",
  },
  {
    id: "Tier",
    EN: "Tier",
    TR: "Kategori",
    DE: "Kategorie",
  },
  {
    id: "Website registration and store registration required.",
    EN: "Website registration and store registration required.",
    TR: "Web sitesi kaydı ve mağaza kaydı gereklidir.",
    DE: "Website-Registrierung und Store-Registrierung erforderlich.",
  },
  {
    id: "No Keepa Historical Data.",
    EN: "No Keepa Historical Data.",
    TR: "Geçmiş Keepa Verisi Mevcut Değil.",
    DE: "Keine historischen Keepa-Daten.",
  },
  {
    id: "Unlimited",
    EN: "Unlimited",
    TR: "Sınırsız",
    DE: "Unbegrenzt",
  },
  {
    id: "Product Based Info",
    EN: "Product Based Info",
    TR: "Detaylı Ürün Analizi",
    DE: "Detaillierte Produktanalyse",
  },
  {
    id: "Limited to 1000 Products - for once",
    EN: "Limited to 1000 Products - for once",
    TR: "1000 Ürün ile Sınırlı - tek seferlik",
    DE: "Limitiert auf 1000 Produkte – ausnahmsweise",
  },
  {
    id: "Limited to 400 Products - per day",
    EN: "Limited to 400 Products - per day",
    TR: "400 Ürün ile Sınırlı - günlük",
    DE: "Begrenzt auf 400 Produkte – pro Tag",
  },
  {
    id: "Limited to 1000 Actions - for once",
    EN: "Limited to 1000 Actions - for once",
    TR: "1000 İşlem ile Sınırlı - tek seferlik",
    DE: "Begrenzt auf 1000 Aktionen – ausnahmsweise",
  },
  {
    id: "Limited to 600 Actions - per day",
    EN: "Limited to 600 Actions - per day",
    TR: "600 İşlem ile Sınırlı - günlük",
    DE: "Begrenzt auf 600 Aktionen – pro Tag",
  },
  {
    id: "Limited to 1500 Actions - per day",
    EN: "Limited to 1500 Actions - per day",
    TR: "1500 İşlem ile Sınırlı - günlük",
    DE: "Begrenzt auf 1500 Aktionen – pro Tag",
  },
  {
    id: "Compare Marketplaces",
    EN: "Compare Marketplaces",
    TR: "Pazar Yerlerini Karşılaştırma",
    DE: "Vergleichen Sie Marktplätze",
  },
  {
    id: "Limited to 1000 Products - for once",
    EN: "Limited to 1000 Products - for once",
    TR: "1000 Ürün ile Sınırlı - tek seferlik",
    DE: "Limitiert auf 10000 Produkte – ausnahmsweise",
  },
  {
    id: "Limited to 1000 Products - per month",
    EN: "Limited to 1000 Products - per month",
    TR: "1000 Ürün ile Sınırlı - aylık",
    DE: "Begrenzt auf 1000 Produkte – pro Monat",
  },
  {
    id: "Limited to 5K Products - per month",
    EN: "Limited to 5K Products - per month",
    TR: "5K Ürün ile Sınırlı - aylık",
    DE: "Begrenzt auf 5.000 Produkte – pro Monat",
  },
  {
    id: "Limited to 10K Products - per month",
    EN: "Limited to 10K Products - per month",
    TR: "10K Ürün ile Sınırlı - aylık",
    DE: "Begrenzt auf 10.000 Produkte – pro Monat",
  },
  {
    id: "Limited to 15K Products - per month",
    EN: "Limited to 15K Products - per month",
    TR: "15K Ürün ile Sınırlı - aylık",
    DE: "Begrenzt auf 15.000 Produkte – pro Monat",
  },
  {
    id: "Limited to 25K Products - per month",
    EN: "Limited to 25K Products - per month",
    TR: "25K Ürün ile Sınırlı - aylık",
    DE: "Begrenzt auf 25.000 Produkte – pro Monat",
  },
  {
    id: "Access to Historical Keepa Data",
    EN: "Access to Historical Keepa Data",
    TR: "Geçmiş Keepa Verilerine Erişim",
    DE: "Zugriff auf historische Keepa-Daten",
  },
  {
    id: "Access to Historical Data with user's Keepa Token",
    EN: "Access to Historical Data with user's Keepa Token",
    TR: "Kullanıcının Keepa Jetonları ile Geçmiş Verilere Erişim",
    DE: "Zugriff auf historische Daten mit dem Keepa-Token des Benutzers",
  },
  {
    id: "Store protection",
    EN: "Store protection",
    TR: "Mağaza Koruması",
    DE: "Schutz speichern",
  },
  {
    id: "You can protect your store with this feature. Our AsinSpy tool won't be available for your seller's market!",
    EN: "You can protect your store with this feature. Our AsinSpy tool won't be available for your seller's market!",
    TR: "Bu özellik ile mağazanızı koruyabilirsiniz. Bu sayede AsinSpy aracımız başkaları tarafından sizin marketinizde kullanılmaz!",
    DE: "Mit dieser Funktion können Sie Ihren Shop schützen. Unser AsinSpy-Tool ist für den Markt Ihres Verkäufers nicht verfügbar!",
  },
  {
    id: "Free Access for Beginners, Basic Features.",
    EN: "Free Access for Beginners, Basic Features.",
    TR: "Başlangıç Seviyesi için Ücretsiz Erişim, Temel Özellikler.",
    DE: "Kostenloser Zugang für Anfänger, Grundfunktionen.",
  },
  {
    id: "Economical and Powerful for Your Fundamental Needs.",
    EN: "Economical and Powerful for Your Fundamental Needs.",
    TR: "Temel İhtiyaçlarınız için Ekonomik ve Güçlü.",
    DE: "Wirtschaftlich und leistungsstark für Ihre grundlegenden Bedürfnisse.",
  },
  {
    id: "Boost Your Sales with Advanced Features.",
    EN: "Boost Your Sales with Advanced Features.",
    TR: "Gelişmiş Özelliklerle Satışlarınızı Artırın.",
    DE: "Steigern Sie Ihre Verkäufe mit fortgeschrittenen Funktionen.",
  },
  {
    id: "Fully Equipped, Superior Performance for Professionals.",
    EN: "Fully Equipped, Superior Performance for Professionals.",
    TR: "Profesyoneller İçin Tam Donanımlı, Üstün Performans.",
    DE: "Voll ausgestattet, überlegene Leistung für Profis.",
  },
  {
    id: "North America, Europe, Far East; if you want to action with whichever of these three marketplaces, you need to connect the marketplaces you want to action with. Because the system works using your SP-API.",
    EN: "North America, Europe, Far East; if you want to action with whichever of these three marketplaces, you need to connect the marketplaces you want to action with. Because the system works using your SP-API.",
    TR: "Amerika, Avrupa ve Asya-Pasifik; bu üç pazar yerinden hangisiyle veya hangileriyle işlem yapmak istiyorsanız işlem yapmak istediğiniz pazar yerlerini bağlamanız gerekir. Çünkü sistem sizin SP-API'nizi kullanarak çalışır.",
    DE: "Nordamerika, Europa, Fernost; wenn Sie auf einem dieser drei Marktplätze agieren möchten, müssen Sie die Marktplätze verbinden, auf denen Sie agieren möchten. Denn das System arbeitet mit Ihrer SP-API.",
  },
  {
    id: "Amazon marketplace connection required for Product Based Info and Compare Marketplaces.",
    EN: "Amazon marketplace connection required for Product Based Info and Compare Marketplaces.",
    TR: "Ürün Botu ve Market Karşılaştırma özellikleri için Amazon pazar yeri bağlantısı zorunludur.",
    DE: "Marktplatzanbindung erforderlich für „Produktbasierte Informationen“ und „Marktplätze vergleichen“.",
  },
  {
    id: "Which marketplaces does Kepicker work in?",
    EN: "Which marketplaces does Kepicker work in?",
    TR: "Kepicker hangi pazar yerlerinde çalışır?",
    DE: "In welchen Marktplätzen funktioniert Kepicker?",
  },
  {
    id: "Kepicker operates across all Amazon marketplaces, whether in the Americas, Europe, or Asia-Pacific. To utilize it in your desired marketplace, you must connect the specific marketplace(s) you wish to work with. This is because the system operates using your SP-API.",
    EN: "Kepicker operates across all Amazon marketplaces, whether in the Americas, Europe, or Asia-Pacific. To utilize it in your desired marketplace, you must connect the specific marketplace(s) you wish to work with. This is because the system operates using your SP-API.",
    TR: "Kepicker tüm Amazon pazar yerlerinde çalışır, Amerika, Avrupa veya Asya-Pasifik pazar yerlerinden hangisiyle veya hangileriyle işlem yapmak istiyorsanız işlem yapmak istediğiniz pazar yerlerini bağlamanız gerekir. Çünkü sistem sizin SP-API'nizi kullanarak çalışır.",
    DE: "Kepicker funktioniert in allen Amazon-Marktplätzen, sei es in Amerika, Europa oder im asiatisch-pazifischen Raum. Um es in Ihrem gewünschten Marktplatz zu nutzen, müssen Sie die spezifischen Marktplätze verbinden, mit denen Sie arbeiten möchten. Dies liegt daran, dass das System unter Verwendung Ihres SP-APIs funktioniert.",
  },
  {
    id: "Is Kepicker reliable, and does it view my store information?",
    EN: "Is Kepicker reliable, and does it view my store information?",
    TR: "Kepicker güvenilir mi, mağaza bilgilerimi görüyor mu?",
    DE: "Ist Kepicker zuverlässig und sieht es meine Shop-Informationen ein?",
  },
  {
    id: "Rest assured, Kepicker does not use third-party software for market connections like some other applications, so be cautious. If you encounter a software using a different tool for market connection, it is advisable to reconsider, as this might mean your data is being shared with other tools. Kepicker's requirement for market connection is due to its operation via SP-API, which is currently the most secure and accurate solution.",
    EN: "Rest assured, Kepicker does not use third-party software for market connections like some other applications, so be cautious. If you encounter a software using a different tool for market connection, it is advisable to reconsider, as this might mean your data is being shared with other tools. Kepicker's requirement for market connection is due to its operation via SP-API, which is currently the most secure and accurate solution.",
    TR: "Merak etmeyin, Kepicker market bağlantınızı yaparken bazı yazılımlar gibi üçüncü part yazılımları kullanmaz, buna dikkat edin. Market bağlantısı yaparken farklı bir araç kullanan yazılıma denk gelirseniz hemen vazgeçin. Çünkü bu verilerinizin diğer araçlarla da paylaşılacağı anlamına gelir. Kepicker'ın market bağlantısı istemesinin sebebi SP-API kullanarak işlemlerini yapmasından kaynaklıdır, bu şuan en güvenilir ve en doğru çözümdür. Kepicker sizin marketinizde yer alan ürünlerinizi ya da buna bağlı hiçbir veriyi GÖREMEZ!!!",
    DE: "Seien Sie unbesorgt, Kepicker verwendet im Gegensatz zu einigen anderen Anwendungen keine Drittanbieter-Software für die Marktverbindung, seien Sie also vorsichtig. Wenn Sie auf eine Software stoßen, die ein anderes Werkzeug für die Marktverbindung verwendet, sollten Sie dies überdenken, da dies bedeuten könnte, dass Ihre Daten mit anderen Tools geteilt werden. Kepickers Anforderung für eine Marktverbindung erfolgt aufgrund seiner Funktion über SP-API, was derzeit die sicherste und genaueste Lösung ist. Kepicker KANN KEINE Ihrer Produkte oder damit verbundenen Daten auf Ihrem Marktplatz sehen.",
  },
  {
    id: "Why is it mandatory to connect the marketplace?",
    EN: "Why is it mandatory to connect the marketplace?",
    TR: "Market bağlama zorunluluğunun sebebi nedir?",
    DE: "Warum ist die Verbindung mit dem Marktplatz verpflichtend?",
  },
  {
    id: "The system uses your SP-API to perform comparisons across different marketplaces.",
    EN: "The system uses your SP-API to perform comparisons across different marketplaces.",
    TR: "Sistem sizin SP-API'nizi kullanarak pazar yerleri arasında karşılaştırma yapar.",
    DE: "Das System verwendet Ihr SP-API, um Vergleiche zwischen verschiedenen Marktplätzen durchzuführen.",
  },
  {
    id: "Can I cancel my subscription at any time?",
    EN: "Can I cancel my subscription at any time?",
    TR: "Herhangi bir zaman aboneliğimi iptal edebilir miyim?",
    DE: "Kann ich mein Abonnement jederzeit kündigen?",
  },
  {
    id: "Certainly, you can cancel your subscription whenever you wish. Upon cancellation, any remaining usage limit will continue until the end of your current billing cycle.",
    EN: "Certainly, you can cancel your subscription whenever you wish. Upon cancellation, any remaining usage limit will continue until the end of your current billing cycle.",
    TR: "Elbette, istediğiniz zaman aboneliğinizi iptal edebilirsiniz. İptal ettiğiniz aşamada kalan kullanım limitiniz son kullanım zamanınıza kadar devam eder.",
    DE: "Natürlich können Sie Ihr Abonnement jederzeit kündigen. Nach der Kündigung wird Ihr verbleibendes Nutzungslimit bis zum Ende Ihres aktuellen Abrechnungszeitraums fortgesetzt.",
  },
  {
    id: "Can I change my package at any time?",
    EN: "Can I change my package at any time?",
    TR: "Kullandığım paketi istediğim zaman değiştirebilir miyim?",
    DE: "Kann ich mein Paket jederzeit ändern?",
  },
  {
    id: "Absolutely, you may upgrade or downgrade your package whenever you desire.",
    EN: "Absolutely, you may upgrade or downgrade your package whenever you desire.",
    TR: "Tabiki de, isterseniz paketinizi yükseltebilir veya bir alt pakete geçebilirsiniz.",
    DE: "Selbstverständlich können Sie Ihr Paket jederzeit upgraden oder downgraden.",
  },
  {
    id: "Is it possible to delete my account?",
    EN: "Is it possible to delete my account?",
    TR: "Hesabımı silmek istersem bu mümkün mü?",
    DE: "Ist es möglich, mein Konto zu löschen?",
  },
  {
    id: "Yes, you can delete your account. However, your data may be retained for a period post-deletion. This is a precautionary measure in case you have inquiries related to payments or other issues, or in the event of any problems. If you open an account again with the same email account, you will lose all your previous data.",
    EN: "Yes, you can delete your account. However, your data may be retained for a period post-deletion. This is a precautionary measure in case you have inquiries related to payments or other issues, or in the event of any problems. If you open an account again with the same email account, you will lose all your previous data.",
    TR: "Evet, hesabınızı silebilirsiniz. Fakat silme işleminden sonra bir süre verileriniz tutulur, bunun sebebi ödemelerinizle ilgili veya başka bir sebeple ilgili soru sormak isterseniz ya da bir sorun oluşmasına karşı aldığımız önlemdir. Tekrar aynı e-posta hesabı ile hesap açarsanız daha önceki tüm verilerinizi kaybetmiş olacaksınız.",
    DE: "Ja, Sie können Ihr Konto löschen. Ihre Daten können jedoch nach der Löschung für einen Zeitraum aufbewahrt werden. Dies ist eine Vorsichtsmaßnahme für den Fall, dass Sie Fragen zu Zahlungen oder anderen Problemen haben oder im Falle von Problemen. Wenn Sie mit demselben E-Mail-Konto erneut ein Konto eröffnen, gehen alle Ihre bisherigen Daten verloren.",
  },
  {
    id: "If I encounter an issue with the extension, do you provide compensation?",
    EN: "If I encounter an issue with the extension, do you provide compensation?",
    TR: "Eklentide bir sorun ile karşılaşırsam sonrasında bunu telafi ediyor musunuz?",
    DE: "Erhalte ich eine Entschädigung, wenn ich auf ein Problem mit der Erweiterung stoße?",
  },
  {
    id: "Do not worry, if you face an issue and it is confirmed, our technical team will immediately send you additional tokens as compensation. After all, this is software, and glitches or problems can occur. We would appreciate your understanding in such situations.",
    EN: "Do not worry, if you face an issue and it is confirmed, our technical team will immediately send you additional tokens as compensation. After all, this is software, and glitches or problems can occur. We would appreciate your understanding in such situations.",
    TR: "Merak etmeyin, bir sorun ile karşılaşırsanız ve bu teyit edilirse teknik ekibimiz anında size fazladan jeton gönderecektir. Sonuçta bu bir yazılım, aksilikler ya da sorunlar çıkabilir, bu aşamada sizden anlayış bekliyor olacağız.",
    DE: "Keine Sorge, wenn Sie auf ein Problem stoßen und es bestätigt wird, wird unser technisches Team Ihnen sofort zusätzliche Token als Entschädigung senden. Schließlich handelt es sich hierbei um Software, und es können Störungen oder Probleme auftreten. Wir würden Ihr Verständnis in solchen Situationen schätzen.",
  },
  {
    id: "Update processing asin lists status to waiting",
    EN: "Update processing asin lists status to waiting",
    TR: "Güncelleme işlemi için asin listelerinin durumunu bekliyor olarak günceller",
    DE: "Update-Verarbeitungs-Asin-Listenstatus auf Warten aktualisieren",
  },
  {
    id: "Update waiting asin lists status to processing",
    EN: "Update waiting asin lists status to processing",
    TR: "Güncelleme işlemi için asin listelerinin durumunu işleniyor olarak günceller",
    DE: "Update-Wartelistenstatus für Asin-Listen auf Verarbeitung",
  },
  {
    id: "Asin lists",
    EN: "Asin lists",
    TR: "Asin listeleri",
    DE: "Asin-Listen",
  },
  {
    id: "Origin",
    EN: "Origin",
    TR: "Origin",
    DE: "Origin",
  },
  {
    id: "Asin List Name",
    EN: "Asin List Name",
    TR: "Asin Listesi Adı",
    DE: "Asin-Listenname",
  },
  {
    id: "Asin count",
    EN: "Asin count",
    TR: "Asin sayısı",
    DE: "Asin-Anzahl",
  },
  {
    id: "Calculated Asin count",
    EN: "Calculated Asin count",
    TR: "Hesaplanan Asin sayısı",
    DE: "Berechnete Asin-Anzahl",
  },
  {
    id: "User email",
    EN: "User email",
    TR: "Kullanıcı e-postası",
    DE: "Benutzer-E-Mail",
  },
  {
    id: "Tier Management",
    EN: "Tier Management",
    TR: "Paket Yönetimi",
    DE: "Paket Management",
  },
  {
    id: "Tiers",
    EN: "Tiers",
    TR: "Paketler",
    DE: "Pakete",
  },
  {
    id: "Your request has been sent. We will contact you as soon as possible!..",
    EN: "Your request has been sent. We will contact you as soon as possible!..",
    TR: "Talebiniz gönderilmiştir. En kısa sürede sizinle iletişime geçeceğiz!..",
    DE: "Ihre Anfrage wurde verschickt. Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen!..",
  },
  {
    id: "Funding Request",
    EN: "Funding Request",
    TR: "Kazanç Talebi",
    DE: "Finanzierungsanfrage",
  },
  {
    id: "$100 etc.",
    EN: "$100 etc.",
    TR: "100 Dolar gibi",
    DE: "100 Dollar usw.",
  },
  {
    id: "Quantity Requested ($)",
    EN: "Quantity Requested ($)",
    TR: "İstenen Miktar ($)",
    DE: "Angeforderte Menge ($)",
  },
  {
    id: "Generate New Code",
    EN: "Generate New Code",
    TR: "Yeni Kod Oluştur",
    DE: "Neuen Code Generieren",
  },
  {
    id: "Affiliate Code",
    EN: "Affiliate Code",
    TR: "Affilliate Kodu",
    DE: "Affiliate-Code",
  },
  {
    id: "Your Affiliate Code",
    EN: "Your Affiliate Code",
    TR: "Affilliate Kodunuz",
    DE: "Ihr Affiliate-Code",
  },
  {
    id: "Create a Funding Request",
    EN: "Create a Funding Request",
    TR: "Kazanç Talebi Oluştur",
    DE: "Eine Finanzierungsanfrage Stellen",
  },
  {
    id: "Payments are transferred to your account after one month.",
    EN: "Payments are transferred to your account after one month.",
    TR: "Ödemeler bir ay sonra hesabınıza aktarılır.",
    DE: "Zahlungen werden nach einem Monat auf Ihr Konto überwiesen.",
  },
  {
    id: "Total of Code Users",
    EN: "Total of Code Users",
    TR: "Kodu Kullananların Toplamı",
    DE: "Gesamtzahl der Code-Nutzer",
  },
  {
    id: "Commission Rate",
    EN: "Commission Rate",
    TR: "Komisyon Oranınız",
    DE: "Provisionssatz",
  },
  {
    id: "Total Earnings",
    EN: "Total Earnings",
    TR: "Toplam Kazancınız",
    DE: "Gesamtverdienst",
  },
  {
    id: "Your total earnings show the total amount you can now withdraw!",
    EN: "Your total earnings show the total amount you can now withdraw!",
    TR: "Toplam kazancınız, şimdi çekebileceğiniz toplam tutarı gösterir!",
    DE: "Ihre Gesamteinnahmen zeigen den Gesamtbetrag, den Sie jetzt abheben können!",
  },
  {
    id: "Affiliate Management",
    EN: "Affiliate Management",
    TR: "Affilliate Yönetimi",
    DE: "Affiliate-Management",
  },
  {
    id: "You can also earn money by inviting your friends for paid packages.",
    EN: "You can also earn money by inviting your friends for paid packages.",
    TR: "Ücretli paketler için arkadaşlarınızı davet ederek de para kazanabilirsiniz.",
    DE: "Sie können auch Geld verdienen, indem Sie Ihre Freunde zu bezahlten Paketen einladen.",
  },
  {
    id: "Copied to Clipboard!",
    EN: "Copied to Clipboard!",
    TR: "Panoya Kopyalandı!",
    DE: "In die Zwischenablage kopiert!",
  },
  {
    id: "Expected Earnings",
    EN: "Expected Earnings",
    TR: "Beklenen Kazanç",
    DE: "Auszahlbare Einkünfte",
  },
  {
    id: "Withdrawable Earnings",
    EN: "Withdrawable Earnings",
    TR: "Çekilebilir Kazanç",
    DE: "Erwartete Einnahmen",
  },
  {
    id: "If you want to buy extra comparison tokens, you need to upgrade your package!",
    EN: "If you want to buy extra comparison tokens, you need to upgrade your package!",
    TR: "Ekstra karşılaştırma jetonu satın almak isterseniz paketinizi yükseltmeniz gerekli.",
    DE: "Wenn Sie zusätzliche Vergleichstoken kaufen möchten, müssen Sie Ihr Paket upgraden!",
  },
  {
    id: "The comparison tokens you purchase will be valid until the expiry date of your current package.",
    EN: "The comparison tokens you purchase will be valid until the expiry date of your current package.",
    TR: "Satın alacağınız karşılaştırma jetonlaru mevcut paketinizin son kullanım gününe kadar geçerli olacaktır.",
    DE: "Die von Ihnen erworbenen Vergleichstoken sind bis zum Ablaufdatum Ihres aktuellen Pakets gültig.",
  },
  {
    id: "Extra tokens can be purchased.",
    EN: "Extra tokens can be purchased.",
    TR: "Ekstra jeton satın alınabilir.",
    DE: "Zusätzliche Token können erworben werden.",
  },
  {
    id: "Saved Card",
    EN: "Saved Card",
    TR: "Kayıtlı Kart",
    DE: "Gespeicherte Karte",
  },
  {
    id: "Next payment due",
    EN: "Next payment due",
    TR: "Bir sonraki ödeme zamanı",
    DE: "Nächste Zahlung fällig",
  },
  {
    id: "Unsubscribe",
    EN: "Unsubscribe",
    TR: "Aboneliği iptal et",
    DE: "Abbestellen",
  },
  {
    id: "Are you sure to cancel current subscription? (You will be able to use your current plan until the end of the next billing period)",
    EN: "Are you sure to cancel current subscription? (You will be able to use your current plan until the end of the next billing period)",
    TR: "Mevcut aboneliği iptal etmek istediğinize emin misiniz? (Mevcut planınızı bir sonraki fatura döneminin sonuna kadar kullanabilirsiniz)",
    DE: "Möchten Sie das aktuelle Abonnement wirklich kündigen? (Sie können Ihren aktuellen Plan bis zum Ende des nächsten Abrechnungszeitraums nutzen)",
  },
  {
    id: "Delete Account",
    EN: "Delete Account",
    TR: "Hesabı Sil",
    DE: "Konto Löschen",
  },
  {
    id: "Are you sure to delete your account? (You will lose all your data)",
    EN: "Are you sure to delete your account? (You will lose all your data)",
    TR: "Hesabınızı silmek istediğinize emin misiniz? (Tüm verileriniz kaybolacak)",
    DE: "Möchten Sie Ihr Konto wirklich löschen? (Sie verlieren alle Ihre Daten)",
  },
  {
    id: "Delete account in progress...",
    EN: "Delete account in progress...",
    TR: "Hesap silme işlemi devam ediyor...",
    DE: "Konto wird gelöscht...",
  },
  {
    id: "Delete account success!",
    EN: "Delete account success!",
    TR: "Hesap silme işlemi başarılı!",
    DE: "Konto erfolgreich gelöscht!",
  },
  {
    id: "Also includes Stock Fetcher and Kepicker Calculator.",
    EN: "Also includes Stock Fetcher and Kepicker Calculator.",
    TR: "Stok Çekici ve Kepicker Hesaplayıcı da dahildir.",
    DE: "Enthält außerdem Stock Fetcher und Kepicker Calculator.",
  },
  {
    id: "You can also get Business Prime discounts from amazon.com.",
    EN: "You can also get Business Prime discounts from amazon.com.",
    TR: "amazon.com'dan Business Prime indirimlerini de çekebilirsiniz.",
    DE: "Sie können auch Business Prime-Rabatte bei amazon.com erhalten.",
  },
  {
    id: "Subscription cancellation started.",
    EN: "Subscription cancellation started.",
    TR: "Abonelik iptali başlatıldı.",
    DE: "Die Kündigung des Abonnements hat begonnen.",
  },
  {
    id: "Subscription cancellation successful!",
    EN: "Subscription cancellation successful!",
    TR: "Abonelik iptali başarıyla tamamlandı!",
    DE: "Abo-Kündigung erfolgreich!",
  },
  {
    id: "Unsubscribe Successful!",
    EN: "Unsubscribe Successful!",
    TR: "Abonelik İptali Başarılı!",
    DE: "Erfolgreich Deabonniert!",
  },
  {
    id: "After deleting your account, you will not be able to log in anymore. All personal data will be deleted, all tool's data will be anonymized. This is irreversible.",
    EN: "After deleting your account, you will not be able to log in anymore. All personal data will be deleted, all tool's data will be anonymized. This is irreversible.",
    TR: "Hesabınızı sildikten sonra artık giriş yapamayacaksınız. Tüm kişisel verileriniz silinecek, tüm araç verileriniz anonimleştirilecektir. Bu geri döndürülemez.",
    DE: "Nach dem Löschen Ihres Kontos können Sie sich nicht mehr anmelden. Alle personenbezogenen Daten werden gelöscht, alle Werkzeugdaten werden anonymisiert. Das ist irreversibel.",
  },
  {
    id: "Warning!",
    EN: "Warning!",
    TR: "Uyarı!",
    DE: "Warnung!",
  },
  {
    id: "Delete My Account",
    EN: "Delete My Account",
    TR: "Hesabımı sil",
    DE: "Mein Konto löschen",
  },
  {
    id: "Now just a minute!",
    EN: "Now just a minute!",
    TR: "Bu son uyarıdır!",
    DE: "Jetzt nur noch eine Minute!",
  },
  {
    id: "Are you sure to delete your account? If you're sure, click Delete Account!!!",
    EN: "Are you sure to delete your account? If you're sure, click Delete Account!!!",
    TR: "Hesabınızı silmek istediğinizden emin misiniz? Eminseniz Hesabı Sil'e tıklayın!!!",
    DE: "Möchten Sie Ihr Konto wirklich löschen? Wenn Sie sicher sind, klicken Sie auf Konto löschen!!!",
  },
  {
    id: "Account deletion successful!",
    EN: "Account deletion successful!",
    TR: "Hesap silme işlemi başarılı!",
    DE: "Kontolöschung erfolgreich!",
  },
  {
    id: "All your personal data has been successfully deleted!",
    EN: "All your personal data has been successfully deleted!",
    TR: "Tüm kişisel verileriniz başarıyla silindi!",
    DE: "Alle Ihre persönlichen Daten wurden erfolgreich gelöscht!",
  },
  {
    id: "Yes, you can delete your account. When you delete your account, all your personal data will be deleted. Your other data regarding the Kepicker software continues to be kept anonymous.",
    EN: "Yes, you can delete your account. When you delete your account, all your personal data will be deleted. Your other data regarding the Kepicker software continues to be kept anonymous.",
    TR: "Evet hesabınızı silebilirsiniz. Hesabınızı sildiğinizde tüm kişisel verileriniz de silinecektir. Kepicker yazılımına ilişkin diğer verileriniz anonim tutulmaya devam edecektir.",
    DE: "Ja, Sie können Ihr Konto löschen. Wenn Sie Ihr Konto löschen, werden alle Ihre persönlichen Daten gelöscht. Ihre sonstigen Daten bezüglich der Kepicker-Software bleiben weiterhin anonym.",
  },
  {
    id: "Please verify your email!",
    EN: "Please verify your email!",
    TR: "Lütfen e-postanızı doğrulayın!",
    DE: "Bitte bestätigen Sie Ihre E-Mail!",
  },
  {
    id: "You're almost there! We sent an verification email to the email address you registered with. If you don't see it, you may need to check your spam folder.",
    EN: "You're almost there! We sent an verification email to the email address you registered with. If you don't see it, you may need to check your spam folder.",
    TR: "Neredeyse tamam! Kayıt olduğunuz e-posta adresine bir doğrulama e-postası gönderdik. Göremiyorsanız spam klasörünüzü kontrol etmeniz gerekebilir.",
    DE: "Du bist fast da! Wir haben eine Bestätigungs-E-Mail an die E-Mail-Adresse gesendet, mit der Sie sich registriert haben. Wenn Sie es nicht sehen, müssen Sie möglicherweise Ihren Spam-Ordner überprüfen.",
  },
  {
    id: "Go to Login page",
    EN: "Go to Login page",
    TR: "Giriş sayfasına gidin",
    DE: "Gehen Sie zur Anmeldeseite",
  },
  {
    id: "Problem? Let's start again.",
    EN: "Problem? Let's start again.",
    TR: "Sorun mu var? Tekrar deneyelim.",
    DE: "Problem? Lass uns noch einmal beginnen.",
  },
  {
    id: "for Once",
    EN: "for Once",
    TR: "Tek Seferlik",
    DE: "einmal",
  },
  {
    id: "Are you sure you want to delete this?",
    EN: "Are you sure you want to delete this?",
    TR: "Bunu silmek istediğinizden emin misiniz?",
    DE: "Sind Sie sicher, dass Sie dies löschen möchten?",
  },
  {
    id: "Are you sure you want to delete this?",
    EN: "Are you sure you want to delete this?",
    TR: "Bunu silmek istediğinizden emin misiniz?",
    DE: "Sind Sie sicher, dass Sie dies löschen möchten?",
  },
  {
    id: "Delete?",
    EN: "Delete?",
    TR: "Silinsin mi?",
    DE: "Löschen?",
  },
  {
    id: "Are you sure?",
    EN: "Are you sure?",
    TR: "Emin misin?",
    DE: "Bist du sicher?",
  },
  {
    id: "Are you sure about performing this action?",
    EN: "Are you sure about performing this action?",
    TR: "Bu işlemin gerçekleştirilmesi konusunda emin misin?",
    DE: "Sind Sie sicher, dass Sie diese Aktion ausführen möchten?",
  },
  {
    id: "Update ASIN lists status:",
    EN: "Update ASIN lists status:",
    TR: "ASIN listelerinin durumunu güncelleyin:",
    DE: "Status der ASIN-Listen aktualisierEN:",
  },
  {
    id: "Processing to Waiting",
    EN: "Processing to Waiting",
    TR: "İşleniyor'dan Bekleniyor'a",
    DE: "Verarbeitung bis Warten",
  },
  {
    id: "Waiting to Processing",
    EN: "Waiting to Processing",
    TR: "Bekleniyor'dan İşleniyor'a",
    DE: "Warten auf Bearbeitung",
  },
  {
    id: "Switch to Client",
    EN: "Switch to Client",
    TR: "Müşteri Moduna Geç",
    DE: "Wechseln Sie in den Kundenmodus",
  },
  {
    id: "Switch to Admin",
    EN: "Switch to Admin",
    TR: "Admin Moduna Geç",
    DE: "Wechseln Sie zu Admin",
  },
  {
    id: "DTokens",
    EN: "Comparison Tokens",
    TR: "Karşılaştırma Jetonları",
    DE: "Vergleichstoken",
  },
  {
    id: "Manage Setting",
    EN: "Manage Setting",
    TR: "Ayarları Yönet",
    DE: "Einstellungen verwalten",
  },
  {
    id: "New Setting",
    EN: "New Setting",
    TR: "Yeni Ayar",
    DE: "Neue Einstellung",
  },
  {
    id: "Saved Settings",
    EN: "Saved Settings",
    TR: "Kaydedilen Ayarlar",
    DE: "Gespeicherte Einstellungen",
  },
  {
    id: "Setting Name",
    EN: "Setting Name",
    TR: "Ayar Adı",
    DE: "Einstellungsname",
  },
  {
    id: "Custom setting",
    EN: "Custom setting",
    TR: "Özel Ayar",
    DE: "Benutzerdefinierte Einstellung",
  },
  {
    id: "Successfully deleted!..",
    EN: "Successfully deleted!..",
    TR: "Başarıyla silindi!..",
    DE: "Erfolgreich gelöscht!..",
  },
  {
    id: "Main setting ##1",
    EN: "Main setting ##1",
    TR: "Temel ayar ##1",
    DE: "Haupt-Einstellung ##1",
  },
  {
    id: "General Settings",
    EN: "General Settings",
    TR: "Genel Ayarlar",
    DE: "Allgemeine Einstellungen",
  },
  {
    id: "Shipping Pricing Settings",
    EN: "Shipping Pricing Settings",
    TR: "Kargo Fiyatlandırma Ayarları",
    DE: "Versandpreis-Einstellungen",
  },
  {
    id: "Select the shipping pricing settings you want to use for your products.",
    EN: "Select the shipping pricing settings you want to use for your products.",
    TR: "Ürünleriniz için kullanmak istediğiniz kargo fiyatlandırma ayarlarını seçin.",
    DE: "Wählen Sie die Versandpreis-Einstellungen aus, die Sie für Ihre Produkte verwenden möchten.",
  },
  {
    id: "Edit",
    EN: "Edit",
    TR: "Düzenle",
    DE: "Bearbeiten",
  },
  {
    id: "Shipping Pricing Settings",
    EN: "Shipping Pricing Settings",
    TR: "Kargo Fiyatlandırma Ayarları",
    DE: "Versandpreisgestaltungseinstellungen",
  },
  {
    id: "Select the shipping pricing settings you want to use for your products.",
    EN: "Select the shipping pricing settings you want to use for your products.",
    TR: "Ürünleriniz için kullanmak istediğiniz kargo fiyatlandırma ayarlarını seçin.",
    DE: "Wählen Sie die Versandpreisgestaltungseinstellungen aus, die Sie für Ihre Produkte verwenden möchten.",
  },
  {
    id: "Fixed Shipping Rate",
    EN: "Fixed Shipping Rate",
    TR: "Sabit Kargo Ücreti",
    DE: "Fester Versandpreis",
  },
  {
    id: "Apply a constant shipping rate for each product, irrespective of its weight or size. Specify the fixed shipping cost in your preferred currency for each item.",
    EN: "Apply a constant shipping rate for each product, irrespective of its weight or size. Specify the fixed shipping cost in your preferred currency for each item.",
    TR: "Ürün başına sabit bir kargo ücreti uygulayın, ağırlığı veya boyutu ne olursa olsun. Her bir ürün için tercih ettiğiniz para biriminde sabit kargo maliyetini belirtin.",
    DE: "Wenden Sie einen konstanten Versandpreis für jedes Produkt an, unabhängig von seinem Gewicht oder seiner Größe. Geben Sie die festen Versandkosten in Ihrer bevorzugten Währung für jedes Element an.",
  },
  {
    id: "Weight-Based Shipping",
    EN: "Weight-Based Shipping",
    TR: "Ağırlığa Göre Kargo",
    DE: "Gewichtsbasierter Versand",
  },
  {
    id: "Calculate the shipping cost based on the weight of the product. Enter a rate in your preferred currency per kilogram. This rate encompasses the cost of labeling each product.",
    EN: "Calculate the shipping cost based on the weight of the product. Enter a rate in your preferred currency per kilogram. This rate encompasses the cost of labeling each product.",
    TR: "Ürünün ağırlığına göre kargo ücretini hesaplayın. Tercih ettiğiniz para birimi cinsinden kilogram başına bir oran girin. Bu oran, her bir ürünün etiketleme maliyetini kapsar.",
    DE: "Berechnen Sie die Versandkosten basierend auf dem Gewicht des Produkts. Geben Sie einen Preis in Ihrer bevorzugten Währung pro Kilogramm ein. Dieser Preis umfasst die Kosten für die Beschriftung jedes Produkts.",
  },
  {
    id: "Weight-Based + Labeling",
    EN: "Weight-Based + Labeling",
    TR: "Ağırlığa Göre + Etiketleme",
    DE: "Gewichtsbasierter + Etikettierung",
  },
  {
    id: "Determine shipping cost based on product weight and include a distinct fee for labeling. Provide two rates: a weight-based shipping rate in your preferred currency per kilogram and an additional fixed labeling fee per item. This option offers a detailed cost breakdown, separating weight handling charges from labeling costs.",
    EN: "Determine shipping cost based on product weight and include a distinct fee for labeling. Provide two rates: a weight-based shipping rate in your preferred currency per kilogram and an additional fixed labeling fee per item. This option offers a detailed cost breakdown, separating weight handling charges from labeling costs.",
    TR: "Ürün ağırlığına göre kargo ücretini belirleyin ve etiketleme için ayrı bir ücret ekleyin. İki oran sağlayın: tercih ettiğiniz para birimi cinsinden kilogram başına ağırlığa dayalı bir kargo ücreti ve her bir ürün için ek bir sabit etiketleme ücreti. Bu seçenek, ağırlık işleme ücretlerini etiketleme maliyetlerinden ayıran ayrıntılı bir maliyet ayrıntısı sunar.",
    DE: "Bestimmen Sie die Versandkosten basierend auf dem Produktgewicht und fügen Sie eine separate Gebühr für die Beschriftung hinzu. Geben Sie zwei Preise an: einen gewichtsbasierten Versandpreis in Ihrer bevorzugten Währung pro Kilogramm und eine zusätzliche feste Beschriftungsgebühr pro Artikel. Diese Option bietet eine detaillierte Kostenaufschlüsselung, die die Gewichtsabwicklungskosten von den Beschriftungskosten trennt.",
  },
  {
    id: "FBA/FBM Setting",
    EN: "FBA/FBM Setting",
    TR: "FBA/FBM Ayarı",
    DE: "FBA/FBM-Einstellung",
  },
  {
    id: "Switching the Fulfillment Type for Amazon Listings (FBA/FBM)",
    EN: "Switching the Fulfillment Type for Amazon Listings (FBA/FBM)",
    TR: "Amazon Listelemeleri için Gönderim Türünü Değiştirme (FBA/FBM)",
    DE: "Ändern des Versandtyps für Amazon-Angebote (FBA/FBM)",
  },
  {
    id: "FBA (Fullfilled by Amazon)",
    EN: "FBA (Fullfilled by Amazon)",
    TR: "FBA (Amazon Lojistik)",
    DE: "FBA (Fullfilled by Amazon)",
  },
  {
    id: "FBM (Fulfilled by Merchant)",
    EN: "FBM (Fulfilled by Merchant)",
    TR: "FBM (Tedarikçi Tarafında Depolama)",
    DE: "FBM (Fulfilled by Merchant)",
  },
  {
    id: "Show discounted prices?",
    EN: "Show discounted prices?",
    TR: "İndirimli fiyatlar gösterilsin mi?",
    DE: "Ermäßigte Preise anzeigen?",
  },
  {
    id: "Do you want to see discounted prices for buyer side?",
    EN: "Do you want to see discounted prices for buyer side?",
    TR: "Alıcı tarafı için indirimli fiyatları görmek istiyor musunuz?",
    DE: "Möchten Sie die reduzierten Preise für die Käuferseite sehen?",
  },
  {
    id: "VAT Settings",
    EN: "VAT Settings",
    TR: "KDV Ayarları",
    DE: "MwSt. Einstellungen",
  },
  {
    id: "Please select correct VAT settings for your seller account.",
    EN: "Please select correct VAT settings for your seller account.",
    TR: "Lütfen satıcı hesabınız için doğru KDV ayarlarını seçiniz.",
    DE: "Bitte wählen Sie die richtigen MwSt. Einstellungen für Ihr Verkäuferkonto.",
  },
  {
    id: "Exempt from VAT (No VAT)",
    EN: "Exempt from VAT (No VAT)",
    TR: "KDV'den muaf (KDV yok)",
    DE: "Von der MwSt. befreit (keine MwSt.)",
  },
  {
    id: "VAT on profit",
    EN: "VAT on profit",
    TR: "Kâr üzerinden KDV",
    DE: "MwSt. auf Gewinn",
  },
  {
    id: "VAT on sales",
    EN: "VAT on sales",
    TR: "Satış üzerinden KDV",
    DE: "MwSt. auf Verkäufe",
  },
  {
    id: "If you’re a UK seller and you’re not registered for VAT then you need to select the Non-VAT Registered scheme. As a non-registered VAT seller, you still have to pay VAT on the cost of the product and you have to pay 20% VAT on top of the Amazon fees(if you’re a UK-based company)",
    EN: "If you’re a UK seller and you’re not registered for VAT then you need to select the Non-VAT Registered scheme. As a non-registered VAT seller, you still have to pay VAT on the cost of the product and you have to pay 20% VAT on top of the Amazon fees(if you’re a UK-based company)",
    TR: "Eğer Birleşik Krallık'ta satıcıysanız ve KDV için kayıtlı değilseniz, KDV'siz seçeneğini seçmeniz gerekmektedir. KDV kaydı olmayan bir satıcı olarak, ürün maliyeti üzerinden KDV ödemek zorundasınız ve eğer Birleşik Krallık merkezli bir şirket iseniz Amazon ücretlerinin üzerine %20 KDV ödemek zorundasınız.",
    DE: "Wenn Sie ein Verkäufer im Vereinigten Königreich sind und nicht für die MwSt registriert sind, dann müssen Sie das Nicht-MwSt-registrierte Schema wählen. Als nicht registrierter MwSt-Verkäufer müssen Sie immer noch die MwSt auf die Kosten des Produkts zahlen, und Sie müssen 20% MwSt zusätzlich zu den Amazon-Gebühren zahlen (wenn Sie ein in Großbritannien ansässiges Unternehmen sind).",
  },
  {
    id: "When sending goods to a US fulfillment center, you don’t have to pay sales tax in the US. However, as soon as you ship inventory to Europe (for example to a fulfillment center when using Fulfillment by Amazon, or FBA), you must pay that country’s import VAT.",
    EN: "When sending goods to a US fulfillment center, you don’t have to pay sales tax in the US. However, as soon as you ship inventory to Europe (for example to a fulfillment center when using Fulfillment by Amazon, or FBA), you must pay that country’s import VAT.",
    TR: "ABD'ye bir teslimat merkezine mal gönderirken, ABD'de satış vergisi ödemeniz gerekmez. Ancak, envanteri Avrupa'ya gönderirken (örneğin, Amazon tarafından Teslimat veya FBA kullanırken bir teslimat merkezine) o ülkenin ithalat KDV'sini ödemeniz gerekir.",
    DE: "Wenn Sie Waren an ein US-Erfüllungszentrum senden, müssen Sie in den USA keine Umsatzsteuer zahlen. Sobald Sie jedoch Inventar nach Europa versenden (zum Beispiel an ein Erfüllungszentrum, wenn Sie Fulfillment by Amazon oder FBA nutzen), müssen Sie die Einfuhrumsatzsteuer des jeweiligen Landes zahlen.",
  },
  {
    id: "Unlike in the US, where sales tax is added to the list price, VAT is included in the list price in the EU. Let’s say that you sell a product on Amazon.com for $100. To sell it for the equivalent price in the UK, you would list it on Amazon.co.uk for $120 (assuming 20% VAT). This is important to remember when analyzing competitive pricing on Amazon’s European marketplaces.",
    EN: "Unlike in the US, where sales tax is added to the list price, VAT is included in the list price in the EU. Let’s say that you sell a product on Amazon.com for $100. To sell it for the equivalent price in the UK, you would list it on Amazon.co.uk for $120 (assuming 20% VAT). This is important to remember when analyzing competitive pricing on Amazon’s European marketplaces.",
    TR: "ABD'de satış vergisinin liste fiyatına eklendiği durumun aksine, AB'de KDV liste fiyatına dahildir. Diyelim ki Amazon.com'da bir ürünü 100 dolar karşılığında satıyorsunuz. Aynı ürünü Birleşik Krallık'ta eşdeğer fiyata satmak için, Amazon.co.uk'te %20 KDV varsayarak 120 dolar olarak listeleyebilirsiniz. Bu, Amazon'un Avrupa pazar yerlerinde rekabetçi fiyatlandırmayı analiz ederken hatırlanması önemli bir noktadır.",
    DE: "Im Gegensatz zu den USA, wo die Umsatzsteuer zum Listenpreis hinzugefügt wird, ist die MwSt im Listenpreis in der EU enthalten. Angenommen, Sie verkaufen ein Produkt auf Amazon.com für 100 Dollar. Um es zum äquivalenten Preis im Vereinigten Königreich zu verkaufen, würden Sie es auf Amazon.co.uk für 120 Dollar listen (unter der Annahme von 20% MwSt). Dies ist wichtig zu bedenken, wenn Sie die Wettbewerbspreise auf den europäischen Marktplätzen von Amazon analysieren.",
  },
  {
    id: "Show",
    EN: "Show",
    TR: "Göster",
    DE: "Show",
  },
  {
    id: "Hide",
    EN: "Hide",
    TR: "Gizle",
    DE: "Zeigen",
  },
  {
    id: "Custom setting etc.",
    EN: "Custom setting etc.",
    TR: "Özel ayar gibi",
    DE: "Benutzerdefinierte Einstellung usw.",
  },
  {
    id: "10 etc.",
    EN: "10 etc.",
    TR: "10 gibi",
    DE: "10 usw.",
  },
  {
    id: "Why should I connect my market?",
    EN: "Why should I connect my market?",
    TR: "Neden marketimi bağlamalıyım?",
    DE: "Warum sollte ich meinen Marktplatz anbinden?",
  },
  {
    id: "We're developing software with SP-Api support, and we use SP-Api to gather essential market and product information while working with you. This is vital for our software's efficiency and providing top-notch service. Don't worry, we do not access your important and personal data. Don't hesitate to reach out for questions or more info. We're here to serve you.",
    EN: "We're developing software with SP-Api support, and we use SP-Api to gather essential market and product information while working with you. This is vital for our software's efficiency and providing top-notch service. Don't worry, we do not access your important and personal data. Don't hesitate to reach out for questions or more info. We're here to serve you.",
    TR: "Gerekli pazar ve ürün bilgilerini toplamak için SP-Api'yi kullanıyoruz. Bu, yazılımımızın verimliliği ve birinci sınıf hizmet sunması açısından hayati öneme sahiptir. Endişelenmeyin, önemli ve kişisel verilerinize erişmiyoruz. Sorularınız veya daha fazla bilgi için bizimle iletişime geçmekten çekinmeyin. Size hizmet etmek için buradayız.",
    DE: "Wir verwenden SP-Api, um notwendige Markt- und Produktinformationen zu sammeln. Dies ist entscheidend für die Effizienz unserer Software und die erstklassige Servicebereitstellung. Keine Sorge, wir greifen nicht auf Ihre wichtigen und persönlichen Daten zu. Kontaktieren Sie uns gerne mit Ihren Fragen oder weiteren Informationen.",
  },
  {
    id: "Until next due",
    EN: "Until next due",
    TR: "Bir sonraki ödeme zamanına kadar",
    DE: "Bis zum nächsten Fälligkeitstermin",
  },
  {
    id: "Show Keepa Data?",
    EN: "Show Keepa Data?",
    TR: "Keepa Verileri Gösterilsin mi?",
    DE: "Keepa Daten anzeigen?",
  },
  {
    id: "Do you want to include historical data by Keepa in Product Based Info?",
    EN: "Do you want to include historical data by Keepa in Product Based Info?",
    TR: "Ürün Botu'na Keepa tarafından sağlanan tarihsel verileri dahil etmek ister misiniz?",
    DE: "Möchten Sie historische Daten von Keepa in produktbasierte Informationen einbeziehen?",
  },
  {
    id: "Accounts that are suspended or have low account health can be blocked by Amazon. This causes the extension to cause problems.",
    EN: "Accounts that are suspended or have low account health can be blocked by Amazon. This causes the extension to cause problems.",
    TR: "Askıya alınan ya da hesap sağlığı düşük hesaplar Amazon tarafından engellenebilmektedir. Bu eklentinin sorun vermesine sebep olur.",
    DE: "Konten, die gesperrt sind oder deren Zustand niedrig ist, können von Amazon gesperrt werden. Dies führt dazu, dass das Plugin Probleme verursacht.",
  },
  {
    id: "The marketplace you will connect must be an account that is actively used and makes sales. (Why?)",
    EN: "The marketplace you will connect must be an account that is actively used and makes sales. (Why?)",
    TR: "Bağlanacak hesabın aktif kullanılıyor olması ve satış yapıyor olmasına dikkat edin. (Neden?)",
    DE: "Der Marktplatz, den Sie verbinden, muss ein Konto sein, das aktiv genutzt wird und Verkäufe tätigt. (Warum?)",
  },
  {
    id: "My new preference...",
    EN: "My new preference...",
    TR: "Yeni ayarım...",
    DE: "Meine neue Einstellung...",
  },
  {
    id: "Market Comparison",
    EN: "Market Comparison",
    TR: "Market Karşılaştırma",
    DE: "Vergleichen Sie Märkte",
  },
  {
    id: "Seller's Stock Fetcher",
    EN: "Seller's Stock Fetcher",
    TR: "Stok Çekme Aracı",
    DE: "Stock Fetcher des Verkäufers",
  },
  {
    id: "Historical Data (Keepa)",
    EN: "Historical Data (Keepa)",
    TR: "Geçmiş Veriler (Keepa)",
    DE: "Historische Daten (Keepa)",
  },
  {
    id: "Additional Product Infos",
    EN: "Additional Product Infos",
    TR: "Detaylı Ürün Bilgileri",
    DE: "Weitere Produktdetails",
  },
  {
    id: "All-in-One Amazon Analytics and Product Research Hub!",
    EN: "All-in-One Amazon Analytics and Product Research Hub!",
    TR: "Kepicker İle Amazon Satışlarınızda Fark Yaratın!",
    DE: "Alles-in-einem Amazon Analytik- und Produktforschungszentrum!",
  },
  {
    id: "Kepicker Showcase: Advanced Tools for Amazon Sellers",
    EN: "Kepicker Showcase: Advanced Tools for Amazon Sellers",
    TR: "Kepicker'ı Keşfet: Amazon Satıcıları için Gelişmiş Araçlar",
    DE: "Kepicker Schaufenster: Erweiterte Tools für Amazon-Verkäufer",
  },
  {
    id: "Maximize Profits. Minimize Effort. Navigate. Analyze. Excel.",
    EN: "Maximize Profits. Minimize Effort. Navigate. Analyze. Excel.",
    TR: "Analiz Edin. Ürünleri Karşılaştırın. Kazancınızı Maksimize Edin!",
    DE: "Maximieren Sie Gewinne. Minimieren Sie Aufwand. Navigieren. Analysieren.",
  },
  {
    id: "Show ASIN List",
    EN: "Show ASIN List",
    TR: "ASIN Listesini Göster",
    DE: "ASIN-Liste Anzeigen",
  },
  {
    id: "Show Comparison Result",
    EN: "Show Comparison Result",
    TR: "Karşılaştırma Sonucunu Göster",
    DE: "Vergleichsergebnis Anzeigen",
  },
  {
    id: "Show Seller Data",
    EN: "Show Seller Data",
    TR: "Satıcı Verilerini Göster",
    DE: "Verkäuferdaten Anzeigen",
  },
  {
    id: "Hazmat Product",
    EN: "Hazmat Product",
    TR: "Tehlikeli Ürün",
    DE: "Gefahrgutprodukt",
  },
  {
    id: "Variation Product",
    EN: "Variation Product",
    TR: "Varyasyonlu Ürün",
    DE: "Variationsprodukt",
  },
  {
    id: "Kepicker's Market Comparison feature allows users to identify the most profitable and suitable products by comparing Asin lists across different Amazon markets. This tool facilitates strategic decision-making for maximizing revenue on Amazon.",
    EN: "Kepicker's Market Comparison feature allows users to identify the most profitable and suitable products by comparing Asin lists across different Amazon markets. This tool facilitates strategic decision-making for maximizing revenue on Amazon.",
    TR: "Kepicker'ın Market Karşılaştırma özelliği; kullanıcıların farklı Amazon pazarlarındaki Asin listelerini karşılaştırarak en karlı ve uygun ürünleri belirlemelerini sağlar. Bu araç, Amazon'da geliri maksimize etmek için stratejik karar alma sürecini kolaylaştırır.",
    DE: "Die Marktvergleichsfunktion von Kepicker ermöglicht es Benutzern, die rentabelsten und geeignetsten Produkte zu identifizieren, indem sie Asin-Listen über verschiedene Amazon-Märkte vergleichen. Dieses Werkzeug erleichtert die strategische Entscheidungsfindung, um den Umsatz auf Amazon zu maximieren.",
  },
  {
    id: "The Seller's Stock Fetcher feature in Kepicker enables users to obtain detailed stock information for a product. This tool is essential for managing inventory levels and making informed purchasing decisions.",
    EN: "The Seller's Stock Fetcher feature in Kepicker enables users to obtain detailed stock information for a product. This tool is essential for managing inventory levels and making informed purchasing decisions.",
    TR: "Kepicker'daki Stok Çekme özelliği, kullanıcıların bir ürün için detaylı stok bilgilerini elde etmelerini sağlar. Bu araç; envanter seviyelerini yönetmek isteyen ve bilinçli satın alma kararları almak isteyenler için hayati öneme sahiptir.",
    DE: "Die Funktion Stock Fetcher des Verkäufers in Kepicker ermöglicht es Benutzern, detaillierte Lagerinformationen für ein Produkt zu erhalten. Dieses Werkzeug ist wesentlich für die Verwaltung von Lagerbeständen und das Treffen informierter Kaufentscheidungen.",
  },
  {
    id: "The Additional Product Infos feature in Kepicker provides users with ROI calculations and other crucial pricing details embedded directly on the Amazon product page. This tool aids in making informed decisions by offering immediate financial insights into products.",
    EN: "The Additional Product Infos feature in Kepicker provides users with ROI calculations and other crucial pricing details embedded directly on the Amazon product page. This tool aids in making informed decisions by offering immediate financial insights into products.",
    TR: "Kepicker'daki Detaylı Ürün Bilgileri özelliği, kullanıcılara ROI hesaplamaları sunar ve Amazon ürün sayfasında ilgili ürüne dair diğer önemli fiyatlandırma detaylarını gösterir. Bu araç, ürünlere ilişkin finansal içgörüler sunarak bilinçli kararlar almanızı sağlar.",
    DE: "Die Funktion Weitere Produktdetails in Kepicker bietet Benutzern ROI-Berechnungen und andere wesentliche Preisdetails, die direkt auf der Amazon-Produktseite eingebettet sind. Dieses Werkzeug hilft, informierte Entscheidungen zu treffen, indem es sofortige finanzielle Einblicke in Produkte bietet.",
  },
  {
    id: "The Historical Data (Keepa) feature in Kepicker allows users to access essential historical Keepa data for products, offering insights into price changes and trends over time. This tool is invaluable for strategizing pricing and understanding market dynamics.",
    EN: "The Historical Data (Keepa) feature in Kepicker allows users to access essential historical Keepa data for products, offering insights into price changes and trends over time. This tool is invaluable for strategizing pricing and understanding market dynamics.",
    TR: "Kepicker'daki Geçmiş Veriler (Keepa) özelliği, kullanıcıların ürünler için temel tarihsel Keepa verilerine erişmelerini sağlar, zaman içinde fiyat değişiklikleri ve trendler hakkında içgörüler sunar. Bu araç, fiyatlandırma stratejilerini geliştirmek ve pazar dinamiklerini anlamak için harika bilgiler sunar.",
    DE: "Die Funktion Historische Daten (Keepa) in Kepicker ermöglicht es Benutzern, auf detaillierte historische Keepa-Daten für Produkte zuzugreifen, und bietet Einblicke in Preisänderungen und Trends im Laufe der Zeit. Dieses Werkzeug ist unschätzbar für die Preisstrategie und das Verständnis der Marktdynamik.",
  },
  {
    id: "Privacy",
    EN: "Privacy",
    TR: "Gizlilik",
    DE: "Privatsphäre",
  },
  {
    id: "Payment is in test mode. You won't be charged.",
    EN: "Payment is in test mode. You won't be charged.",
    TR: "Ödeme test modunda. Ücretlendirme yapılmayacaktır.",
    DE: "Die Zahlung befindet sich im Testmodus. Es werden keine Kosten berechnet.",
  },
  {
    id: "Subscription Cancelled",
    EN: "Subscription Cancelled",
    TR: "Abonelik İptal Edildi",
    DE: "Abonnement storniert",
  },
  {
    id: "Paid",
    EN: "Paid",
    TR: "Ödendi",
    DE: "Bezahlt",
  },
  {
    id: "Extra purchased comparison tokens expiry date",
    EN: "Extra purchased comparison tokens expiry date",
    TR: "Ekstra satın alınan karşılaştırma jetonlarının bitiş zamanı",
    DE: "Ablaufdatum der zusätzlich gekauften Vergleichstoken",
  },
  {
    id: "Extra Comparison Tokens",
    EN: "Extra Comparison Tokens",
    TR: "Ekstra Karşılaştırma Jetonu",
    DE: "Zusätzliche Vergleichstoken",
  },
  {
    id: "Contact Us",
    EN: "Contact Us",
    TR: "Bize Ulaşın",
    DE: "Kontaktiere uns",
  },
  {
    id: "Let's Grow Together",
    EN: "Let's Grow Together",
    TR: "Birlikte Büyüyelim",
    DE: "Lasst uns gemeinsam wachsen",
  },
  {
    id: "Get in Touch",
    EN: "Get in Touch",
    TR: "Kurumsal Çözümler",
    DE: "In Kontakt kommen",
  },
  {
    id: "Ready to take your business to the next level? Contact us today to enhance your competitive edge in the marketplace with Kepicker.",
    EN: "Ready to take your business to the next level? Contact us today to enhance your competitive edge in the marketplace with Kepicker.",
    TR: "Kepicker ile büyümeye hazır olun. İşinizi daha fazla büyütmek ve piyasadaki rekabet avantajınızı artırmak için bugün bizimle iletişime geçin.",
    DE: "Sind Sie bereit, Ihr Unternehmen auf die nächste Stufe zu heben? Kontaktieren Sie uns noch heute, um mit Kepicker Ihren Wettbewerbsvorteil auf dem Markt zu steigern.",
  },
  {
    id: "We are pleased to offer tailored solutions for corporate needs with our new Enterprise package. Designed to leverage the full benefits of Kepicker for businesses looking to elevate their Amazon selling experience to the next level, our Enterprise package is ideal for large-scale enterprises and high-volume sellers seeking to meet unique requirements.",
    EN: "We are pleased to offer tailored solutions for corporate needs with our new Enterprise package. Designed to leverage the full benefits of Kepicker for businesses looking to elevate their Amazon selling experience to the next level, our Enterprise package is ideal for large-scale enterprises and high-volume sellers seeking to meet unique requirements.",
    TR: "Kurumsal düzeyde ihtiyaçlarınız için özel bir çözüm arıyorsanız, Kepicker ile işbirliği yapmanın nasıl bir fark yaratabileceğini keşfedin. Enterprise paketimiz ve özelleştirilmiş çözümlerimiz hakkında daha fazla bilgi almak için lütfen bizimle iletişime geçin. Ekibimiz, işletmenize en uygun planı ve çözümleri belirlemek için sizinle birlikte çalışmaktan memnuniyet duyar.",
    DE: "Wir freuen uns, mit unserem neuen Enterprise-Paket maßgeschneiderte Lösungen für Unternehmensbedürfnisse anbieten zu können. Unser Enterprise-Paket wurde entwickelt, um alle Vorteile von Kepicker für Unternehmen zu nutzen, die ihr Amazon-Verkaufserlebnis auf die nächste Stufe heben möchten. Es ist ideal für Großunternehmen und Großverkäufer, die besondere Anforderungen erfüllen möchten.",
  },
  {
    id: "Comparison Tokens",
    EN: "Comparison Tokens",
    TR: "Karşılaştırma Jetonları",
    DE: "Vergleichstoken",
  },
  {
    id: "Product Based Info Limit",
    EN: "Product Based Info Limit",
    TR: "Ürün Botu Limiti",
    DE: "Produkt-Bot-Limit",
  },
  {
    id: "Extension Usages",
    EN: "Extension Usages",
    TR: "Eklenti Kullanımları",
    DE: "Plugin- Verwendungen",
  },
  {
    id: "Usage Limits",
    EN: "Usage Limits",
    TR: "Kullanım Limitiniz",
    DE: "Nutzungsbeschränkung",
  },
  {
    id: "Current Plan",
    EN: "Current Plan",
    TR: "Mevcut Plan",
    DE: "Derzeitiger Plan",
  },
  {
    id: "More comparison tokens",
    EN: "More comparison tokens",
    TR: "Karşılaştırma jetonu satın al",
    DE: "Weitere Vergleichstoken",
  },
  {
    id: "We're Sorry You Uninstalled Kepicker, Can We Know Why?",
    EN: "We're Sorry You Uninstalled Kepicker, Can We Know Why?",
    TR: "Ayrılığınız Bizi Üzdü, Nedenini Öğrenebilir miyiz?",
    DE: "Können Sie uns einen Moment Ihrer Zeit schenken?",
  },
  {
    id: "We noticed you've removed Kepicker and we're curious to know why. Could you spare a moment to share your feedback through a quick survey? Your insights are invaluable to us for providing better service in the future. We'll always be here, waiting for your return!",
    EN: "We noticed you've removed Kepicker and we're curious to know why. Could you spare a moment to share your feedback through a quick survey? Your insights are invaluable to us for providing better service in the future. We'll always be here, waiting for your return!",
    TR: "Kepicker'ı kaldırdığınızı gördük ve nedenini öğrenmek istiyoruz. Bize geri bildirimde bulunabilir misiniz? Görüşleriniz, gelecekte daha iyi hizmet verebilmemiz için çok değerli. Her zaman sizi bekliyor olacağız!",
    DE: "Wir haben bemerkt, dass Sie Kepicker entfernt haben und möchten gerne wissen warum. Könnten Sie sich einen Moment Zeit nehmen, um uns Ihr Feedback über eine kurze Umfrage mitzuteilen? Ihre Einsichten sind für uns sehr wertvoll, um in Zukunft einen besseren Service bieten zu können. Wir werden immer hier sein und auf Ihre Rückkehr warten!",
  },
  {
    id: "How It Works",
    EN: "How It Works",
    TR: "Kullanım Klavuzu",
    DE: "Wie es funktioniert",
  },
  {
    id: "Watch video",
    EN: "Watch video",
    TR: "Videoyu izle",
    DE: "Schau Video",
  },
  {
    id: "Begin your journey in Kepicker - Streamline your business, enhance market analysis, and boost sales effortlessly. Learn more now!",
    EN: "Begin your journey in Kepicker - Streamline your business, enhance market analysis, and boost sales effortlessly. Learn more now!",
    TR: "Kepicker ile Amazon satışlarınızı nasıl artıracağınızı keşfedin. İşinizi kolaylaştırın, pazar analizinizi güçlendirin ve kârınızı artırın!",
    DE: "Beginnen Sie Ihre Reise in Kepicker – Optimieren Sie Ihr Geschäft, verbessern Sie die Marktanalyse und steigern Sie mühelos den Umsatz. Jetzt mehr erfahren!",
  },
  {
    id: "Welcome to Kepicker!",
    EN: "Welcome to Kepicker!",
    TR: "Kepicker'a Hoş Geldiniz!",
    DE: "Willkommen bei Kepicker!",
  },
  {
    id: "By joining Kepicker, you've now further empowered your journey in the Amazon marketplace! To help you easily find everything you need, start exploring our features. If you have any questions or suggestions, feel free to reach out to us anytime.",
    EN: "By joining Kepicker, you've now further empowered your journey in the Amazon marketplace! To help you easily find everything you need, start exploring our features. If you have any questions or suggestions, feel free to reach out to us anytime.",
    TR: "Kepicker'a katılarak şimdi Amazon pazar yerinizdeki yolculuğunuzu daha da güçlendirdiniz! İhtiyacınız olan her şeyi kolayca bulmanıza yardımcı olmak adına, özelliklerimizi keşfetmeye başlayın. Sorularınız veya önerileriniz varsa, bize her zaman ulaşabilirsiniz.",
    DE: "Durch Ihren Beitritt zu Kepicker haben Sie Ihre Reise auf dem Amazon-Marktplatz jetzt noch weiter gestärkt! Um Ihnen zu helfen, alles, was Sie benötigen, leicht zu finden, beginnen Sie damit, unsere Funktionen zu erkunden. Wenn Sie Fragen oder Vorschläge haben, können Sie sich jederzeit an uns wenden.",
  },
  {
    id: "Make a difference on Amazon with Kepicker! Quickly compare markets, discover the most profitable products, and multiply your sales.",
    EN: "Make a difference on Amazon with Kepicker! Quickly compare markets, discover the most profitable products, and multiply your sales.",
    TR: "Kepicker ile Amazon'da fark yaratın! Pazarları hızla karşılaştırın, en kârlı ürünleri keşfedin ve satışlarınızı katlayın.",
    DE: "Machen Sie einen Unterschied auf Amazon mit Kepicker! Vergleichen Sie schnell Märkte, entdecken Sie die profitabelsten Produkte und vervielfachen Sie Ihre Verkäufe.",
  },
  {
    id: "Reset user tokens",
    EN: "Reset user tokens",
    TR: "Kullanıcı jetonlarını sıfırla",

    DE: "Benutzertoken zurücksetzen",
  },
  {
    id: "It's crucial to keep all your Chrome extensions, including Kepicker, up-to-date for optimal functionality. However, automatic updates can sometimes take up to 5 hours or not run at all. Don't worry, there's a solution! To ensure that you have the latest version of all your extensions, you need to update them manually.",
    EN: "It's crucial to keep all your Chrome extensions, including Kepicker, up-to-date for optimal functionality. However, automatic updates can sometimes take up to 5 hours or not run at all. Don't worry, there's a solution! To ensure that you have the latest version of all your extensions, you need to update them manually.",
    TR: "Tüm Chrome eklentilerinizi, Kepicker dahil, en iyi işlevsellik için güncel tutmak çok önemlidir. Ancak, otomatik güncellemeler bazen 5 saate kadar sürebilir veya hiç çalışmayabilir. Endişelenmeyin, bir çözüm var! Tüm eklentilerinizin en son sürümüne sahip olduğunuzdan emin olmak için, bunları manuel olarak güncellemeniz gerekmektedir.",
    DE: "Es ist entscheidend, alle Ihre Chrome-Erweiterungen, einschließlich Kepicker, auf dem neuesten Stand zu halten, um optimale Funktionalität zu gewährleisten. Automatische Updates können jedoch manchmal bis zu 5 Stunden dauern oder gar nicht ausgeführt werden. Keine Sorge, es gibt eine Lösung! Um sicherzustellen, dass Sie die neueste Version aller Ihrer Erweiterungen haben, müssen Sie sie manuell aktualisieren.",
  },
  {
    id: "How to update Chrome extensions?",
    EN: "How to update Chrome extensions?",
    TR: "Chrome uzantıları nasıl güncellenir?",
    DE: "Wie aktualisiere ich Chrome-Erweiterungen?",
  },
  {
    id: "You have chosen to downgrade your package. This action may result in the limitation or removal of some of your features. Please consider the impact of this change on your business.",
    EN: "You have chosen to downgrade your package. This action may result in the limitation or removal of some of your features. Please consider the impact of this change on your business.",
    TR: "Paketi düşürmeyi seçtiniz. Bu işlem, bazı özelliklerinizin kısıtlanmasına veya kullanımdan kaldırılmasına neden olabilir. Lütfen, bu değişikliğin işletmeniz üzerindeki etkilerini göz önünde bulundurun.",
    DE: "Sie haben sich entschieden, Ihr Paket herabzustufen. Dieser Schritt kann die Einschränkung oder Entfernung einiger Ihrer Funktionen zur Folge haben. Bitte berücksichtigen Sie die Auswirkungen dieser Änderung auf Ihr Geschäft.",
  },
  {
    id: "You are about to upgrade your package! This will provide you with additional features and capacities, offering the opportunity to further grow your business. Once the upgrade process is completed, you will start benefiting from the advantages of your new plan in the next billing period.",
    EN: "You are about to upgrade your package! This will provide you with additional features and capacities, offering the opportunity to further grow your business. Once the upgrade process is completed, you will start benefiting from the advantages of your new plan in the next billing period.",
    TR: "Paketinizi yükseltmek üzeresiniz! Bu, size daha fazla özellik ve kapasiteler sunarak işinizi daha da büyütme fırsatı verecek. Yükseltme işlemi tamamlandığında, yeni paketinizin avantajlarından bir sonraki fatura döneminde yararlanmaya başlayabilirsiniz.",
    DE: "Sie stehen kurz davor, Ihr Paket zu verbessern! Dies bietet Ihnen die Möglichkeit, Ihr Geschäft mit zusätzlichen Funktionen und Kapazitäten weiter auszubauen. Nach Abschluss des Upgrades können Sie von den Vorteilen Ihres neuen Pakets im nächsten Abrechnungszeitraum profitieren.",
  },
  {
    id: "Are you sure you want to switch to a lower package?",
    EN: "Are you sure you want to switch to a lower package?",
    TR: "Daha düşük bir pakete geçmek istediğinize emin misiniz?",
    DE: "Sind Sie sicher, dass Sie zu einem niedrigeren Paket wechseln möchten?",
  },
  {
    id: "Do you wish to proceed?",
    EN: "Do you wish to proceed?",
    TR: "Devam etmek istiyor musunuz?",
    DE: "Möchten Sie fortfahren?",
  },
  {
    id: "Subscription update request successfull. Check your email!",
    EN: "Subscription update request successfull. Check your email!",
    TR: "Abonelik güncelleme isteği başarıyla tamamlandı. E-postanızı kontrol edin!",
    DE: "Anfrage zur Abonnementaktualisierung erfolgreich. Check deine E-Mails!",
  },
  {
    id: "Subscription update request failed. Try again later",
    EN: "Subscription update request failed. Try again later",
    TR: "Abonelik güncelleme isteği başarısız oldu. Daha sonra tekrar deneyin",
    DE: "Die Anfrage zur Abonnementaktualisierung ist fehlgeschlagen. Versuchen Sie es später erneut",
  },
  {
    id: "Helpful Articles",
    EN: "Helpful Articles",
    TR: "Faydalı Makaleler",
    DE: "Hilfreiche Artikel",
  },
  {
    id: "Next plan",
    EN: "Next plan",
    TR: "Sonraki plan",
    DE: "Nächster Plan",
  },
  {
    id: "Changing packages too frequently may cause your account to be suspended!",
    EN: "Changing packages too frequently may cause your account to be suspended!",
    TR: "Çok sık paket değişikliği hesabınızın askıya alınmasına sebep olabilir!",
    DE: "Zu häufige Paketwechsel können zur Sperrung Ihres Kontos führen!",
  },
  {
    id: "Your next plan",
    EN: "Your next plan",
    TR: "Gelecek planın",
    DE: "Dein nächster Plan",
  },
  {
    id: "will be updated at",
    EN: "will be updated at",
    TR: "şu tarihte güncellenecek",
    DE: "wird am aktualisiert",
  },
  {
    id: "What is Kepicker Amazon Software and What Does It Do?",
    EN: "What is Kepicker Amazon Software and What Does It Do?",
    TR: "Kepicker Amazon Yazılımı Nedir ve Ne İşe Yarar?",
    DE: "Was ist die Kepicker-Amazon-Software und was macht sie?",
  },
  {
    id: "How Can I Install Kepicker Chrome Extension?",
    EN: "How Can I Install Kepicker Chrome Extension?",
    TR: "Kepicker Chrome Eklentisini Nasıl Kurabilirim?",
    DE: "Wie kann ich die Kepicker Chrome-Erweiterung installieren?",
  },
  {
    id: "How Can I Connect My Store? Why Do I Need to Link My Store?",
    EN: "How Can I Connect My Store? Why Do I Need to Link My Store?",
    TR: "Mağazamı Nasıl Bağlayabilirim? Neden Mağazamı Bağlamam Gerekiyor?",
    DE: "Wie kann ich meinen Shop anbinden? Warum muss ich meinen Shop verlinken?",
  },
  {
    id: "How to Use Kepicker Amazon Software? (General Explanation)",
    EN: "How to Use Kepicker Amazon Software? (General Explanation)",
    TR: "Kepicker Amazon Yazılımı Nasıl Kullanılır? (Genel Anlatım)",
    DE: "Wie verwende ich die Kepicker-Amazon-Software? (Allgemeine Erklärung)",
  },
  {
    id: "Asin Extractor Tool (Asin Scan, Compile, Grabber)",
    EN: "Asin Extractor Tool (Asin Scan, Compile, Grabber)",
    TR: "Asin Extractor Aracı (Asin Tarama, Derleme, Çekme)",
    DE: "Asin Extractor Tool (Asin Scan, Compile, Grabber)",
  },
  {
    id: "Storefront Scanning with ASIN SPY Tool - Store Copy",
    EN: "Storefront Scanning with ASIN SPY Tool - Store Copy",
    TR: "ASIN SPY Aracı ile Storefront Tarama - Mağaza Kopyalama",
    DE: "Storefront-Scannen mit dem ASIN SPY Tool – Store Copy",
  },
  {
    id: "Category Search with ASIN SPY Tool",
    EN: "Category Search with ASIN SPY Tool",
    TR: "ASIN SPY Aracı ile Kategori Arama",
    DE: "Kategoriesuche mit dem ASIN SPY Tool",
  },
  {
    id: "Uploader Tool (Uploading Asin List from External)",
    EN: "Uploader Tool (Uploading Asin List from External)",
    TR: "Uploader Tool (Dışarıdan Asin Listesi Yükleme)",
    DE: "Uploader-Tool (Asin-Liste von extern hochladen)",
  },
  {
    id: "Product Based Info - ROI Calculation - Kepicker Calculator",
    EN: "Product Based Info - ROI Calculation - Kepicker Calculator",
    TR: "Ürün Botu - ROI Hesaplama - Kepicker Hesaplayıcı - Detaylı Ürün Bilgileri",
    DE: "Produkt-Bot – ROI-Berechnung – Kepicker-Rechner",
  },
  {
    id: "Market Comparison (Comparing Amazon Marketplaces)",
    EN: "Market Comparison (Comparing Amazon Marketplaces)",
    TR: "Market Karşılaştırma (Amazon Pazar Yerlerini Karşılaştırma)",
    DE: "Marktvergleich (Vergleich von Amazon-Marktplätzen)",
  },
  {
    id: "Billing Information",
    EN: "Billing Information",
    TR: "Fatura Bilgisi",
    DE: "Rechnungsinformationen",
  },
  {
    id: "Person/Company Name",
    EN: "Person/Company Name",
    TR: "Kişi/Firma Adı",
    DE: "Person/Firmenname",
  },
  {
    id: "City/Town",
    EN: "City/Town",
    TR: "Şehir/Kasaba",
    DE: "Stadt/Gemeinde",
  },
  {
    id: "State/Province/Region",
    EN: "State/Province/Region",
    TR: "Eyalet/İl/Bölge",
    DE: "Bundesland/Provinz/Region",
  },
  {
    id: "Zip Code",
    EN: "Zip Code",
    TR: "Posta Kodu",
    DE: "Postleitzahl",
  },
  {
    id: "Country",
    EN: "Country",
    TR: "Ülke",
    DE: "Land",
  },
  {
    id: "Please enter billing information",
    EN: "Please enter billing information",
    TR: "Fatura bilgilerini giriniz",
    DE: "Bitte geben Sie die Rechnungsinformationen ein",
  },
  {
    id: "Confirm and continue",
    EN: "Confirm and continue",
    TR: "Onayla ve devam et",
    DE: "Bestätigen und fortfahren",
  },
  {
    id: "Billing Address",
    EN: "Billing Address",
    TR: "Fatura Adresi",
    DE: "Billing Address",
  },
  {
    id: "Product Review",
    EN: "Product Review",
    TR: "Ürün Bilgisi",
    DE: "Produktbewertung",
  },
  {
    id: "Product Review",
    EN: "Product Review",
    TR: "Ürün Bilgisi",
    DE: "Produktbewertung",
  },
  {
    id: "Product Review",
    EN: "Product Review",
    TR: "Ürün Bilgisi",
    DE: "Produktbewertung",
  },
  {
    id: "Payment",
    EN: "Payment",
    TR: "Ödeme",
    DE: "Zahlung",
  },
  {
    id: "Result",
    EN: "Result",
    TR: "Sonuç",
    DE: "Ergebnis",
  },
  {
    id: "If you delete your account, you cannot connect the markets you have linked to your new account!!!",
    EN: "If you delete your account, you cannot connect the markets you have linked to your new account!!!",
    TR: "Hesabınızı silmeniz durumunda bağladığınız marketleri yeni hesabınızda bağlayamazsınız!!!",
    DE: "Wenn Sie Ihr Konto löschen, können Sie die Märkte, die Sie mit Ihrem neuen Konto verknüpft haben, nicht mehr verbinden!!!",
  },
  {
    id: "Back",
    EN: "Back",
    TR: "Geri",
    DE: "Zurück",
  },

  {
    id: "Hello",
    EN: "Hello",
    TR: "Merhaba",
    DE: "Hallo",
  },
  {
    id: "We hope you're having a great day..",
    EN: "We hope you're having a great day..",
    TR: "Umarız harika bir gün geçiriyorsundur..",
    DE: "Wir hoffen, Sie haben einen tollen Tag..",
  },
  {
    id: "Beginner's Guide",
    EN: "Beginner's Guide",
    TR: "Başlangıç Kılavuzu",
    DE: "Anfängerleitfaden",
  },
  {
    id: "Connect your marketplace.",
    EN: "Connect your marketplace.",
    TR: "Pazar yerinizi bağlayın.",
    DE: "Verbinden Sie Ihren Marktplatz.",
  },
  {
    id: "How can I connect my marketplace?",
    EN: "How can I connect my marketplace?",
    TR: "Pazar yerimi nasıl bağlayabilirim?",
    DE: "Den Marktplatz vernetzen?",
  },
  {
    id: "Edit marketplace options.",
    EN: "Edit marketplace options.",
    TR: "Market ayarlarınızı yapın.",
    DE: "Marktplatzoptionen bearbeiten.",
  },
  {
    id: "Where are my marketplace settings?",
    EN: "Where are my marketplace settings?",
    TR: "Pazar yeri ayarlarım nerede?",
    DE: "Wo sind meine Marktplatzeinstellungen?",
  },
  {
    id: "Download the Kepicker extension.",
    EN: "Download the Kepicker extension.",
    TR: "Kepicker eklentisini indirin.",
    DE: "Laden Sie die Kepicker-Erweiterung herunter.",
  },
  {
    id: "Where do I download it?",
    EN: "Where do I download it?",
    TR: "Nereden indirebilirim?",
    DE: "Wo kann ich es herunterladen?",
  },
  {
    id: "Now you are ready to use the extension.",
    EN: "Now you are ready to use the extension.",
    TR: "Şimdi eklentiyi kullanmaya hazırsınız.",
    DE: "Jetzt sind Sie bereit, die Erweiterung zu verwenden.",
  },
  {
    id: "User guide for the extension?",
    EN: "User guide for the extension?",
    TR: "Eklenti için kullanım kılavuzu?",
    DE: "Benutzerhandbuch für die Erweiterung?",
  },
  {
    id: "Still having problems?",
    EN: "Still having problems?",
    TR: "Hala sorun mu yaşıyorsun?",
    DE: "Immer noch Probleme?",
  },
  {
    id: "Create Ticket",
    EN: "Create Ticket",
    TR: "Ticket Oluştur",
    DE: "Ticket erstellen",
  },
  {
    id: "Create a Support Request",
    EN: "Create a Support Request",
    TR: "Destek Talebi Oluştur",
    DE: "Erstellen Sie eine Supportanfrage",
  },
  {
    id: "Whatsapp Support",
    EN: "Whatsapp Support",
    TR: "Whatsapp Destek",
    DE: "Whatsapp-Support",
  },
  {
    id: "Extension usages",
    EN: "Extension usages",
    TR: "Eklenti kullanımları",
    DE: "Erweiterungsnutzungen",
  },
  {
    id: "Your usage rights for your plan",
    EN: "Your usage rights for your plan",
    TR: "Planınız için kullanım haklarınız",
    DE: "Ihre Nutzungsrechte für Ihren Plan",
  },
  {
    id: "Comparison Tokens",
    EN: "Comparison Tokens",
    TR: "Karşılaştırma Jetonları",
    DE: "Vergleichstokens",
  },
  {
    id: "Product Based Info Limit",
    EN: "Product Based Info Limit",
    TR: "Ürün Botu Limiti",
    DE: "Produkt-Bot-Limit",
  },
  {
    id: "Connect your markets",
    EN: "Connect your markets",
    TR: "Pazar yerlerinizi bağlayın",
    DE: "Verbinden Sie Ihre Märkte",
  },
  {
    id: "Connect",
    EN: "Connect",
    TR: "Bağlan",
    DE: "Verbinden",
  },
  {
    id: "Connected",
    EN: "Connected",
    TR: "Bağlandı",
    DE: "Verbunden",
  },
  {
    id: "Disconnect",
    EN: "Disconnect",
    TR: "Bağlantıyı kes",
    DE: "Trennen",
  },
  {
    id: "Plan details",
    EN: "Plan details",
    TR: "Plan detayları",
    DE: "Plandetails",
  },
  {
    id: "Manage your plan and payments.",
    EN: "Manage your plan and payments.",
    TR: "Planınızı ve ödemelerinizi yönetin.",
    DE: "Verwalten Sie Ihren Plan und Ihre Zahlungen.",
  },
  {
    id: "Current plan",
    EN: "Current plan",
    TR: "Mevcut plan",
    DE: "Aktueller Plan",
  },
  {
    id: "Payment",
    EN: "Payment",
    TR: "Ödeme",
    DE: "Zahlung",
  },
  {
    id: "Renew at",
    EN: "Renew at",
    TR: "Yenileme tarihi",
    DE: "Erneuern bei",
  },
  {
    id: "Action center",
    EN: "Action center",
    TR: "Eylem merkezi",
    DE: "Aktionszentrum",
  },
  {
    id: "You're running out of comparison tokens!",
    EN: "You're running out of comparison tokens!",
    TR: "Karşılaştırma jetonlarınız tükeniyor!",
    DE: "Ihnen gehen die Vergleichstokens aus!",
  },
  {
    id: "You can upgrade your package.",
    EN: "You can upgrade your package.",
    TR: "Paketinizi yükseltebilirsiniz.",
    DE: "Sie können Ihr Paket upgraden.",
  },
  {
    id: "Congratulation",
    EN: "Congratulation",
    TR: "Tebrikler",
    DE: "Glückwunsch",
  },
  {
    id: "Your action center is empty, so everything is fine!",
    EN: "Your action center is empty, so everything is fine!",
    TR: "Eylem merkeziniz boş, her şey yolunda!",
    DE: "Ihr Aktionszentrum ist leer, also ist alles in Ordnung!",
  },
  {
    id: "Welcome, if you do not know how to install the plugin or need support, you can review this content!",
    EN: "Welcome, if you do not know how to install the plugin or need support, you can review this content!",
    TR: "Hoş geldiniz, eklentiyi nasıl kuracağınızı bilmiyorsanız veya desteğe ihtiyacınız varsa, bu içeriği inceleyebilirsiniz!",
    DE: "Willkommen, wenn Sie nicht wissen, wie Sie das Plugin installieren oder Unterstützung benötigen, können Sie diesen Inhalt überprüfen!",
  },
  {
    id: "Your usage rights for your plan.",
    EN: "Your usage rights for your plan.",
    TR: "Planınıza ilişkin kullanım haklarınız.",
    DE: "Ihre Nutzungsrechte für Ihren Plan.",
  },
  {
    id: "You can see system notifications here.",
    EN: "You can see system notifications here.",
    TR: "Sistem bildirimlerini burada görebilirsiniz.",
    DE: "Systembenachrichtigungen finden Sie hier.",
  },
  {
    id: "Market settings",
    EN: "Market settings",
    TR: "Market ayarları",
    DE: "Markteinstellungen",
  },
  {
    id: "Buy comparison tokens.",
    EN: "Buy comparison tokens.",
    TR: "Jeton satın alın.",
    DE: "Kaufen Sie Vergleichstoken.",
  },
  {
    id: "You're running out of Product Based Info limit!",
    EN: "You're running out of Product Based Info limit!",
    TR: "Ürün Botu limitiniz bitmek üzere!",
    DE: "Ihr Produkt-Bot-Limit ist bald erschöpft!",
  },
  {
    id: "Your marketplace is not connected!",
    EN: "Your marketplace is not connected!",
    TR: "Pazar yeriniz bağlı değil!",
    DE: "Ihr Marktplatz ist nicht verbunden!",
  },
  {
    id: "Need tokens?",
    EN: "Need tokens?",
    TR: "Jeton mu lazım?",
    DE: "Benötigen Sie Token?",
  },
  {
    id: "Next plan canceled!",
    EN: "Next plan canceled!",
    TR: "Gelecek plan iptal edildi!",
    DE: "Zukunftsplan abgesagt!",
  },
  {
    id: "Next plan canceled!",
    EN: "Next plan canceled!",
    TR: "Gelecek plan iptal edildi!",
    DE: "Zukunftsplan abgesagt!",
  },
  {
    id: "Cancel Next Plan",
    EN: "Cancel Next Plan",
    TR: "Gelecek Planı İptal Et",
    DE: "Nächsten Plan kündigen",
  },
  {
    id: "Affiliate Code",
    EN: "Affiliate Code",
    TR: "Affiliate Kodu",
    DE: "Affiliate-Code",
  },
  {
    id: "Affiliate Type",
    EN: "Affiliate Type",
    TR: "Affiliate Türü",
    DE: "Affiliate-Typ",
  },
  {
    id: "Percentage",
    EN: "Percentage",
    TR: "Yüzde",
    DE: "Prozentsatz",
  },
  {
    id: "Token Amount",
    EN: "Token Amount",
    TR: "Jeton Miktarı",
    DE: "Token-Betrag",
  },
  {
    id: "Commission Rate",
    EN: "Commission Rate",
    TR: "Komisyon Oranı",
    DE: "Provisionsrate",
  },
  {
    id: "Add Token on Register",
    EN: "Add Token on Register",
    TR: "Kayıtta Token Ekle",
    DE: "Token bei Registrierung hinzufügen",
  },
  {
    id: "Limit",
    EN: "Limit",
    TR: "Limit",
    DE: "Limit",
  },
  {
    id: "Limit Used",
    EN: "Limit Used",
    TR: "Kullanılan Limit",
    DE: "Verwendetes Limit",
  },
  {
    id: "Affiliates Management",
    EN: "Affiliates Management",
    TR: "Affiliate Yönetimi",
    DE: "Affiliate-Management",
  },
  {
    id: "Select User",
    EN: "Select User",
    TR: "Kullanıcı Seç",
    DE: "Benutzer auswählen",
  },
  {
    id: "Create/Edit Affiliate",
    EN: "Create/Edit Affiliate",
    TR: "Affiliate Oluştur/Düzenle",
    DE: "Affiliate erstellen/bearbeiten",
  },
  {
    id: "Affiliate Name",
    EN: "Affiliate Name",
    TR: "Affiliate Adı",
    DE: "Affiliate-Name",
  },
  {
    id: "User",
    EN: "User",
    TR: "Kullanıcı",
    DE: "Benutzer",
  },
  {
    id: "Comparison Token Amount",
    EN: "Comparison Token Amount",
    TR: "Karşılaştırma Jeton Miktarı",
    DE: "Vergleichs-Token-Menge",
  },
  {
    id: "Comparison Token to Add on Register",
    EN: "Comparison Token to Add on Register",
    TR: "Kayıtta Eklenecek Karşılaştırma Jetonu",
    DE: "Vergleichs-Token zur Registrierung hinzufügen",
  },
  {
    id: "Asin Token to Add on Register",
    EN: "Asin Token to Add on Register",
    TR: "Kayıtta Eklenecek Asin Jeton",
    DE: "Asin-Token zur Registrierung hinzufügen",
  },
  {
    id: "Is Limited?",
    EN: "Is Limited?",
    TR: "Limitli mi?",
    DE: "Ist limitiert?",
  },
  {
    id: "Create New",
    EN: "Create New",
    TR: "Yeni Oluştur",
    DE: "Neu erstellen",
  },
  {
    id: "If you want your friends to register with a discount, you can invite them with the URL you created.",
    EN: "If you want your friends to register with a discount, you can invite them with the URL you created.",
    TR: "Arkadaşlarınızı indirimle kayıt olmaları için davet etmek istiyorsanız, oluşturduğunuz URL ile davet edebilirsiniz.",
    DE: "Wenn Sie möchten, dass sich Ihre Freunde mit einem Rabatt registrieren, können Sie sie mit der von Ihnen erstellten URL einladen.",
  },
  {
    id: "Remove Protection",
    EN: "Remove Protection",
    TR: "Korumayı Kaldır",
    DE: "Schutz entfernen",
  },
  {
    id: "Add Protection",
    EN: "Add Protection",
    TR: "Koruma Ekle",
    DE: "Schutz hinzufügen",
  },
  {
    id: "Request Count",
    EN: "Request Count",
    TR: "İstek Sayısı",
    DE: "Anzahl der Anfragen",
  },
  {
    id: "Is Protected?",
    EN: "Is Protected?",
    TR: "Korunuyor mu?",
    DE: "Ist geschützt?",
  },
  {
    id: "Seller ID",
    EN: "Seller ID",
    TR: "Satıcı ID",
    DE: "Verkäufer-ID",
  },
  {
    id: "Server",
    EN: "Server",
    TR: "Sunucu",
    DE: "Server",
  },
  {
    id: "Sellers",
    EN: "Sellers",
    TR: "Satıcılar",
    DE: "Verkäufer",
  },
  {
    id: "Successfully Saved as Draft!",
    EN: "Successfully Saved as Draft!",
    TR: "Taslak Olarak Başarıyla Kaydedildi!",
    DE: "Erfolgreich als Entwurf gespeichert!",
  },
  {
    id: "Emails were sent successfully!",
    EN: "Emails were sent successfully!",
    TR: "E-postalar başarıyla gönderildi!",
    DE: "E-Mails wurden erfolgreich gesendet!",
  },
  {
    id: "From here you can send custom emails to all registered users or selected users.",
    EN: "From here you can send custom emails to all registered users or selected users.",
    TR: "Buradan tüm kayıtlı kullanıcılara veya seçilen kullanıcılara özel e-postalar gönderebilirsiniz.",
    DE: "Von hier aus können Sie benutzerdefinierte E-Mails an alle registrierten Benutzer oder ausgewählte Benutzer senden.",
  },
  {
    id: "New Email",
    EN: "New Email",
    TR: "Yeni E-posta",
    DE: "Neue E-Mail",
  },
  {
    id: "Email Management",
    EN: "Email Management",
    TR: "E-posta Yönetimi",
    DE: "E-Mail-Verwaltung",
  },
  {
    id: "Save as Draft",
    EN: "Save as Draft",
    TR: "Taslak Olarak Kaydet",
    DE: "Als Entwurf speichern",
  },
  {
    id: "Users List",
    EN: "Users List",
    TR: "Kullanıcı Listesi",
    DE: "Benutzerliste",
  },
  {
    id: "All Free",
    EN: "All Free",
    TR: "Tüm Ücretsizler",
    DE: "Alle kostenlos",
  },
  {
    id: "All Paid (Basic, Plus, Pro)",
    EN: "All Paid (Basic, Plus, Pro)",
    TR: "Tüm Ücretliler (Basic, Plus, Pro)",
    DE: "Alle bezahlt (Basic, Plus, Pro)",
  },
  {
    id: "All Basic",
    EN: "All Basic",
    TR: "Tüm Basic'ler",
    DE: "Alle Basic",
  },
  {
    id: "All Plus",
    EN: "All Plus",
    TR: "Tüm Plus'lar",
    DE: "Alle Plus",
  },
  {
    id: "All Pro",
    EN: "All Pro",
    TR: "Tüm Pro'lar",
    DE: "Alle Pro",
  },
  {
    id: "Just FE Market Connected",
    EN: "Just FE Market Connected",
    TR: "Sadece FE Pazarı Bağlılar",
    DE: "Nur FE-Markt verbunden",
  },
  {
    id: "Just NA Market Connected",
    EN: "Just NA Market Connected",
    TR: "Sadece NA Pazarı Bağlılar",
    DE: "Nur NA-Markt verbunden",
  },
  {
    id: "Just EU Market Connected",
    EN: "Just EU Market Connected",
    TR: "Sadece EU Pazarı Bağlılar",
    DE: "Nur EU-Markt verbunden",
  },
  {
    id: "Just NOT Market Connected",
    EN: "Just NOT Market Connected",
    TR: "Sadece Pazar Bağlamayanlar",
    DE: "Nur NICHT Markt verbunden",
  },
  {
    id: "Just Market Connected",
    EN: "Just Market Connected",
    TR: "Sadece Pazar Bağlı Olanlar",
    DE: "Nur Markt verbunden",
  },
  {
    id: "All Users",
    EN: "All Users",
    TR: "Tüm Kullanıcılar",
    DE: "Alle Benutzer",
  },
  {
    id: "Custom",
    EN: "Custom",
    TR: "Özel",
    DE: "Benutzerdefiniert",
  },
  {
    id: "Filter Users",
    EN: "Filter Users",
    TR: "Kullanıcıları Filtrele",
    DE: "Benutzer filtern",
  },
  {
    id: "Create Email",
    EN: "Create Email",
    TR: "E-posta Oluştur",
    DE: "E-Mail erstellen",
  },
  {
    id: "Send Draft",
    EN: "Send Draft",
    TR: "Taslağı Gönder",
    DE: "Entwurf senden",
  },
  {
    id: "Sent",
    EN: "Sent",
    TR: "Gönderildi",
    DE: "Gesendet",
  },
  {
    id: "Draft",
    EN: "Draft",
    TR: "Taslak",
    DE: "Entwurf",
  },
  {
    id: "To",
    EN: "To",
    TR: "Kime",
    DE: "An",
  },
  {
    id: "From",
    EN: "From",
    TR: "Kimden",
    DE: "Von",
  },
  {
    id: "Next",
    EN: "Next",
    TR: "Sonraki",
    DE: "Nächster",
  },
  {
    id: "Have you tried following the suggestions in this list in order?",
    EN: "Have you tried following the suggestions in this list in order?",
    TR: "Şu listedeki önerileri sırasıyla yapmayı denedin mi?",
    DE: "Haben Sie versucht, die Vorschläge in dieser Liste der Reihe nach zu befolgen?",
  },
  {
    id: "Many users have problems installing the plugin because they did not read the getting started guide!",
    EN: "Many users have problems installing the plugin because they did not read the getting started guide!",
    TR: "Kullanıcıların çoğu başlangıç klavuzuna göz atmadığı için eklentiyi yüklerken sorun yaşıyor!",
    DE: "Viele Benutzer haben Probleme bei der Installation des Plugins, weil sie die Kurzanleitung nicht gelesen haben!",
  },
  {
    id: "Message has been resetted successfuly!",
    EN: "Message has been resetted successfuly!",
    TR: "Mesaj başarıyla sıfırlandı!",
    DE: "Die Nachricht wurde erfolgreich zurückgesetzt!",
  },
  {
    id: "Reset",
    EN: "Reset",
    TR: "Sıfırla",
    DE: "Zurücksetzen",
  },
  {
    id: "Type status message..",
    EN: "Type status message..",
    TR: "Durum mesajını yazın..",
    DE: "Geben Sie die Statusmeldung ein.",
  },
  {
    id: "Extension Status Message",
    EN: "Extension Status Message",
    TR: "Eklenti Durum Mesajı",
    DE: "Erweiterungsstatusmeldung",
  },
  {
    id: "Clients",
    EN: "Clients",
    TR: "Müşteriler",
    DE: "Kunden",
  },
  {
    id: "Balance Activity",
    EN: "Balance Activity",
    TR: "Bakiye Etkinliği",
    DE: "Wallet-Aktivität",
  },
  {
    id: "Report Mode",
    EN: "Report Mode",
    TR: "Rapor Modu",
    DE: "Berichtsmodus",
  },
  {
    id: "Chart Mode",
    EN: "Chart Mode",
    TR: "Grafik Modu",
    DE: "Kartenmodus",
  },
  {
    id: "Users Activity",
    EN: "Users Activity",
    TR: "Kullanıcı Etkinliği",
    DE: "Benutzeraktivität",
  },
  {
    id: "Asin list created",
    EN: "Asin list created",
    TR: "Asin listesi oluşturuldu",
    DE: "Asin-Liste erstellt",
  },
  {
    id: "Payment accepted",
    EN: "Payment accepted",
    TR: "Ödeme kabul edildi",
    DE: "Zahlung akzeptiert",
  },
  {
    id: "Stock checked",
    EN: "Stock checked",
    TR: "Stok kontrol edildi",
    DE: "Bestand überprüft",
  },
  {
    id: "Ticket created",
    EN: "Ticket created",
    TR: "Ticket oluşturuldu",
    DE: "Ticket erstellt",
  },
  {
    id: "Users registered",
    EN: "Users registered",
    TR: "Kullanıcılar kayıt oldu",
    DE: "Benutzer registriert",
  },
  {
    id: "Latest Releases",
    EN: "Latest Releases",
    TR: "Blog & Haberler",
    DE: "Letzte Veröffentlichung",
  },
  {
    id: "Latest Releases",
    EN: "Latest Releases",
    TR: "Blog & Haberler",
    DE: "Letzte Veröffentlichung",
  },
  {
    id: "Useful Articles",
    EN: "Useful Articles",
    TR: "Faydalı Makaleler",
    DE: "Nützliche Artikel",
  },
  {
    id: "In the currency of the target market!",
    EN: "In the currency of the target market!",
    TR: "Hedef pazarın para birimi cinsinden!",
    DE: "In der Währung des Zielmarktes!",
  },
  {
    id: "Daily",
    EN: "Daily",
    TR: "Günlük",
    DE: "Täglich",
  },
  {
    id: "1 Product Comparison = 1 Comparison Token",
    EN: "1 Product Comparison = 1 Comparison Token",
    TR: "1 Ürün Karşılaştırma = 1 Karşılaştırma Jetonu",
    DE: "1 Produktvergleich = 1 Vergleichs-Token",
  },
  {
    id: "Opening 1 Product Page = 1 PBI Usage",
    EN: "Opening 1 Product Page = 1 PBI Usage",
    TR: "1 Ürün Sayfası Açma = 1 Ü.B. Hakkı",
    DE: "Öffnen einer Produktseite = 1 PB-Nutzung",
  },
  {
    id: "Refreshing the page and recalculating also reduce your PBI usage limit!",
    EN: "Refreshing the page and recalculating also reduce your PBI usage limit!",
    TR: "Sayfayı yenileme, yeniden hesaplama işlemleri de Ü.B. limitini düşürür!",
    DE: "Durch das Aktualisieren der Seite und die Neuberechnung wird auch Ihr PB-Nutzungslimit reduziert!",
  },
  {
    id: "You can connect the marketplace or marketplaces you want to use here!",
    EN: "You can connect the marketplace or marketplaces you want to use here!",
    TR: "Kullanmak istediğiniz pazar yeri ya da pazaryerlerinizi  buradan bağlayabilirsiniz.",
    DE: "Hier können Sie den oder die Marktplätze anbinden, die Sie nutzen möchten.",
  },
  {
    id: "Ticket closed successfully!",
    EN: "Ticket closed successfully!",
    TR: "Ticket başarıyla kapatıldı!",
    DE: "Ticket erfolgreich geschlossen!",
  },
  {
    id: "Failed to close the ticket!",
    EN: "Failed to close the ticket!",
    TR: "Ticket kapatılamadı!",
    DE: "Ticket konnte nicht geschlossen werden!",
  },
  {
    id: "No ticket selected!",
    EN: "No ticket selected!",
    TR: "Herhangi bir ticket seçilmedi!",
    DE: "Kein Ticket ausgewählt!",
  },
  {
    id: "Should the ticket status be set to Closed?",
    EN: "Should the ticket status be set to Closed?",
    TR: "Ticket durumu Kapatıldı olarak ayarlansın mı?",
    DE: "Soll der Ticketstatus auf Geschlossen gesetzt werden?",
  },
  {
    id: "Close Ticket",
    EN: "Close Ticket",
    TR: "Ticket'ı Kapat",
    DE: "Ticket schließen",
  },
  {
    id: "The saved setting cannot be edited! Add new settings if necessary.",
    EN: "The saved setting cannot be edited! Add new settings if necessary.",
    TR: "Kaydedilen ayar düzenlenemez! Gerekirse yeni ayar ekleyin.",
    DE: "Die gespeicherte Einstellung kann nicht bearbeitet werden! Fügen Sie bei Bedarf neue Einstellungen hinzu.",
  },
  {
    id: "Webinar",
    EN: "Webinar",
    TR: "Webinar",
    DE: "Webinar",
  },
  {
    id: "Event",
    EN: "Event",
    TR: "Etkinlik",
    DE: "Ereignis",
  },
  {
    id: "View Details",
    EN: "View Details",
    TR: "Detayları Görüntüle",
    DE: "Details anzeigen",
  },
  {
    id: "Hide Details",
    EN: "Hide Details",
    TR: "Detayları Gizle",
    DE: "Details ausblenden",
  },
  {
    id: "Protected Count",
    EN: "Protected Count",
    TR: "Korunma Sayısı",
    DE: "Geschützter Graf",
  },
  {
    id: "Views Count",
    EN: "Views Count",
    TR: "Görüntüleme Sayısı",
    DE: "Anzahl der Aufrufe",
  },
  {
    id: "Protection Status",
    EN: "Protection Status",
    TR: "Koruma Durumu",
    DE: "Schutz status",
  },
  {
    id: "Protection Not Active",
    EN: "Protection Not Active",
    TR: "Koruma Aktif Değil",
    DE: "Schutz nicht aktiv",
  },
  {
    id: "Upgrade to Pro Package",
    EN: "Upgrade to Pro Package",
    TR: "Pro Pakete Yükselt",
    DE: "Upgrade auf Pro-Paket",
  },
  {
    id: "Protection Active",
    EN: "Protection Active",
    TR: "Koruma Aktif",
    DE: "Schutz aktiv",
  },
  {
    id: "3rd-party Integrations",
    EN: "3rd-party Integrations",
    TR: "3. Parti Entegrasyonlar",
    DE: "Drittanbieter-Integrationen",
  },
  {
    id: "Available Columns",
    EN: "Available Columns",
    TR: "Mevcut Sütunlar",
    DE: "Verfügbare Spalten",
  },
  {
    id: "Selected Columns",
    EN: "Selected Columns",
    TR: "Seçilen Sütunlar",
    DE: "Ausgewählte Spalten",
  },
  {
    id: "Add New Empty Column",
    EN: "Add New Empty Column",
    TR: "Yeni Boş Sütun Ekle",
    DE: "Neue Leere Spalte Hinzufügen",
  },
  {
    id: "Available",
    EN: "Available",
    TR: "Mevcut",
    DE: "Verfügbar",
  },
  {
    id: "Selected",
    EN: "Selected",
    TR: "Seçilmiş",
    DE: "Ausgewählt",
  },
  {
    id: "You can manage your 3rd-party Integration settings here.",
    EN: "You can manage your 3rd-party Integration settings here.",
    TR: "3. Parti Entegrasyon ayarlarınızı buradan yönetebilirsiniz.",
    DE: "Sie können hier Ihre Einstellungen für Drittanbieter-Integrationen verwalten.",
  },
  {
    id: "Are you sure want to disconnect?",
    EN: "Are you sure want to disconnect?",
    TR: "Bağlantıyı kesmek istediğinizden emin misiniz?",
    DE: "Sind Sie sicher, dass Sie die Verbindung trennen möchten?",
  },
  {
    id: "Removing successful!",
    EN: "Removing successful!",
    TR: "Kaldırma başarılı!",
    DE: "Entfernen erfolgreich!",
  },
  {
    id: "Column name..",
    EN: "Column name..",
    TR: "Sütun adı..",
    DE: "Spaltenname..",
  },
  {
    id: "Save Settings",
    EN: "Save Settings",
    TR: "Ayarları Kaydet",
    DE: "Einstellungen speichern",
  },
  {
    id: "Setting name..",
    EN: "Setting name..",
    TR: "Ayar adı..",
    DE: "Einstellungsname..",
  },
  {
    id: "Add New Setting",
    EN: "Add New Setting",
    TR: "Yeni Ayar Ekle",
    DE: "Neue Einstellung hinzufügen",
  },
  {
    id: "Select the columns you want to see in the table from the 'Available Columns' list, add extra columns if you want, and send the columns you selected to the 'Selected Columns' area by clicking the 'Export to Right' button, which turns blue.",
    EN: "Select the columns you want to see in the table from the 'Available Columns' list, add extra columns if you want, and send the columns you selected to the 'Selected Columns' area by clicking the 'Export to Right' button, which turns blue.",
    TR: "Tabloda görmek istediğin sütunları 'Mevcut sütunlar' listesinden seç, istersen ekstra sütun ekle ve seçtiğin sütunları maviye dönen 'Sağa Aktarma' butonuna tıklayarak 'Seçilen Sütunlar' alanına gönder.",
    DE: "Wählen Sie aus der Liste „Verfügbare Spalten“ die Spalten aus, die Sie in der Tabelle sehen möchten, fügen Sie bei Bedarf zusätzliche Spalten hinzu und senden Sie die ausgewählten Spalten an den Bereich „Ausgewählte Spalten“, indem Sie auf die Schaltfläche „Nach rechts exportieren“ klicken Blau.",
  },
  {
    id: "In the 'Selected Columns' field, you can change the order in which you want the columns to appear in the table by dragging and dropping the 'Burger' icon that appears to the right of the column objects. Or you can re-select the column you want to remove and send it to the 'Available Columns' field by clicking on the 'Export Left' button, which turns blue.",
    EN: "In the 'Selected Columns' field, you can change the order in which you want the columns to appear in the table by dragging and dropping the 'Burger' icon that appears to the right of the column objects. Or you can re-select the column you want to remove and send it to the 'Available Columns' field by clicking on the 'Export Left' button, which turns blue.",
    TR: "'Seçilen Sütunlar' alanında tabloda sütunların hangi sırayla gelmesini istiyorsan sütun nesnelerinin sağında beliren '3 çizgi' ikonunu sürükle - bırak ile sıralamasını değiştirebilirsiniz. Ya da çıkarmak istediğiniz sütunu tekrar seçerek maviye dönen 'Sola Aktarma' butonuna tıklayarak 'Mevcut Sütunlar' alanına tekrar gönderebilirsiniz.",
    DE: "Im Feld „Ausgewählte Spalten“ können Sie die Reihenfolge ändern, in der die Spalten in der Tabelle angezeigt werden sollen, indem Sie das „Burger“-Symbol, das rechts neben den Spaltenobjekten erscheint, per Drag & Drop ziehen. Oder Sie können die Spalte, die Sie entfernen möchten, erneut auswählen und an das Feld „Verfügbare Spalten“ senden, indem Sie auf die Schaltfläche „Links exportieren“ klicken, die blau wird.",
  },
  {
    id: "Finally, you need to record both the columns you selected and your ranking. Give a name to your setting in the 'Setting name' field and make it active by clicking on it if you want to use it. Now, by clicking the 'Google Sheet' button from the plugin, the products you send can be transferred to your Google Sheet account with the columns and order you want.",
    EN: "Finally, you need to record both the columns you selected and your ranking. Give a name to your setting in the 'Setting name' field and make it active by clicking on it if you want to use it. Now, by clicking the 'Google Sheet' button from the plugin, the products you send can be transferred to your Google Sheet account with the columns and order you want.",
    TR: "Son olarak hem seçtiğiniz sütunları hem de sıralamanızı kayıt altına almanız gerekiyor. 'Ayar adı' alanına ayarınıza bir isim verin ve kullanmak isterseniz üzerine tıklayarak aktif olmasını sağlayın. Artık eklentiden 'Google Sheet' butonuna tıklayarak gönderdiğiniz ürünler istediğiniz sütunlar ve sıralama ile Google Sheet hesabınıza aktarılabilir.",
    DE: "Abschließend müssen Sie sowohl die von Ihnen ausgewählten Spalten als auch Ihr Ranking aufzeichnen. Geben Sie Ihrer Einstellung im Feld „Einstellungsname“ einen Namen und aktivieren Sie sie, indem Sie darauf klicken, wenn Sie sie verwenden möchten. Durch Klicken auf die Schaltfläche „Google Sheet“ im Plugin können nun die von Ihnen gesendeten Produkte mit den gewünschten Spalten und der gewünschten Reihenfolge auf Ihr Google Sheet-Konto übertragen werden.",
  },
  {
    id: "Product Based Info Sheets",
    EN: "Product Based Info Sheets",
    TR: "Ürün Botu Tabloları",
    DE: "Produktbasierte Informationsblätter",
  },
  {
    id: "Comparison Sheets",
    EN: "Comparison Sheets",
    TR: "Karşılaştırma Tabloları",
    DE: "Vergleichsblätter",
  },

  {
    id: "Card Info",
    EN: "Card Info",
    TR: "Kart Bilgileri",
    DE: "Karteninformationen",
  },
  {
    id: "Add New Card",
    EN: "Add New Card",
    TR: "Yeni Kart Ekle",
    DE: "Neue Karte hinzufügen",
  },
  {
    id: "Select Country",
    EN: "Select Country",
    TR: "Ülke Seçin",
    DE: "Land auswählen",
  },
  {
    id: "Default Card",
    EN: "Default Card",
    TR: "Varsayılan Kart",
    DE: "Standardkarte",
  },
  {
    id: "Set as Default",
    EN: "Set as Default",
    TR: "Varsayılan Olarak Ayarla",
    DE: "Als Standard festlegen",
  },
  {
    id: `Delete Card`,
    EN: `Delete Card`,
    TR: `Kartı Sil`,
    DE: `Karte Löschen`,
  },
  {
    id: "Add New Custom Column",
    EN: "Add New Custom Column",
    TR: "Yeni Özel Sütun Ekle",
    DE: "Neue benutzerdefinierte Spalte hinzufügen",
  },
  {
    id: "Google Sheet Connection Successful!",
    EN: "Google Sheet Connection Successful!",
    TR: "Google Sheet Bağlantısı Başarılı!",
    DE: "Google-Verbindung erfolgreich!",
  },
  {
    id: "Google Sheet Not Connected!",
    EN: "Google Sheet Not Connected!",
    TR: "Google Bağlı Değil!",
    DE: "Google Sheet nicht verbunden!",
  },
  {
    id: "Card deletion successful!",
    EN: "Card deletion successful!",
    TR: "Kart silme işlemi başarılı!",
    DE: "Kartenlöschung erfolgreich!",
  },
  {
    id: "Your action has been completed successfully!",
    EN: "Your action has been completed successfully!",
    TR: "İşleminiz başarıyla tamamlandı!",
    DE: "Ihre Aktion wurde erfolgreich abgeschlossen!",
  },
  {
    id: "Cancel Subscription",
    EN: "Cancel Subscription",
    TR: "Aboneliği İptal Et",
    DE: "Abonnement kündigen",
  },
  {
    id: "Saved Cards",
    EN: "Saved Cards",
    TR: "Kayıtlı Kartlar",
    DE: "Gespeicherte Karten",
  },
  {
    id: "Add New Card",
    EN: "Add New Card",
    TR: "Yeni Kart Ekle",
    DE: "Neue Karte hinzufügen",
  },
  {
    id: "Paid Users",
    EN: "Paid Users",
    TR: "Ücretli Kullanıcılar",
    DE: "Bezahlte Benutzer",
  },
  {
    id: "Total Paid",
    EN: "Total Paid",
    TR: "Toplam Ödenen",
    DE: "Insgesamt Bezahlt",
  },
  {
    id: "Stripe Customers",
    EN: "Stripe Customers",
    TR: "Stripe Müşterileri",
    DE: "Stripe Kunden",
  },
  {
    id: "Canceled Users",
    EN: "Canceled Users",
    TR: "İptal Edenler",
    DE: "Abgebrochene Benutzer",
  },
  {
    id: "Connected Users",
    EN: "Connected Users",
    TR: "Marketi Bağlı Olanlar",
    DE: "Verbundene Benutzer",
  },
  {
    id: "NA Store",
    EN: "NA Store",
    TR: "NA Mağaza",
    DE: "NA Laden",
  },
  {
    id: "EU Store",
    EN: "EU Store",
    TR: "EU Mağaza",
    DE: "EU Laden",
  },
  {
    id: "FE Store",
    EN: "FE Store",
    TR: "FE Mağaza",
    DE: "FE Laden",
  },
  {
    id: "Google Connected Users",
    EN: "Google Connected Users",
    TR: "Google Bağlı Kullanıcılar",
    DE: "Mit Google Verbundene Benutzer",
  },
  {
    id: "Not Connected Store",
    EN: "Not Connected Store",
    TR: "Market Bağlamayanlar",
    DE: "Hinweis Connected Store",
  },
  {
    id: "My Sheet",
    EN: "My Sheet",
    TR: "Tablolarım",
    DE: "Mein Blatt",
  },
  {
    id: "Delete Sheet",
    EN: "Delete Sheet",
    TR: "Tablomu Sil",
    DE: "Blatt Löschen",
  },
  {
    id: "Hide Professional Settings",
    EN: "Hide Professional Settings",
    TR: "Profesyonel Ayarları Gizle",
    DE: "Professionelle Einstellungen Verbergen",
  },
  {
    id: "Show Professional Settings",
    EN: "Show Professional Settings",
    TR: "Profesyonel Ayarları Göster",
    DE: "Professionelle Einstellungen Anzeigen",
  },
  {
    id: "Custom Shipping Settings",
    EN: "Custom Shipping Settings",
    TR: "Özel Kargo Ayarları",
    DE: "Custom Shipping Settings",
  },
  {
    id: "Basic Shipping Options",
    EN: "Basic Shipping Options",
    TR: "Temel Kargo Ayarları",
    DE: "Basic Shipping Options",
  },
  {
    id: "Edit Custom Settings",
    EN: "Edit Custom Settings",
    TR: "Özel Ayarlarını Düzenle",
    DE: "Edit Custom Settings",
  },
  {
    id: "Custom Shipping Pricing Values",
    EN: "Custom Shipping Pricing Values",
    TR: "Özel Kargo Fiyatlandırma Değerleri",
    DE: "Custom Shipping Pricing Values",
  },
  {
    id: "Create your detailed shipping values. Also make sure that you enter the values ​​correctly and completely.",
    EN: "Create your detailed shipping values. Also make sure that you enter the values ​​correctly and completely.",
    TR: "Detaylı kargo değerlerinizi oluşturun. Değerleri doğru ve tam olarak girdiğinizden emin olun.",
    DE: "Create your detailed shipping values. Also make sure that you enter the values ​​correctly and completely.",
  },
  {
    id: "Make sure you enter all values!",
    EN: "Make sure you enter all values!",
    TR: "Tüm değerleri girdiğinizden emin olun!",
    DE: "Make sure you enter all values!",
  },

  {
    id: "Asin lists merged successfully!",
    EN: "Asin lists merged successfully!",
    TR: "Asin listeleri başarıyla birleştirildi!",
    DE: "Asin-Listen erfolgreich zusammengeführt!",
  },
  {
    id: "Failed to merge asin lists!",
    EN: "Failed to merge asin lists!",
    TR: "Asin listeleri birleştirilemedi!",
    DE: "Zusammenführen der Asin-Listen fehlgeschlagen!",
  },
  {
    id: "Asin lists deleted successfully!",
    EN: "Asin lists deleted successfully!",
    TR: "Asin listeleri başarıyla silindi!",
    DE: "Asin-Listen erfolgreich gelöscht!",
  },
  {
    id: "Failed to delete asin lists!",
    EN: "Failed to delete asin lists!",
    TR: "Asin listeleri silinemedi!",
    DE: "Löschen der Asin-Listen fehlgeschlagen!",
  },
  {
    id: "Asin lists downloaded successfully!",
    EN: "Asin lists downloaded successfully!",
    TR: "Asin listeleri başarıyla indirildi!",
    DE: "Asin-Listen erfolgreich heruntergeladen!",
  },
  {
    id: "Failed to download asin lists!",
    EN: "Failed to download asin lists!",
    TR: "Asin listeleri indirilemedi!",
    DE: "Herunterladen der Asin-Listen fehlgeschlagen!",
  },
  {
    id: "Comparison process started successfully!",
    EN: "Comparison process started successfully!",
    TR: "Karşılaştırma işlemi başlatıldı!",
    DE: "Vergleichsprozess erfolgreich gestartet!",
  },
  {
    id: "Failed to start comparison process!",
    EN: "Failed to start comparison process!",
    TR: "Karşılaştırma işlemi başlatılamadı!",
    DE: "Start des Vergleichsprozesses fehlgeschlagen!",
  },
  {
    id: "Comparison process stopped successfully!",
    EN: "Comparison process stopped successfully!",
    TR: "Karşılaştırma işlemi durduruldu!",
    DE: "Vergleichsprozess erfolgreich gestoppt!",
  },
  {
    id: "Comparison process restarted successfully!",
    EN: "Comparison process restarted successfully!",
    TR: "Karşılaştırma işlemi yeniden başlatıldı!",
    DE: "Vergleichsprozess erfolgreich neu gestartet!",
  },
  {
    id: "Manage, compare and view detailed analysis of your Asin lists.",
    EN: "Manage, compare and view detailed analysis of your Asin lists.",
    TR: "Asin listelerinizi yönetin, karşılaştırın ve detaylı analizlerini görüntüleyin.",
    DE: "Verwalten, vergleichen und detaillierte Analyse Ihrer Asin-Listen anzeigen.",
  },
  {
    id: "Total Saved Asin List",
    EN: "Total Saved Asin List",
    TR: "Toplam Asin Listesi",
    DE: "Gesamte gespeicherte Asin-Liste",
  },
  {
    id: "Total All Asins",
    EN: "Total All Asins",
    TR: "Tüm Asinler",
    DE: "Gesamt Alle Asins",
  },
  {
    id: "Asin Tokens",
    EN: "Asin Tokens",
    TR: "Asin Jetonları",
    DE: "Asin-Token",
  },
  {
    id: "Comparison Tokens",
    EN: "Comparison Tokens",
    TR: "Karşılaştırma Jetonları",
    DE: "Vergleichs-Token",
  },
  {
    id: "All Asins",
    EN: "All Asins",
    TR: "Tüm Asinler",
    DE: "Alle Asins",
  },
  {
    id: "Imported Asins",
    EN: "Imported Asins",
    TR: "Yüklenmiş Asinler",
    DE: "Importierte Asins",
  },
  {
    id: "Merged Asin Lists",
    EN: "Merged Asin Lists",
    TR: "Birleştirilmiş Asin Listeleri",
    DE: "Zusammengeführte Asin-Listen",
  },
  {
    id: "Import",
    EN: "Import",
    TR: "Dosya Yükle",
    DE: "Importieren",
  },
  {
    id: "Download",
    EN: "Download",
    TR: "İndir",
    DE: "Herunterladen",
  },
  {
    id: "Refresh",
    EN: "Refresh",
    TR: "Yenile",
    DE: "Aktualisieren",
  },
  {
    id: "Asin list download started!",
    EN: "Asin list download started!",
    TR: "Asin listesi indirme işlemi başladı!",
    DE: "Download der Asin-Liste gestartet!",
  },
  {
    id: "Asin list copied!",
    EN: "Asin list copied!",
    TR: "Asin listesi kopyalandı!",
    DE: "Asin-Liste kopiert!",
  },
  {
    id: "Delete",
    EN: "Delete",
    TR: "Sil",
    DE: "Löschen",
  },
  {
    id: "Merge Asin Lists",
    EN: "Merge Asin Lists",
    TR: "Asin Listelerini Birleştir",
    DE: "Asin-Listen zusammenführen",
  },
  {
    id: "Name",
    EN: "Name",
    TR: "İsim",
    DE: "Name",
  },

  {
    id: "Go to Page",
    EN: "Go to Page",
    TR: "Sayfaya Git",
    DE: "Gehe zur Seite",
  },
  {
    id: "Asin count",
    EN: "Asin count",
    TR: "Asin sayısı",
    DE: "Asin-Anzahl",
  },
  {
    id: "Preference Name",
    EN: "Preference Name",
    TR: "Ayar Adı",
    DE: "Präferenzname",
  },
  {
    id: "Status",
    EN: "Status",
    TR: "Durum",
    DE: "Status",
  },
  {
    id: "Origin",
    EN: "Origin",
    TR: "Kaynak",
    DE: "Herkunft",
  },
  {
    id: "Date",
    EN: "Date",
    TR: "Tarih",
    DE: "Datum",
  },
  {
    id: "Action",
    EN: "Action",
    TR: "Eylemler",
    DE: "Aktion",
  },
  {
    id: "Details",
    EN: "Details",
    TR: "Detaylar",
    DE: "Details",
  },
  {
    id: "Download Asin List",
    EN: "Download Asin List",
    TR: "Asin Listesini İndir",
    DE: "Asin-Liste herunterladen",
  },
  {
    id: "Start Comparison",
    EN: "Start Comparison",
    TR: "Karşılaştırmayı Başlat",
    DE: "Vergleich starten",
  },
  {
    id: "Stop Comparison",
    EN: "Stop Comparison",
    TR: "Karşılaştırmayı Durdur",
    DE: "Vergleich stoppen",
  },
  {
    id: "View Details",
    EN: "View Details",
    TR: "Detayları Göster",
    DE: "Details anzeigen",
  },
  {
    id: "Copy Asin List",
    EN: "Copy Asin List",
    TR: "Asin Listesini Kopyala",
    DE: "Asin-Liste kopieren",
  },
  {
    id: "Are you sure you want to recalculate the comparison list?",
    EN: "Are you sure you want to recalculate the comparison list?",
    TR: "Karşılaştırma listesini yeniden hesaplamak istediğinize emin misiniz?",
    DE: "Sind Sie sicher, dass Sie die Vergleichsliste neu berechnen möchten?",
  },
  {
    id: "Recalculation started!",
    EN: "Recalculation started!",
    TR: "Yeniden hesaplama başladı!",
    DE: "Neuberechnung gestartet!",
  },
  {
    id: "All Filters cleared!",
    EN: "All Filters cleared!",
    TR: "Tüm Filtreler Temizlendi!",
    DE: "Alle Filter gelöscht!",
  },
  {
    id: "Compare Asins",
    EN: "Compare Asins",
    TR: "Asin'leri Karşılaştır",
    DE: "Asins vergleichen",
  },
  {
    id: "Start",
    EN: "Start",
    TR: "Başlat",
    DE: "Start",
  },
  {
    id: "Stop",
    EN: "Stop",
    TR: "Durdur",
    DE: "Stopp",
  },
  {
    id: "Total Asin Count",
    EN: "Total Asin Count",
    TR: "Toplam Asin Sayısı",
    DE: "Gesamtanzahl der Asins",
  },
  {
    id: "Calculated Asin Count",
    EN: "Calculated Asin Count",
    TR: "Hesaplanan Asin Sayısı",
    DE: "Berechnete Asin-Anzahl",
  },
  {
    id: "Stock Error Count",
    EN: "Stock Error Count",
    TR: "Stok Hata Sayısı",
    DE: "Lagerfehleranzahl",
  },
  {
    id: "Filtered Asin Count",
    EN: "Filtered Asin Count",
    TR: "Filtrelenmiş Asin Sayısı",
    DE: "Gefilterte Asin-Anzahl",
  },
  {
    id: "Stock errors",
    EN: "Stock errors",
    TR: "Stok hataları",
    DE: "Lagerfehler",
  },
  {
    id: "Reset Filters",
    EN: "Reset Filters",
    TR: "Filtreleri Sıfırla",
    DE: "Filter zurücksetzen",
  },
  {
    id: "Re-calculate",
    EN: "Re-calculate",
    TR: "Yeniden hesapla",
    DE: "Neu berechnen",
  },
  {
    id: "Image",
    EN: "Image",
    TR: "Resim",
    DE: "Bild",
  },
  {
    id: "Title",
    EN: "Title",
    TR: "Başlık",
    DE: "Titel",
  },
  {
    id: "Fees",
    EN: "Fees",
    TR: "Ücretler",
    DE: "Gebühren",
  },
  {
    id: "Profit Per Item",
    EN: "Profit Per Item",
    TR: "Ürün Başına Kar",
    DE: "Gewinn pro Artikel",
  },
  {
    id: "Sales Rank",
    EN: "Sales Rank",
    TR: "Satış Sıralaması",
    DE: "Verkaufsrang",
  },

  {
    id: "Sales Rank Category",
    EN: "Sales Rank Category",
    TR: "Satış Sıralaması Kategorisi",
    DE: "Verkaufsrangkategorie",
  },
  {
    id: "Has Discount?",
    EN: "Has Discount?",
    TR: "İndirimli mi?",
    DE: "Gibt es einen Rabatt?",
  },
  {
    id: "Hazmat?",
    EN: "Hazmat?",
    TR: "Tehlikeli Madde mi?",
    DE: "Gefahrgut?",
  },
  {
    id: "Total Offer Count",
    EN: "Total Offer Count",
    TR: "Toplam Teklif Sayısı",
    DE: "Gesamtangebotsanzahl",
  },
  {
    id: "FBA Seller Count",
    EN: "FBA Seller Count",
    TR: "FBA Satıcı Sayısı",
    DE: "Anzahl der FBA-Verkäufer",
  },
  {
    id: "FBM Seller Count",
    EN: "FBM Seller Count",
    TR: "FBM Satıcı Sayısı",
    DE: "Anzahl der FBM-Verkäufer",
  },
  {
    id: "Amazon Seller?",
    EN: "Amazon Seller?",
    TR: "Satıcı Amazon mu?",
    DE: "Amazon-Verkäufer?",
  },
  {
    id: "Brand",
    EN: "Brand",
    TR: "Marka",
    DE: "Marke",
  },
  {
    id: "Binding",
    EN: "Binding",
    TR: "Bağlama",
    DE: "Bindung",
  },
  {
    id: "Product Type",
    EN: "Product Type",
    TR: "Ürün Tipi",
    DE: "Produkttyp",
  },
  {
    id: "Item Weight",
    EN: "Item Weight",
    TR: "Ürün Ağırlığı",
    DE: "Artikelgewicht",
  },
  {
    id: "Item Measures",
    EN: "Item Measures",
    TR: "Ürün Ölçüleri",
    DE: "Artikelmaße",
  },
  {
    id: "Package Measures",
    EN: "Package Measures",
    TR: "Paket Ölçüleri",
    DE: "Paketmaße",
  },
  {
    id: "Show Product Based Info",
    EN: "Show Product Based Info",
    TR: "Ürün Botunu Göster",
    DE: "Produkt-Bot anzeigen",
  },
  {
    id: "Filter by",
    EN: "Filter by",
    TR: "Filtrele",
    DE: "Filtern nach",
  },
  {
    id: "Reset",
    EN: "Reset",
    TR: "Sıfırla",
    DE: "Zurücksetzen",
  },
  {
    id: "Apply",
    EN: "Apply",
    TR: "Uygula",
    DE: "Anwenden",
  },
  {
    id: "Minimum value",
    EN: "Minimum value",
    TR: "Minimum Değer",
    DE: "Mindestwert",
  },
  {
    id: "Maximum value",
    EN: "Maximum value",
    TR: "Maksimum Değer",
    DE: "Höchstwert",
  },
  {
    id: "Open PBI",
    EN: "Open PBI",
    TR: "ÜB'nu Aç",
    DE: "PB anzeigen",
  },
  {
    id: "Market Comparison",
    EN: "Market Comparison",
    TR: "Pazar Karşılaştırma",
    DE: "Marktvergleich",
  },
  {
    id: "Stock Fetcher",
    EN: "Stock Fetcher",
    TR: "Stok Çekici",
    DE: "Lagerabrufer",
  },
  {
    id: "Additional Infos",
    EN: "Additional Infos",
    TR: "Ek Bilgiler",
    DE: "Zusätzliche Infos",
  },
  {
    id: "Keepa Data",
    EN: "Keepa Data",
    TR: "Keepa Verileri",
    DE: "Keepa-Daten",
  },
  {
    id: "Historical Keepa Data",
    EN: "Historical Keepa Data",
    TR: "Geçmiş Keepa Verileri",
    DE: "Keepa-Daten",
  },
  {
    id: "Variation Product - Variation Count",
    EN: "Variation Product - Variation Count",
    TR: "Varyasyonlu Ürün - Varyasyon Sayısı",
    DE: "Variationsprodukt - Variationsanzahl",
  },
  {
    id: "Heat Sensitive - Meltable",
    EN: "Heat Sensitive - Meltable",
    TR: "Isıya Duyarlı - Eriyebilir",
    DE: "Hitzeempfindlich - Schmelzbar",
  },
  {
    id: "Hazmat",
    EN: "Hazmat",
    TR: "Tehlikeli Madde",
    DE: "Gefahrgut",
  },
  {
    id: "Search on Google",
    EN: "Search on Google",
    TR: "Google'da Ara",
    DE: "Auf Google suchen",
  },
  {
    id: "Check on Amazon Seller Central",
    EN: "Check on Amazon Seller Central",
    TR: "Amazon Seller Central'da Kontrol Et",
    DE: "Auf Amazon Seller Central überprüfen",
  },
  {
    id: "More",
    EN: "More",
    TR: "Daha Fazlası",
    DE: "Mehr",
  },
  {
    id: "Keyword Tool",
    EN: "Keyword Tool",
    TR: "Anahtar Kelime Aracı",
    DE: "Keyword-Tool",
  },
  {
    id: "Keyword",
    EN: "Keyword",
    TR: "Anahtar Kelime",
    DE: "Schlüsselwort",
  },
  {
    id: "Select marketplace",
    EN: "Select marketplace",
    TR: "Pazar Yeri Seç",
    DE: "Marktplatz auswählen",
  },
  {
    id: "Search for products in Amazon stores by entering keywords; filter, save, compare..",
    EN: "Search for products in Amazon stores by entering keywords; filter, save, compare..",
    TR: "Anahtar kelimeler girerek Amazon mağazalarında ürün arayın; filtreleyin, kaydedin, karşılaştırın..",
    DE: "Suchen Sie in Amazon-Läden nach Produkten, indem Sie Schlüsselwörter eingeben; filtern, speichern, vergleichen..",
  },
  {
    id: "Merge & Save to Asin List",
    EN: "Merge & Save to Asin List",
    TR: "Birleştir ve Asin Listesine Kaydet",
    DE: "Zusammenführen & in Asin-Liste speichern",
  },
  {
    id: "Selected items -",
    EN: "Selected items -",
    TR: "Seçilen öğeler -",
    DE: "Ausgewählte Artikel -",
  },
  {
    id: "Search process started!",
    EN: "Search process started!",
    TR: "Arama işlemi başladı!",
    DE: "Suchvorgang gestartet!",
  },
  {
    id: "Start search?",
    EN: "Start search?",
    TR: "Arama başlatılsın mı?",
    DE: "Suche starten?",
  },
  {
    id: "Please enter a keyword and select a marketplace!",
    EN: "Please enter a keyword and select a marketplace!",
    TR: "Lütfen bir anahtar kelime girin ve bir pazar yeri seçin!",
    DE: "Bitte geben Sie ein Schlüsselwort ein und wählen Sie einen Marktplatz aus!",
  },
  {
    id: "Total Number of Products",
    EN: "Total Number of Products",
    TR: "Toplam Ürün Sayısı",
    DE: "Gesamtzahl der Produkte",
  },
  {
    id: "Stop search?",
    EN: "Stop search?",
    TR: "Arama durdurulsun mu?",
    DE: "Suche anhalten?",
  },
  {
    id: "Searching has been stopped!",
    EN: "Searching has been stopped!",
    TR: "Arama durduruldu!",
    DE: "Suche wurde gestoppt!",
  },
  {
    id: "Cancel search?",
    EN: "Cancel search?",
    TR: "Arama iptal edilsin mi?",
    DE: "Suche abbrechen?",
  },
  {
    id: "Will everything be cancelled? Are you sure?",
    EN: "Will everything be cancelled? Are you sure?",
    TR: "Her şey iptal edilecek, emin misiniz?",
    DE: "Wird alles abgebrochen? Sind Sie sicher?",
  },
  {
    id: "All search canceled!",
    EN: "All search canceled!",
    TR: "Tüm aramalar iptal edildi!",
    DE: "Alle Suchen abgebrochen!",
  },
  {
    id: "Cancel All Search!",
    EN: "Cancel All Search!",
    TR: "Tüm Aramaları İptal Et!",
    DE: "Alle Suchen abbrechen!",
  },
  {
    id: "In Progress",
    EN: "In Progress",
    TR: "Devam Ediyor",
    DE: "In Bearbeitung",
  },
  {
    id: "Done",
    EN: "Done",
    TR: "Tamamlandı",
    DE: "Fertig",
  },
  {
    id: "Seller Type",
    EN: "Seller Type",
    TR: "Satıcı tipi",
    DE: "Verkäufertyp",
  },
  {
    id: "Seller Name",
    EN: "Seller Name",
    TR: "Satıcı adı",
    DE: "Verkäufername",
  },
  { id: "Stock", EN: "Stock", TR: "Stok", DE: "Lager" },
  { id: "Ratings", EN: "Ratings", TR: "Değerlendirmeler", DE: "Bewertungen" },
  {
    id: "Rating Count",
    EN: "Rating Count",
    TR: "Değerlendirme Sayısı",
    DE: "Bewertungszahl",
  },
  {
    id: "Delivery Time",
    EN: "Delivery Time",
    TR: "Teslimat Süresi",
    DE: "Lieferzeit",
  },
  {
    id: "Stock Fetching Completed",
    EN: "Stock Fetching Completed",
    TR: "Stok Çekme Tamamlandı",
    DE: "Lagerabruf abgeschlossen",
  },
  {
    id: "Stock Fetching Failed",
    EN: "Stock Fetching Failed",
    TR: "Stok Çekme Başarısız",
    DE: "Lagerabruf fehlgeschlagen",
  },
  {
    id: "Stock Fetching In Progress",
    EN: "Stock Fetching In Progress",
    TR: "Stok Çekme Devam Ediyor",
    DE: "Lagerabruf läuft",
  },
  {
    id: `Buybox - The buybox value of the crowded area of the target store is taken as reference.`,
    EN: `Buybox - The buybox value of the crowded area of the target store is taken as reference.`,
    TR: `Buybox - Hedef mağazanın kalabalık alanının buybox değeri referans alınır.`,
    DE: `Buybox - Der Buybox-Wert des überfüllten Bereichs des Zielgeschäfts wird als Referenz genommen.`,
  },
  {
    id: "Means this seller has limited max order quantity",
    EN: "Means this seller has limited max order quantity",
    TR: "Bu satıcının maksimum sipariş miktarı sınırlıdır",
    DE: "Bedeutet, dass dieser Verk äufer eine begrenzte maximale Bestellmenge hat",
  },
  {
    id: "Is Buybox",
    EN: "Is Buybox",
    TR: "Buybox mu",
    DE: "Ist Buybox",
  },
  {
    id: "True",
    EN: "True",
    TR: "Doğru",
    DE: "Wahr",
  },
  {
    id: "False",
    EN: "False",
    TR: "Yanlış",
    DE: "Falsch",
  },
  {
    id: "Total Price",
    EN: "Total Price",
    TR: "Toplam Fiyat",
    DE: "Gesamtpreis",
  },
  {
    id: "Seller URL",
    EN: "Seller URL",
    TR: "Satıcı URL'si",
    DE: "Verkäufer-URL",
  },
  {
    id: "Store Front URL",
    EN: "Store Front URL",
    TR: "Mağaza URL'si",
    DE: "Store Front URL",
  },

  {
    id: "Fetch Seller Asins",
    EN: "Fetch Seller Asins",
    TR: "Satıcı Asinleri Çek",
    DE: "Verkäufer-Asins abrufen",
  },
  {
    id: "Positive Feedbacks",
    EN: "Positive Feedbacks",
    TR: "Pozitif Geri Bildirimler",
    DE: "Positive Feedbacks",
  },
  {
    id: "Price With Delivery",
    EN: "Price With Delivery",
    TR: "Teslimatlı Fiyat",
    DE: "Preis mit Lieferung",
  },
  {
    id: "Profit",
    EN: "Profit",
    TR: "Kâr",
    DE: "Profit",
  },
  {
    id: "Source market",
    EN: "Source market",
    TR: "Kaynak pazar",
    DE: "Quellmarkt",
  },
  {
    id: "Quantity price",
    EN: "Quantity price",
    TR: "Miktar fiyatı",
    DE: "Mengenpreis",
  },
  {
    id: "Buybox Price",
    EN: "Buybox Price",
    TR: "Buybox Fiyatı",
    DE: "Buybox Preis",
  },
  {
    id: "Lowest Price",
    EN: "Lowest Price",
    TR: "En Düşük Fiyat",
    DE: "Niedrigster Preis",
  },
  {
    id: "Target market",
    EN: "Target market",
    TR: "Hedef pazar",
    DE: "Zielmarkt",
  },
  {
    id: "Copy",
    EN: "Copy",
    TR: "Kopyala",
    DE: "Kopieren",
  },
  {
    id: "BSR Drops (30/90/180)",
    EN: "BSR Drops (30/90/180)",
    TR: "BSR Düşüşleri (30/90/180)",
    DE: "BSR-Drops (30/90/180)",
  },
  {
    id: "Source price",
    EN: "Source price",
    TR: "Kaynak fiyat",
    DE: "Quellpreis",
  },
  {
    id: "Target price",
    EN: "Target price",
    TR: "Hedef fiyat",
    DE: "Zielpreis",
  },
  {
    id: "Inbound Cost",
    EN: "Inbound Cost",
    TR: "Giriş Maliyeti",
    DE: "Eingehende Kosten",
  },
  {
    id: "Item Dimensions",
    EN: "Item Dimensions",
    TR: "Ürün Boyutları",
    DE: "Artikelmaße",
  },
  {
    id: "Package Dimensions",
    EN: "Package Dimensions",
    TR: "Paket Boyutları",
    DE: "Paketmaße",
  },
  {
    id: "Item Weight",
    EN: "Item Weight",
    TR: "Ürün Ağırlığı",
    DE: "Artikelgewicht",
  },
  {
    id: "Package Weight",
    EN: "Package Weight",
    TR: "Paket Ağırlığı",
    DE: "Paketgewicht",
  },
  {
    id: "Value",
    EN: "Value",
    TR: "Değer",
    DE: "Wert",
  },
  {
    id: "Select Weight Type",
    EN: "Select Weight Type",
    TR: "Ağırlık Türü Seçin",
    DE: "Gewichtstyp auswählen",
  },
  {
    id: "Select..",
    EN: "Select..",
    TR: "Seçiniz..",
    DE: "Auswählen..",
  },
  {
    id: "Other Data",
    EN: "Other Data",
    TR: "Diğer Veriler",
    DE: "Weitere Daten",
  },
  {
    id: "Data",
    EN: "Data",
    TR: "Veri",
    DE: "Daten",
  },
  {
    id: "30 Day Avg",
    EN: "30 Day Avg",
    TR: "30 Gün Ortalaması",
    DE: "30-Tage-Durchschnitt",
  },
  {
    id: "90 Day Avg",
    EN: "90 Day Avg",
    TR: "90 Gün Ortalaması",
    DE: "90-Tage-Durchschnitt",
  },
  {
    id: "180 Day Avg",
    EN: "180 Day Avg",
    TR: "180 Gün Ortalaması",
    DE: "180-Tage-Durchschnitt",
  },
  {
    id: "365 Day Avg",
    EN: "365 Day Avg",
    TR: "365 Gün Ortalaması",
    DE: "365-Tage-Durchschnitt",
  },
  {
    id: "Upload ASIN",
    EN: "Upload ASIN",
    TR: "ASIN Yükle",
    DE: "ASIN hochladen",
  },
  {
    id: "Click or Drag File to This Area to Upload.",
    EN: "Click or Drag File to This Area to Upload.",
    TR: "Dosyayı Yüklemek için Buraya Tıklayın veya Sürükleyin.",
    DE: "Klicken oder ziehen Sie die Datei in dieses Feld zum Hochladen.",
  },
  {
    id: "OR",
    EN: "OR",
    TR: "VEYA",
    DE: "ODER",
  },
  {
    id: "File uploaded successfully.!",
    EN: "File uploaded successfully.!",
    TR: "Dosya başarıyla yüklendi.!",
    DE: "Datei erfolgreich hochgeladen.!",
  },
  {
    id: "File uploaded failed.!",
    EN: "File uploaded failed.!",
    TR: "Dosya yükleme başarısız oldu.!",
    DE: "Dateiupload fehlgeschlagen.!",
  },
  {
    id: "Paste the ASIN List You Copied Here..",
    EN: "Paste the ASIN List You Copied Here..",
    TR: "Kopyaladığınız ASIN Listesini Buraya Yapıştırın..",
    DE: "Fügen Sie die kopierte ASIN-Liste hier ein..",
  },
  {
    id: "Paste the ASIN List You Copied.",
    EN: "Paste the ASIN List You Copied.",
    TR: "Kopyaladığınız ASIN Listesini Yapıştırın.",
    DE: "Fügen Sie die kopierte ASIN-Liste ein.",
  },
  {
    id: "You can only upload Excel files!",
    EN: "You can only upload Excel files!",
    TR: "Yalnızca Excel dosyaları yükleyebilirsiniz!",
    DE: "Sie können nur Excel-Dateien hochladen!",
  },
  {
    id: "No files were uploaded!",
    EN: "No files were uploaded!",
    TR: "Hiç dosya yüklenmedi!",
    DE: "Es wurden keine Dateien hochgeladen!",
  },
  {
    id: "Pay Now!",
    EN: "Pay Now!",
    TR: "Şimdi Öde!",
    DE: "Jetzt bezahlen!",
  },
  {
    id: "Show Invoice",
    EN: "Show Invoice",
    TR: "Faturayı Göster",
    DE: "Rechnung anzeigen",
  },
  {
    id: "Minimum Weight",
    EN: "Minimum Weight",
    TR: "Minimum Ağırlık",
    DE: "Mindestgewicht",
  },
  {
    id: "Maximum Weight",
    EN: "Maximum Weight",
    TR: "Maksimum Ağırlık",
    DE: "Maximalgewicht",
  },
  {
    id: "Shipping price",
    EN: "Shipping price for range",
    TR: "Aralık için kargo fiyatı",
    DE: "Versandpreis für Bereich",
  },
  {
    id: "Please enter a column name before adding!",
    EN: "Please enter a column name before adding!",
    TR: "Eklemeden önce bir sütun adı giriniz!",
    DE: "Bitte geben Sie einen Spaltennamen ein, bevor Sie hinzufügen!",
  },
  {
    id: "Sheet Settings",
    EN: "Sheet Settings",
    TR: "E-tablo Ayarları",
    DE: "Blatteinstellungen",
  },
  {
    id: "Remove the columns you do not want to see in the sheet, edit the column order with drag and drop. If you want, you can also add a empty column to add comments to your sheet.",
    EN: "Remove the columns you do not want to see in the sheet, edit the column order with drag and drop. If you want, you can also add a empty column to add comments to your sheet.",
    TR: "Görmek istemediğiniz sütunları kaldırın, sütun sırasını sürükleyip bırakarak düzenleyin. İsterseniz, sayfanıza yorum eklemek için boş bir sütun da ekleyebilirsiniz.",
    DE: "Entfernen Sie die Spalten, die Sie im Blatt nicht sehen möchten, bearbeiten Sie die Spaltenreihenfolge per Drag-and-Drop. Wenn Sie möchten, können Sie auch eine leere Spalte hinzufügen, um Kommentare zu Ihrem Blatt hinzuzufügen.",
  },
  {
    id: "Please enter a setting name before saving.",
    EN: "Please enter a setting name before saving.",
    TR: "Kaydetmeden önce bir ayar adı giriniz.",
    DE: "Bitte geben Sie einen Einstellungsnamen ein, bevor Sie speichern.",
  },
  {
    id: "Setting saved successfully!",
    EN: "Setting saved successfully!",
    TR: "Ayar başarıyla kaydedildi!",
    DE: "Einstellung erfolgreich gespeichert!",
  },
  {
    id: "Please enter a sheet name before adding!",
    EN: "Please enter a sheet name before adding!",
    TR: "Eklemeden önce bir e-tablo adı giriniz!",
    DE: "Bitte geben Sie einen Blattnamen ein, bevor Sie hinzufügen!",
  },
  {
    id: "Saved successfully!",
    EN: "Saved successfully!",
    TR: "Başarıyla kaydedildi!",
    DE: "Erfolgreich gespeichert!",
  },
  {
    id: "Coming Soon",
    EN: "Coming Soon",
    TR: "Çok Yakında",
    DE: "Demnächst",
  },
  {
    id: "Overlapping or range is not valid",
    EN: "Overlapping or range is not valid",
    TR: "Örtüşme veya aralık geçerli değil",
    DE: "Überlappung oder Bereich ist nicht gültig",
  },
  {
    id: "Valuett",
    EN: "Values",
    TR: "Değerleri",
    DE: "-Werte",
  },
  {
    id: "Actionn",
    EN: "Action",
    TR: "İşlem",
    DE: "Operation",
  },
  {
    id: "Currency Value",
    EN: "Currency Value",
    TR: "Para Birimi Değeri",
    DE: "Währungswert",
  },
  {
    id: "Select Currency",
    EN: "Select Currency",
    TR: "Para Birimi Seçin",
    DE: "Währung auswählen",
  },
  {
    id: "File upload failed!",
    EN: "File upload failed!",
    TR: "Dosya yükleme başarısız oldu!",
    DE: "Dateiupload fehlgeschlagen!",
  },
  {
    id: "Please select weight type and currency!",
    EN: "Please select weight type and currency!",
    TR: "Lütfen ağırlık türünü ve para birimini seçin!",
    DE: "Bitte wählen Sie Gewichtstyp und Währung aus!",
  },
  {
    id: "Sheet Settings",
    EN: "Sheet Settings",
    TR: "E-tablo Ayarları",
    DE: "Blatteinstellungen",
  },
  {
    id: "Access Sheet",
    EN: "Access Sheet",
    TR: "E-tabloya Eriş",
    DE: "Auf Blatt zugreifen",
  },
  {
    id: "My Sheets",
    EN: "My Sheets",
    TR: "E-tablolarım",
    DE: "Meine Blätter",
  },
  {
    id: "Sheet name..",
    EN: "Sheet name..",
    TR: "E-tablo adı..",
    DE: "Blattname..",
  },
  {
    id: "Add New Google Sheet",
    EN: "Add New Google Sheet",
    TR: "Yeni E-tablo Ekle",
    DE: "Neues Google Blatt hinzufügen",
  },
  {
    id: "Min..",
    EN: "Min..",
    TR: "Min..",
    DE: "Min..",
  },
  {
    id: "Max..",
    EN: "Max..",
    TR: "Max..",
    DE: "Max..",
  },
  {
    id: "Price..",
    EN: "Price..",
    TR: "Fiyat..",
    DE: "Preis..",
  },
  {
    id: "Select Currency",
    EN: "Select Currency",
    TR: "Para Birimi Seç",
    DE: "Währung auswählen",
  },
  {
    id: "Column name..",
    EN: "Column name..",
    TR: "Sütun adı..",
    DE: "Spaltenname..",
  },
  {
    id: "Add New Empty Column",
    EN: "Add New Empty Column",
    TR: "Yeni Boş Sütun Ekle",
    DE: "Neue leere Spalte hinzufügen",
  },
  {
    id: "For Example:",
    EN: "For Example:",
    TR: "Örnek:",
    DE: "Beispiel:",
  },
  {
    id: "If it is between Min 1KG - Max 3KG; 5 USD.",
    EN: "If it is between Min 1KG - Max 3KG; 5 USD.",
    TR: "Min 1KG - Max 3KG arası olursa; 5 USD.",
    DE: "Wenn es zwischen Min 1KG - Max 3KG liegt; 5 USD.",
  },
  {
    id: "If it is between Min 3KG - Max 5KG; 10 USD... etc.",
    EN: "If it is between Min 3KG - Max 5KG; 10 USD... etc.",
    TR: "Min 3KG - Max 5KG arası olursa; 10 USD... gibi.",
    DE: "Wenn es zwischen Min 3KG - Max 5KG liegt; 10 USD... usw.",
  },
  {
    id: "If the product weight exceeds the maximum value, the calculation will be based on the highest value.",
    EN: "If the product weight exceeds the maximum value, the calculation will be based on the highest value.",
    TR: "Ürün ağırlığı girilen maksimum değeri aşarsa, hesaplama en yüksek girilen değere göre yapılır.",
    DE: "Wenn das Gewicht des Produkts den Höchstwert überschreitet, erfolgt die Berechnung basierend auf dem höchsten Wert.",
  },
  {
    id: "If the selected currency is different, the system will automatically convert the amount to the target store's currency.",
    EN: "If the selected currency is different, the system will automatically convert the amount to the target store's currency.",
    TR: "Sistem girilen fiyatı otomatik olarak hedef mağazanın para birimine çevirir.",
    DE: "Wenn die ausgewählte Währung anders ist, wird das System den Betrag automatisch in die Währung des Zielgeschäfts umrechnen.",
  },
  {
    id: "icon means “Custom Shipping Setting” is active.",
    EN: "icon means “Custom Shipping Setting” is active.",
    TR: "ikonu varsa “Özel Kargo Ayarı Aktif” aktif demektir.",
    DE: "Wenn ein Symbol vorhanden ist, bedeutet dies „Spezielle Frachteinstellung ist aktiv“.",
  },
  {
    id: "You can make more specific settings such as 10 USD if it is between 1KG and 5KG or 15USD if it is between 5KG and 8KG.",
    EN: "You can make more specific settings such as 10 USD if it is between 1KG and 5KG or 15USD if it is between 5KG and 8KG.",
    TR: "1KG - 5KG arası olursa 10 USD ya da 5KG - 8KG arası olursa 15USD gibi daha spesifik ayarlamalar yapabilirsiniz.",
    DE: "Sie können spezifischere Anpassungen vornehmen, z. B. 10 USD, wenn das Gewicht zwischen 1 kg und 5 kg liegt, oder 15 USD, wenn es zwischen 5 kg und 8 kg liegt.",
  },
  {
    id: "For Custom Shipping Setting, you must select the FBM option!",
    EN: "For Custom Shipping Setting, you must select the FBM option!",
    TR: "Özel Kargo Ayarı için FBM seçeneğini seçmelisiniz!",
    DE: "Für die benutzerdefinierten Versandeinstellungen müssen Sie die FBM-Option auswählen!",
  },
  {
    id: "If Custom Shipping Setting is active, you can't select FBA!",
    EN: "If Custom Shipping Setting is active, you can't select FBA!",
    TR: "Özel Kargo Ayarı varsa FBA seçemezsiniz!",
    DE: "Deaktivieren Sie die Einstellung für den Sonderversand!",
  },
  {
    id: "Show Telegram Settings",
    EN: "Show Telegram Settings",
    TR: "Telegram Ayarlarını Göster",
    DE: "Telegrammeinstellungen anzeigen",
  },
  {
    id: "Hide Telegram Settings",
    EN: "Hide Telegram Settings",
    TR: "Telegram Ayarlarını Gizle",
    DE: "Telegrammeinstellungen ausblenden",
  },
  {
    id: "Your currently active marketplace setting in your account is used. Every ASIN message drops a Product Based Info token!",
    EN: "Your currently active marketplace setting in your account is used. Every ASIN message drops a Product Based Info token!",
    TR: "Hesabınızda şuan aktif seçili olan pazar yeri ayarınız kullanılır. Her ASIN mesajı bir Ürün Botu jetonunuzu düşürür!",
    DE: "Es wird Ihre aktuell aktive Marktplatzeinstellung in Ihrem Konto verwendet. Jede ASIN-Nachricht lässt eine Product Bot-Münze fallen!",
  },
  {
    id: "How can I use it?",
    EN: "How can I use it?",
    TR: "Nasıl Kullanabilirim?",
    DE: "Wie kann ich es verwenden?",
  },
  {
    id: "Without entering the web dashboard or using the extension, simply send the ASIN information of a product to the Kepicker Telegram bot and receive the Product Based Info informations for that product as a message.",
    EN: "Without entering the web dashboard or using the extension, simply send the ASIN information of a product to the Kepicker Telegram bot and receive the Product Based Info informations for that product as a message.",
    TR: "Web panele girmeden ya da eklentiyi kullanmadan sadece Kepicker Telegram botuna bir ürünün ASIN bilgisini gönderin ve o ürüne yönelik Ürün Botu bilgilerini mesaj olarak alın.",
    DE: "Ohne das Web-Panel aufzurufen oder das Plug-in zu verwenden, senden Sie einfach die ASIN-Informationen eines Produkts an den Kepicker Telegram-Bot und erhalten Sie die Produkt-Bot-Informationen für dieses Produkt als Nachricht.",
  },
  {
    id: "Saved Custom Shipping Setting —",
    EN: "Saved Custom Shipping Setting —",
    TR: "Kayıtlı Özel Kargo Ayarı —",
    DE: "Gespeicherte benutzerdefinierte Versandeinstellung –",
  },
  {
    id: "Please add at least one shipping setting or select weight type and currency!",
    EN: "Please add at least one shipping setting or select weight type and currency!",
    TR: "Lütfen en az bir kargo ayarı ekleyin veya ağırlık türü ve para birimini seçin!",
    DE: "Bitte fügen Sie mindestens eine Versandeinstellung hinzu oder wählen Sie Gewichtstyp und Währung aus!",
  },
  {
    id: "No custom shipping settings added. Please add at least one setting before saving.",
    EN: "No custom shipping settings added. Please add at least one setting before saving.",
    TR: "Özel kargo ayarları eklenmedi. Kaydetmeden önce lütfen en az bir ayar ekleyin.",
    DE: "Keine benutzerdefinierten Versandeinstellungen hinzugefügt. Bitte fügen Sie vor dem Speichern mindestens eine Einstellung hinzu.",
  },
  {
    id: "Please enter Chat ID!",
    EN: "Please enter Chat ID!",
    TR: "Lütfen Chat ID giriniz!",
    DE: "Bitte geben Sie die Chat-ID ein!",
  },
  {
    id: "Please come back!..",
    EN: "Please come back!..",
    TR: "Lütfen geri dön!..",
    DE: "Bitte komm zurück!..",
  },

  {
    id: "with Kepicker.",
    EN: "with Kepicker.",
    TR: "Kepicker ile;",
    DE: "Mit Kepicker.",
  },
  {
    id: "🏆 The Ultimate Amazon Product Research Tool!",
    EN: "🏆 The Ultimate Amazon Product Research Tool!",
    TR: "🏆 Gelişmiş Amazon Ürün Araştırma Aracı!",
    DE: "🏆 Das ultimative Amazon-Produktforschungstool!",
  },
  {
    id: "The Ultimate Amazon Product Research Tool!",
    EN: "The Ultimate Amazon Product Research Tool!",
    TR: "Gelişmiş Amazon Ürün Araştırma Aracınız!",
    DE: "Das ultimative Amazon-Produktforschungstool!",
  },
  {
    id: "Extract ASINs Quickly",
    EN: "Extract ASINs Quickly",
    TR: "ASIN'leri Çekin.",
    DE: "ASINs schnell extrahieren",
  },
  {
    id: "Fetch Sellers Storefront",
    EN: "Fetch Sellers Storefront",
    TR: "Satıcıları İnceleyin.",
    DE: "Verkäufer-Storefront abrufen",
  },
  {
    id: "Compare Products",
    EN: "Compare Products",
    TR: "Ürünleri Karşılaştırın.",
    DE: "Produkte vergleichen",
  },
  {
    id: "Calculate ROI",
    EN: "Calculate ROI",
    TR: "ROI Hesaplayın.",
    DE: "ROI berechnen",
  },
  {
    id: "View Keepa Data",
    EN: "View Keepa Data",
    TR: "Keepa Verilerini Alın.",
    DE: "Keepa-Daten anzeigen",
  },
  {
    id: "Show Product Metrics",
    EN: "Show Product Metrics",
    TR: "Maliyetleri Hesaplayın.",
    DE: "Produktmetriken anzeigen",
  },
  {
    id: "Token Based Free Trial",
    EN: "Token Based Free Trial",
    TR: "Jeton Bazlı Ücretsiz Deneme",
    DE: "Token-basierte kostenlose Testversion",
  },
  {
    id: "No Credit Card Required",
    EN: "No Credit Card Required",
    TR: "Kredi Kartı Gerekmez",
    DE: "Keine Kreditkarte erforderlich",
  },
  {
    id: "Product Metrics",
    EN: "Product Metrics",
    TR: "Ürün Metrikleri",
    DE: "Produktmetriken",
  },
  {
    id: "The extension calculates critical financial indicators such as ROI, profit, expenses, and sales ranking. Maximize your profits with real-time data!",
    EN: "The extension calculates critical financial indicators such as ROI, profit, expenses, and sales ranking. Maximize your profits with real-time data!",
    TR: "Eklenti; ROI, kâr, giderler ve satış sıralaması gibi kritik finansal göstergeleri hesaplar. Kârınızı gerçek zamanlı verilerle en üst düzeye çıkarın!",
    DE: "Die Erweiterung berechnet wichtige finanzielle Indikatoren wie ROI, Gewinn, Ausgaben und Verkaufsrang. Maximieren Sie Ihren Gewinn mit Echtzeitdaten!",
  },
  {
    id: "Fees Comparison",
    EN: "Fees Comparison",
    TR: "Fiyat Karşılaştırma",
    DE: "Gebührenvergleich",
  },
  {
    id: "Estimate selling fees and costs - calculate fees, costs, and revenue for a current or future product.",
    EN: "Estimate selling fees and costs - calculate fees, costs, and revenue for a current or future product.",
    TR: "Satış ücretlerini ve maliyetlerini analiz edin - mevcut bir ürün ile ilgili ücretleri, maliyetleri ve geliri hesaplayın.",
    DE: "Schätzen Sie Verkaufsgebühren und Kosten - berechnen Sie Gebühren, Kosten und Einnahmen für ein aktuelles oder zukünftiges Produkt.",
  },
  {
    id: "Compare ASINs across different Amazon marketplaces to pinpoint the most profitable opportunities.",
    EN: "Compare ASINs across different Amazon marketplaces to pinpoint the most profitable opportunities.",
    TR: "En kârlı ürünleri tespit etmek için farklı Amazon pazar yerlerini karşılaştırın.",
    DE: "Vergleichen Sie ASINs in verschiedenen Amazon-Marktplätzen, um die profitabelsten Möglichkeiten zu ermitteln.",
  },
  {
    id: "View comprehensive historical Keepa data to predict future trends and pricing strategies.",
    EN: "View comprehensive historical Keepa data to predict future trends and pricing strategies.",
    TR: "Gelecekteki trendleri ve fiyatlandırma stratejilerini tahmin etmek için temel Keepa verilerini görüntüleyin.",
    DE: "Umfassende historische Keepa-Daten anzeigen, um zukünftige Trends und Preisstrategien vorherzusagen.",
  },
  {
    id: "Make your own calculations by manually entering the source and target market price and shipping price.",
    EN: "Make your own calculations by manually entering the source and target market price and shipping price.",
    TR: "Kaynak ve hedef pazar fiyatını ve nakliye ücretini manuel olarak girerek kendi hesaplamalarınızı yapın.",
    DE: "Führen Sie eigene Berechnungen durch, indem Sie manuell den Quell- und Zielmarktpreis sowie den Versandpreis eingeben.",
  },
  {
    id: "Easily upload and compare lists of ASINs. Perfect for bulk analysis and strategic planning.",
    EN: "Easily upload and compare lists of ASINs. Perfect for bulk analysis and strategic planning.",
    TR: "ASIN listelerini kolayca yükleyin ve karşılaştırın. Toplu analiz ve stratejik planlama için idealdir.",
    DE: "ASIN-Listen einfach hochladen und vergleichen. Perfekt für Massenanalysen und strategische Planung.",
  },
  {
    id: "Get detailed stock and storefront data to ensure you never run out of your best sellers.",
    EN: "Get detailed stock and storefront data to ensure you never run out of your best sellers.",
    TR: "Görüntülediğiniz bir ürüne yönelik detaylı stok ve mağaza verilerini hızlıca alın.",
    DE: "Detaillierte Lager- und Storefront-Daten erhalten, um sicherzustellen, dass Ihnen Ihre Bestseller nie ausgehen.",
  },
  {
    id: "Kepicker Core Tools",
    EN: "Kepicker Core Tools",
    TR: "Temel Kepicker Araçları",
    DE: "Kepicker Kernwerkzeuge",
  },

  {
    id: "Leverage Amazon analytics from all over the globe.",
    EN: "Leverage Amazon analytics from all over the globe.",
    TR: "Kepicker ile Kazancınıza Yön Verin: Hızlı Analiz, Akıllı Kararlar!",
    DE: "Nutzen Sie Amazon-Analysen aus der ganzen Welt.",
  },
  {
    id: "Compare All Amazon Markets",
    EN: "Compare All Amazon Markets",
    TR: "Tüm Amazon Pazarlarını Karşılaştırın",
    DE: "Vergleichen Sie alle Amazon-Märkte",
  },
  {
    id: "Find the Most Profitable Products to Sell on Amazon!",
    EN: "Find the Most Profitable Products to Sell on Amazon!",
    TR: "Amazon'da Satış Potansiyeli Yüksek En Kârlı Ürünleri Bulun!",
    DE: "Finden Sie die profitabelsten Produkte zum Verkauf auf Amazon!",
  },
  {
    id: "Unlock the Full Potential of your Amazon Selling Experience with Kepicker.",
    EN: "Unlock the Full Potential of your Amazon Selling Experience with Kepicker.",
    TR: "Amazon Satış Deneyiminizi Kepicker ile Zirveye Taşıyın.",
    DE: "Entfesseln Sie das volle Potenzial Ihres Amazon-Verkaufserlebnisses mit Kepicker.",
  },
  {
    id: "Members Love Our Software",
    EN: "Members Love Our Software",
    TR: "Kullanıcılarımız Kepicker'ı Seviyor",
    DE: "Mitglieder lieben unsere Software",
  },
  {
    id: "What People Are Saying About Kepicker Extension",
    EN: "What People Are Saying About Kepicker Extension",
    TR: "İnsanların Kepicker Eklentisi Hakkında Söyledikleri",
    DE: "Was die Leute über die Kepicker-Erweiterung sagen",
  },
  {
    id: "Have a question or need help? Our dedicated support team is just a ticket away.",
    EN: "Have a question or need help? Our dedicated support team is just a ticket away.",
    TR: "Bir sorunuz mu var veya yardıma mı ihtiyacınız var? Tecrübeli destek ekibimiz sadece bir ticket uzaklıkta.",
    DE: "Haben Sie eine Frage oder benötigen Hilfe? Unser engagiertes Support-Team ist nur ein Ticket entfernt.",
  },
  {
    id: "Dedicated Support",
    EN: "Dedicated Support",
    TR: "Özel Destek",
    DE: "Dedizierter Support",
  },
  {
    id: "Kepicker has multiple languages: Türkçe, English, Deutsch..",
    EN: "Kepicker has multiple languages: Türkçe, English, Deutsch..",
    TR: "Kepicker birden fazla dilde mevcut: Türkçe, English, Deutsch..",
    DE: "Kepicker ist mehrsprachig: Türkçe, English, Deutsch..",
  },
  {
    id: "Multi-Language",
    EN: "Multi-Language",
    TR: "Çoklu Dil Desteği",
    DE: "Mehrsprachig",
  },
  {
    id: "Empower your business decisions with robust data analytics.",
    EN: "Empower your business decisions with robust data analytics.",
    TR: "Satış kararlarınızı sağlam veri analitiği ile güçlendirin.",
    DE: "Stärken Sie Ihre Geschäftsentscheidungen mit robusten Datenanalysen.",
  },
  {
    id: "Data-Driven Decisions",
    EN: "Data-Driven Decisions",
    TR: "Veriye Dayalı Kararlar",
    DE: "Datengetriebene Entscheidungen",
  },
  {
    id: "Easy to navigate and use, regardless of your tech skills.",
    EN: "Easy to navigate and use, regardless of your tech skills.",
    TR: "Teknik becerileriniz ne olursa olsun kullanımı kolay ve gezinmesi basit.",
    DE: "Einfach zu bedienen und zu navigieren, unabhängig von Ihren technischen Fähigkeiten.",
  },
  {
    id: "User-Friendly Interface",
    EN: "User-Friendly Interface",
    TR: "Kullanıcı Dostu Arayüz",
    DE: "Benutzerfreundliche Schnittstelle",
  },
  {
    id: "Fast and Reliable",
    EN: "Fast and Reliable",
    TR: "Hızlı ve Güvenilir",
    DE: "Schnell und zuverlässig",
  },
  {
    id: "Designed for efficiency, our tools deliver results quickly and accurately.",
    EN: "Designed for efficiency, our tools deliver results quickly and accurately.",
    TR: "Verimlilik için tasarlandı; yazılımımız sonuçları hızlı ve doğru bir şekilde sunar.",
    DE: "Für Effizienz entwickelt, liefern unsere Werkzeuge schnell und genau Ergebnisse.",
  },
  {
    id: "Our tools are designed to streamline your workflow, reducing the time you spend managing your listings.",
    EN: "Our tools are designed to streamline your workflow, reducing the time you spend managing your listings.",
    TR: "Kepicker, pazar araştırmasını ve ürün keşfini hızlandırarak zamanınızı en iyi şekilde değerlendirmenize olanak tanır.",
    DE: "Unsere Werkzeuge sind darauf ausgelegt, Ihren Arbeitsablauf zu optimieren und die Zeit zu reduzieren, die Sie mit der Verwaltung Ihrer Einträge verbringen.",
  },
  {
    id: "Custom Shipping Settings",
    EN: "Custom Shipping Settings",
    TR: "Özel Kargo Ayarları",
    DE: "Benutzerdefinierte Versandeinstellungen",
  },

  {
    id: "Tailor your shipping strategies with our custom settings to meet diverse market demands and maximize efficiency.",
    EN: "Tailor your shipping strategies with our custom settings to meet diverse market demands and maximize efficiency.",
    TR: "Farklı pazar taleplerini karşılamak ve verimliliği en üst düzeye çıkarmak için kargo stratejilerinizi özel ayarlarla şekillendirin.",
    DE: "Passen Sie Ihre Versandstrategien mit unseren benutzerdefinierten Einstellungen an, um den Anforderungen verschiedener Märkte gerecht zu werden und die Effizienz zu maximieren.",
  },
  {
    id: "Buy Our $50 or $100 Packages and Get Extra Tokens for FREE!",
    EN: "Buy Our $50 or $100 Packages and Get Extra Tokens for FREE!",
    TR: "50$ veya 100$'lık Paketlerimizi Satın Alarak Ekstra Ücretsiz Jetonlar Kazanın!",
    DE: "Kaufen Sie unsere 50$ oder 100$ Pakete und erhalten Sie zusätzliche Tokens GRATIS!",
  },
  {
    id: "Business Prime discounts from amazon.com.",
    EN: "Business Prime discounts from amazon.com.",
    TR: "Amazon.com'dan Business Prime indirimleri.",
    DE: "Business Prime-Rabatte von amazon.com.",
  },
  {
    id: "Store registration required!",
    EN: "Store registration required!",
    TR: "Mağaza kaydı gereklidir!",
    DE: "Speicherregistrierung erforderlich!",
  },
  {
    id: "1000 Actions",
    EN: "1000 Actions",
    TR: "1000 İşlem",
    DE: "1000 Aktionen",
  },
  {
    id: "ROI Calculation",
    EN: "ROI Calculation",
    TR: "ROI Hesaplama",
    DE: "ROI-Berechnung",
  },
  {
    id: "For Once",
    EN: "For Once",
    TR: "Tek Seferlik",
    DE: "Einmal",
  },
  {
    id: "Dimensional Measurement",
    EN: "Dimensional Measurement",
    TR: "Boyutsal Ölçümler",
    DE: "Dimensionale Messung",
  },
  {
    id: "Currency Converter",
    EN: "Currency Converter",
    TR: "Döviz Çevirici",
    DE: "Währungsumrechner",
  },
  {
    id: "1000 Products",
    EN: "1000 Products",
    TR: "1000 Ürün",
    DE: "1000 Produkte",
  },
  {
    id: "Store Protection",
    EN: "Store Protection",
    TR: "Mağaza Koruma",
    DE: "Speicherschutz",
  },
  {
    id: "Paid Extra Token",
    EN: "Paid Extra Token",
    TR: "Ücretli Ekstra Jeton",
    DE: "Bezahltes Extra-Token",
  },
  {
    id: "600 Actions",
    EN: "600 Actions",
    TR: "600 İşlem",
    DE: "600 Aktionen",
  },
  {
    id: "Daily",
    EN: "Daily",
    TR: "Günlük",
    DE: "Täglich",
  },
  {
    id: "Monthly",
    EN: "Monthly",
    TR: "Aylık",
    DE: "Monatlich",
  },
  {
    id: "10K Products",
    EN: "10K Products",
    TR: "10K Ürün",
    DE: "10K Produkte",
  },
  {
    id: "25K Products",
    EN: "25K Products",
    TR: "25K Ürün",
    DE: "25K Produkte",
  },
  {
    id: "Don't miss out on our exclusive deal! Buy our $50 or $100 packages and get extra tokens for free!",
    EN: "Don't miss out on our exclusive deal! Buy our $50 or $100 packages and get extra tokens for free!",
    TR: "Özel teklifimizi kaçırmayın! 50$ veya 100$'lık paketlerimizi satın alarak ekstra jeton kazanın!",
    DE: "Verpassen Sie nicht unser exklusives Angebot! Kaufen Sie unsere 50$ oder 100$ Pakete und erhalten Sie zusätzliche Tokens kostenlos!",
  },
  {
    id: "Purchase Now",
    EN: "Purchase Now",
    TR: "Şimdi Satın Alın",
    DE: "Jetzt kaufen",
  },
  {
    id: "Limited Time Offer",
    EN: "Limited Time Offer",
    TR: "Sınırlı Süreli Teklif",
    DE: "Begrenztes Zeitangebot",
  },
  {
    id: "Choose Package",
    EN: "Choose Package",
    TR: "Paket Seçin",
    DE: "Paket wählen",
  },
  {
    id: "Get Started Today!",
    EN: "Get Started Today!",
    TR: "Bugün Başlayın!",
    DE: "Starten Sie heute!",
  },
  {
    id: "Get Started With a Free Account",
    EN: "Get Started With a Free Account",
    TR: "Ücretsiz Hesap ile Başlayın",
    DE: "Mit einem kostenlosen Konto beginnen",
  },
  {
    id: "Increase your revenue with Kepicker!",
    EN: "Increase your revenue with Kepicker!",
    TR: "Kepicker ile satış gelirinizi artırın!",
    DE: "Steigern Sie Ihren Umsatz mit Kepicker!",
  },
  {
    id: "Kepicker offers all the basic features you'll need for product research.",
    EN: "Kepicker offers all the basic features you'll need for product research.",
    TR: "Kepicker, ürün araştırması için ihtiyacınız olan tüm temel özellikleri sunar.",
    DE: "Kepicker bietet alle grundlegenden Funktionen, die Sie für die Produktrecherche benötigen.",
  },

  {
    id: "Analyze Smarter.",
    EN: "Analyze Smarter.",
    TR: "Akıllı Analiz Et.",
    DE: "Smarter analysieren.",
  },
  {
    id: "Find Better.",
    EN: "Find Better.",
    TR: "İyisini Bul.",
    DE: "Besser finden.",
  },
  {
    id: "Search Faster.",
    EN: "Search Faster.",
    TR: "Hızlı Ara.",
    DE: "Schneller suchen.",
  },
  {
    id: "Kepicker is your go-to tool for gaining a competitive edge!",
    EN: "Kepicker is your go-to tool for gaining a competitive edge!",
    TR: "Kepicker, dinamik Amazon pazarında rakiplerinizden bir adım öne geçmenizi sağlar!",
    DE: "Kepicker ist Ihr Werkzeug, um einen Wettbewerbsvorteil zu erlangen!",
  },
  {
    id: "Barcode UPC Lookup",
    EN: "Barcode UPC Lookup",
    TR: "Barkod UPC Sorgulama",
    DE: "Barcode-UPC-Suche",
  },
  {
    id: "Search the Product on Google",
    EN: "Search the Product on Google",
    TR: "Ürünü Google'da Ara",
    DE: "Das Produkt auf Google suchen",
  },
  {
    id: "ASIN - EAN - UPC Infos",
    EN: "ASIN - EAN - UPC Infos",
    TR: "ASIN - EAN - UPC Bilgileri",
    DE: "ASIN - EAN - UPC Infos",
  },
  {
    id: "Telegram Integration",
    EN: "Telegram Integration",
    TR: "Telegram Entegrasyonu",
    DE: "Telegram-Integration",
  },
  {
    id: "Asins to Google Sheets",
    EN: "Asins to Google Sheets",
    TR: "Asin'leri Google Sheets'e Aktar",
    DE: "ASINs zu Google Sheets",
  },
  {
    id: "Business Prime Discounts",
    EN: "Business Prime Discounts",
    TR: "Business Prime İndirimleri",
    DE: "Business Prime-Rabatte",
  },
  {
    id: "Heat Sensitive, Meltable Tags",
    EN: "Heat Sensitive, Meltable Tags",
    TR: "Isıya Duyarlı, Eriyebilir Madde Etiketleri",
    DE: "Hitzeempfindliche, schmelzbare Tags",
  },
  {
    id: "Variation, Hazmat Tags",
    EN: "Variation, Hazmat Tags",
    TR: "Varyasyon, Tehlikeli Madde Etiketleri",
    DE: "Variationen, Gefahrgut-Etiketten",
  },
  {
    id: "Amazon Best Sellers Rank (BSR)",
    TR: "Amazon Best Sellers Rank (BSR)",
    TR: "Amazon En Çok Satanlar Sıralaması (BSR)",
    DE: "Amazon Bestseller-Rang (BSR)",
  },
  {
    id: "Monthly Sales Count",
    EN: "Monthly Sales Count",
    TR: "Aylık Satış Sayısı",
    DE: "monatliche Verkaufszahl",
  },
  {
    id: "Historical Product Data",
    EN: "Historical Product Data",
    TR: "Geçmiş Ürün Verileri",
    DE: "Historische Produktdaten",
  },
  {
    id: "Currency Converter",
    EN: "Currency Converter",
    TR: "Döviz Çevirici",
    DE: "Währungsumrechner",
  },
  {
    id: "Auto / Manual ROI Calculation",
    EN: "Auto / Manual ROI Calculation",
    TR: "Otomatik / Manuel ROI Hesaplama",
    DE: "Automatische / manuelle ROI-Berechnung",
  },
  {
    id: "Advanced Stock Fetcher",
    EN: "Advanced Stock Fetcher",
    TR: "Gelişmiş Stok Çekici",
    DE: "Fortgeschrittener Lagerabruf",
  },
  {
    id: "FBA - FBM Mode",
    EN: "FBA - FBM Mode",
    TR: "FBA - FBM Modu",
    DE: "FBA - FBM-Modus",
  },
  {
    id: "Web Based Asin 360° Tool",
    EN: "Web Based Asin 360° Tool",
    TR: "Web Tabanlı Asin 360° Aracı",
    DE: "Webbasiertes Asin 360°-Tool",
  },
  {
    id: "Advanced Filters",
    EN: "Advanced Filters",
    TR: "Gelişmiş Filtreler",
    DE: "Erweiterte Filter",
  },

  {
    id: "Manage ASIN Lists",
    EN: "Manage ASIN Lists",
    TR: "ASIN Listelerini Yönet",
    DE: "ASIN-Listen verwalten",
  },
  {
    id: "Upload - Merge ASINs",
    EN: "Upload - Merge ASINs",
    TR: "ASIN Yükle - ASIN'leri Birleştir",
    DE: "Hochladen - ASINs zusammenführen",
  },
  {
    id: "Storefront Data",
    EN: "Storefront Data",
    TR: "Mağaza Verileri",
    DE: "Storefront-Daten",
  },
  {
    id: "Find Competing Sellers",
    EN: "Find Competing Sellers",
    TR: "Rekabet Eden Satıcıları Bul",
    DE: "Konkurrierende Verkäufer finden",
  },
  {
    id: "Custom Shipping Settings for FBM",
    EN: "Custom Shipping Settings for FBM",
    TR: "FBM İçin Özel Kargo Ayarları",
    DE: "Benutzerdefinierte Versandeinstellungen für FBM",
  },
  {
    id: "Compare All Amazon Marketplaces",
    EN: "Compare All Amazon Marketplaces",
    TR: "Tüm Amazon Pazarlarını Karşılaştır",
    DE: "Alle Amazon-Marktplätze vergleichen",
  },
  {
    id: "Powerful ASIN Grabber",
    EN: "Powerful ASIN Grabber",
    TR: "Güçlü ASIN Çekici",
    DE: "Leistungsstarker ASIN-Grabber",
  },

  {
    id: "1. What is Kepicker Software? What Does It Do? Introduction to Training..",
    EN: "1. What is Kepicker Software? What Does It Do? Introduction to Training..",
    TR: "1. Kepicker Yazılımı Nedir? Ne İşe Yarar? Eğitime Giriş..",
    DE: "1. Was ist Kepicker Software? Wozu dient es? Einführung in die Schulung..",
  },
  {
    id: "2. Account Opening: How Can I Register?",
    EN: "2. Account Opening: How Can I Register?",
    TR: "2. Hesap Açma: Nasıl Kayıt Olabilirim?",
    DE: "2. Kontoeröffnung: Wie kann ich mich registrieren?",
  },
  {
    id: "3. Connecting Amazon Seller Central Account (Store), Downloading the Extension",
    EN: "3. Connecting Amazon Seller Central Account (Store), Downloading the Extension",
    TR: "3. Amazon Seller Central Hesabını (Mağaza) Bağlama, Eklentiyi İndirme",
    DE: "3. Verbinden des Amazon Seller Central-Kontos (Shop), Herunterladen der Erweiterung",
  },
  {
    id: "4. How Can I Configure Marketplace Settings?",
    EN: "4. How Can I Configure Marketplace Settings?",
    TR: "4. Pazar Yeri Ayarlarını Nasıl Yapabilirim?",
    DE: "4. Wie kann ich Marktplatzeinstellungen konfigurieren?",
  },
  {
    id: "5. Asin SPY, Asin Extractor, Uploader Tool Tools",
    EN: "5. Asin SPY, Asin Extractor, Uploader Tool Tools",
    TR: "5. Asin SPY, Asin Extractor, Uploader Tool Araçları",
    DE: "5. Asin SPY, Asin Extractor, Uploader Tool Werkzeuge",
  },
  {
    id: "6. Compare Marketplaces; Product Comparison Panel, ASIN Management",
    EN: "6. Compare Marketplaces; Product Comparison Panel, ASIN Management",
    TR: "6. Compare Marketplaces; Ürün Karşılaştırma Paneli, ASIN Yönetimi",
    DE: "6. Marktplätze vergleichen; Produktvergleichspanel, ASIN-Verwaltung",
  },
  {
    id: "7. Product Based Info (Metrics, ROI, Calculation, Keepa Data, Stock Fetching, Google Sheets..)",
    EN: "7. Product Based Info (Metrics, ROI, Calculation, Keepa Data, Stock Fetching, Google Sheets..)",
    TR: "7. Ürün Botu (Metrikler, ROI, Hesaplama, Keepa Verileri, Stok Çekme, Google Sheets..)",
    DE: "7. Produkt-Bot Informationen (Metriken, ROI, Berechnung, Keepa-Daten, Lagerabruf, Google Tabellen..)",
  },
  {
    id: "8. Using Kepicker Dashboard: Asin 360°, Ticket, Plan, Settings..",
    EN: "8. Using Kepicker Dashboard: Asin 360°, Ticket, Plan, Settings..",
    TR: "8. Kepicker Paneli Kullanımı: Asin 360°, Ticket, Plan, Ayarlar..",
    DE: "8. Nutzung des Kepicker Panels: Asin 360°, Ticket, Plan, Einstellungen..",
  },
  {
    id: "Learn Kepicker Software in 1 Hour (Complete from Start to Finish in One Video)!",
    EN: "Learn Kepicker Software in 1 Hour (Complete from Start to Finish in One Video)!",
    TR: "Kepicker Yazılımını 1 Saatte Öğrenin (Baştan Sona Tamamı Tek Videoda)!",
    DE: "Lernen Sie die Kepicker-Software in 1 Stunde (komplett von Anfang bis Ende in einem Video)!",
  },
  {
    id: "Read More Review",
    EN: "Read More Review",
    TR: "Daha Fazlasını Görüntüle",
    DE: "Lesen Sie mehr Rezension",
  },
  {
    id: "Most Popular",
    EN: "Most Popular",
    TR: "En Popüler",
    DE: "Am beliebtesten",
  },
  {
    id: "Kepicker 🏆 Transform your Amazon Selling Journey!",
    EN: "Kepicker 🏆 Transform your Amazon Selling Journey!",
    TR: "Kepicker 🏆 Amazon'da Kârlılığınızı Maksimize Edin!",
    DE: "Kepicker 🏆 Verwandeln Sie Ihre Amazon-Verkaufsreise!",
  },
  {
    id: "Say hello to Industry’s newest Amazon Product Research Tool. Compare all Amazon marketplaces - increase leads with Kepicker. Let's find the most profitable products!",
    EN: "Say hello to Industry’s newest Amazon Product Research Tool. Compare all Amazon marketplaces - increase leads with Kepicker. Let's find the most profitable products!",
    TR: "Sektörün en yeni Amazon Ürün Araştırma Aracına merhaba deyin. Tüm Amazon pazar yerlerini karşılaştırın - Kepicker ile Amazon'da Bir Adım Önde Olun. Kâr Marjı Yüksek Ürünleri Bulun!",
    DE: "Begrüßen Sie das neueste Amazon-Produktforschungstool der Branche. Vergleichen Sie alle Amazon-Marktplätze - erhöhen Sie Leads mit Kepicker. Lassen Sie uns die profitabelsten Produkte finden!",
  },
  {
    id: "Meet the Kepicker's Product Based Info —",
    EN: "Meet the Kepicker's Product Based Info —",
    TR: "Kepicker'ın Ürün Botu Aracıyla Tanışın —",
    DE: "Lernen Sie den Produkt-Bot von Kepicker kennen –",
  },
  {
    id: "+2K Comparison Tokens for $50 Package & +5K Comparison Tokens for $100 Package!",
    EN: "+2K Comparison Tokens for $50 Package & +5K Comparison Tokens for $100 Package!",
    TR: "$50 Paketine +2K Karşılaştırma Jetonu & $100 Paketine +5K Karşılaştırma Jetonu!",
    DE: "+2.000 Vergleichsmünzen für 50 $ und +5.000 Münzen für 100 $",
  },
  {
    id: "Our Partners",
    EN: "Our Partners",
    TR: "Partnerlerimiz",
    DE: "Unsere Partner",
  },
  {
    id: "How do I configure the marketplace settings?",
    EN: "How do I configure the marketplace settings?",
    TR: "Pazar yeri ayarlarını nasıl yapacağım?",
    DE: "Wie konfiguriere ich die Marktplatzeinstellungen?",
  },
  {
    id: "Personal",
    EN: "Personal",
    TR: "Hesap",
    DE: "Konto",
  },
  {
    id: "Marketplace",
    EN: "Marketplace",
    TR: "Pazar Yeri",
    DE: "Marktplatz",
  },
  {
    id: "Integrations",
    EN: "Integrations",
    TR: "Entegrasyonlar",
    DE: "Integrationen",
  },

  {
    id: "Amazon Consulting Services",
    EN: "Amazon Consulting Services",
    TR: "Amazon Danışmanlık Hizmetleri",
    DE: "Amazon Beratungsdienste",
  },
  {
    id: "Amazon Warehousing Services",
    EN: "Amazon Warehousing Services",
    TR: "Amazon Depolama Hizmetleri",
    DE: "Amazon Lagerdienste",
  },
  {
    id: "Warehousing",
    EN: "Warehousing",
    TR: "Depolama",
    DE: "Lagerung",
  },
  {
    id: "Consulting",
    EN: "Consulting",
    TR: "Danışmanlık",
    DE: "Beratung",
  },
  {
    id: "We understand the challenges of scaling up your warehousing needs. That’s why we offer flexible and cost-effective warehousing solutions tailored to your growing requirements.",
    EN: "We understand the challenges of scaling up your warehousing needs. That’s why we offer flexible and cost-effective warehousing solutions tailored to your growing requirements.",
    TR: "Depolama ihtiyaçlarınızın ölçeklendirilmesiyle ilgili zorlukları anlıyoruz. Bu yüzden, büyüyen ihtiyaçlarınıza göre uygun ve etkin depolama çözümleri sunuyoruz.",
    DE: "Wir verstehen die Herausforderungen beim Skalieren Ihrer Lagerbedürfnisse. Deshalb bieten wir flexible und kostengünstige Lagerlösungen, die auf Ihre wachsenden Anforderungen zugeschnitten sind.",
  },
  {
    id: "Partnering with Amazon experts can help you plan for and hedge against the seasonality and variability that affect online retailers even harder than physical stores.",
    EN: "Partnering with Amazon experts can help you plan for and hedge against the seasonality and variability that affect online retailers even harder than physical stores.",
    TR: "Kepicker ile Amazon'da Satışlarınızı Optimize Edin ve Rekabetin Önünde Olun! Ürünlerini ve bütçeni analiz ettikten sonra hızlı bir şekilde Amazon mağazanızı kurup satış yapacak seviyeye getiriyoruz.",
    DE: "Die Partnerschaft mit Amazon-Experten kann Ihnen helfen, für die Saisonalität und Variabilität zu planen und sich abzusichern, die Online-Händler noch härter treffen als physische Geschäfte.",
  },
  {
    id: "Amazon seller consulting services boost your sales through an in-depth Amazon marketing strategy analysis and customized Amazon management solutions.",
    EN: "Amazon seller consulting services boost your sales through an in-depth Amazon marketing strategy analysis and customized Amazon management solutions.",
    TR: "Kepicker, Amazon satıcılarına yönelik profesyonel danışmanlık hizmetleri sunar ve işinizi büyütmek, optimize etmek ve rekabet avantajı elde etmek konularında size yardımcı olabilecek deneyimli bir ekibe sahiptir.",
    DE: "Amazon-Verkäuferberatungsdienste steigern Ihren Umsatz durch eine umfassende Analyse der Amazon-Marketingstrategie und maßgeschneiderte Amazon-Managementlösungen.",
  },
  {
    id: "Kepicker is the only all-in-one amazon consulting services agency dedicated to growing your brand on the world’s largest marketplace.",
    EN: "Kepicker is the only all-in-one amazon consulting services agency dedicated to growing your brand on the world’s largest marketplace.",
    TR: "Kepicker, Amazon'da e-ihracat yapmak isteyen firmalara profesyonel Amazon danışmanlık çözümleri ile A'dan Z'ye Amazon danışmanlığı hizmetleri vermektedir. Kepicker'ın deneyimli Amazon uzmanları; Amazon üzerinden satış ve kârlılığınızı maksimuma çekerek, sıfırdan başlayıp başarı hikayesi oluşturmanız için sizinle birlikte çalışır.",
    DE: "Kepicker ist die einzige All-in-One-Amazon-Beratungsagentur, die sich der Markenentwicklung auf dem weltweit größten Marktplatz widmet.",
  },
  {
    id: "Looking for Amazon Consultancy Services?",
    EN: "Looking for Amazon Consultancy Services?",
    TR: "Amazon Danışmanlık Hizmetleri mi Arıyorsunuz?",
    DE: "Suchen Sie Amazon-Beratungsdienste?",
  },
  {
    id: "Ready to GROW on Amazon? We are the Amazon Experts!",
    EN: "Ready to GROW on Amazon? We are the Amazon Experts!",
    TR: "Amazon'da BÜYÜMEYE Hazır mısınız? Amazon ile Daha Fazla Satın!",
    DE: "Bereit zum WACHSEN auf Amazon? Wir sind die Amazon-Experten!",
  },

  {
    id: "Amazon Consulting Services | Amazon Seller Experts",
    EN: "Amazon Consulting Services | Amazon Seller Experts",
    TR: "Amazon Danışmanlık Hizmetleri | Amazon Satıcı Uzmanları",
    DE: "Amazon Beratungsdienste | Amazon-Verkäufer-Experten",
  },
  {
    id: "Kepicker is the only all-in-one amazon consulting services agency dedicated to growing your brand on the world’s largest marketplace. The Kepicker mission is to achieve explosive growth for your brand by utilizing advanced operational marketing strategies and a proprietary set of eCommerce tools. The entire Kepicker team is entirely dedicated to raising your brand above the 3 million sellers on the marketplace through constant ingenuity and courageous innovation.",
    EN: "Kepicker is the only all-in-one amazon consulting services agency dedicated to growing your brand on the world’s largest marketplace. The Kepicker mission is to achieve explosive growth for your brand by utilizing advanced operational marketing strategies and a proprietary set of eCommerce tools. The entire Kepicker team is entirely dedicated to raising your brand above the 3 million sellers on the marketplace through constant ingenuity and courageous innovation.",
    TR: "Amazon'da satış yapmak isteyen bir firmasınız ancak firmanızı zarara uğratma riskini almak istemiyor ya da konuyu öğrenmeye ayıracak vakit bulamıyor olabilirsiniz. Amazon'da başarılı olabilmek için ne gerekiyorsa, tüm tecrübe ve bilgimizle; kullandığımız benzersiz araçlar ve uluslararası iş ortaklarımızla sizi bir üst seviyeye taşıyoruz. Ürünlerinizin hangi pazar yerinde ne kadar karla satılabileceğini belirliyor ve ilk ürün satışına kadar yapılması gereken tüm aşamaları uzman ekibimizle size öğreterek yapıyoruz. Size de başlangıçta zorlu süreçlerde boğulmadan, hata yapmadan tamamen hazır bir hesaptan direk satış yaparak global marka olma yolculuğunuzu başlatmış oluyorsunuz.",
    DE: "Kepicker ist die einzige All-in-One-Amazon-Beratungsagentur, die sich der Entwicklung Ihrer Marke auf dem größten Marktplatz der Welt widmet. Die Mission von Kepicker ist es, durch die Nutzung fortgeschrittener operativer Marketingstrategien und eines proprietären Sets von E-Commerce-Tools explosives Wachstum für Ihre Marke zu erreichen. Das gesamte Kepicker-Team ist vollständig darauf ausgerichtet, Ihre Marke durch ständige Einfallsreichtum und mutige Innovation über die 3 Millionen Verkäufer auf dem Marktplatz zu heben.",
  },
  {
    id: "Amazon seller consulting services boost your sales through an in-depth Amazon marketing strategy analysis and customized Amazon management solutions. Kepicker, is your ideal partner in making your Amazon seller strategy reach its full potential. Achieve record-high sales with fine-tuned Amazon selling strategies, proven Amazon digital marketing techniques and Amazon optimization consulting expertise. Kepicker is your strategic partner for driving marketplace growth while eliminating extra work for your team. Contact us to speak with an Amazon consultant.",
    EN: "Amazon seller consulting services boost your sales through an in-depth Amazon marketing strategy analysis and customized Amazon management solutions. Kepicker, is your ideal partner in making your Amazon seller strategy reach its full potential. Achieve record-high sales with fine-tuned Amazon selling strategies, proven Amazon digital marketing techniques and Amazon optimization consulting expertise. Kepicker is your strategic partner for driving marketplace growth while eliminating extra work for your team. Contact us to speak with an Amazon consultant.",
    TR: "Amazon Danışmanlık hizmetimiz ürünlerinizin Amerika, İngiltere, Avrupa ve Kanada mağazalarında satmanız için gerekli tüm sürecin baştan sona yönetilmesi ve size sürdürülebilir bir iş modeli oluşturmayı amaçlamaktadır. Amazon’da satış yaparak kendi markanızı geniş bir kullanıcı kitlesine ulaştırabilirsiniz. Amazon hesap açılışından tüm satış sürecine kadar birebir online danışmanlık ile yanınızdayız. Amazon hesabınız askıya alındığında Amazonun sizden neler istediğini ve Amazonda satarken nelere ihtiyacınız olduğunu biliyoruz. Amazon danışmanlık hizmetimizi aldığınızda sahip olacağınız birçok ayrıcalığın yanı sıra alanında uzman iş ortaklarımızla kişiye özel çözümler üretiyoruz.",
    DE: "Amazon-Verkäuferberatungsdienste steigern Ihren Umsatz durch eine eingehende Analyse der Amazon-Marketingstrategie und maßgeschneiderte Amazon-Managementlösungen. Kepicker ist Ihr idealer Partner, um Ihre Amazon-Verkäuferstrategie voll auszuschöpfen. Erzielen Sie Rekordumsätze mit fein abgestimmten Amazon-Verkaufsstrategien, bewährten Amazon-Digitalmarketingtechniken und Amazon-Optimierungsberatungsexpertise. Kepicker ist Ihr strategischer Partner für das Wachstum auf dem Marktplatz, während zusätzliche Arbeit für Ihr Team eliminiert wird. Kontaktieren Sie uns, um mit einem Amazon-Berater zu sprechen.",
  },

  {
    id: "Amazon Training Solutions",
    EN: "Amazon Training Solutions",
    TR: "Amazon Eğitim Çözümleri",
    DE: "Amazon Schulungslösungen",
  },
  {
    id: "What We Do?",
    EN: "What We Do?",
    TR: "Danışmanlık Çözümlerimiz Nelerdir?",
    DE: "Was machen wir?",
  },
  {
    id: "Amazon Recovery Services",
    EN: "Amazon Recovery Services",
    TR: "Amazon Kurtarma Hizmetleri",
    DE: "Amazon Wiederherstellungsdienste",
  },
  {
    id: "Amazon Account Management",
    EN: "Amazon Account Management",
    TR: "Amazon Hesap Yönetimi",
    DE: "Amazon-Kontoverwaltung",
  },
  {
    id: "Amazon Seller Central Consultants",
    EN: "Amazon Seller Central Consultants",
    TR: "Amazon Satıcı Hesabı Danışmanlığı",
    DE: "Berater für Amazon Seller Central",
  },
  {
    id: "Amazon Vendor Central Consulting",
    EN: "Amazon Vendor Central Consulting",
    TR: "Amazon Vendor Central Danışmanlığı",
    DE: "Amazon Vendor Central Beratung",
  },
  {
    id: "Don’t let unnoticed errors and deductions eat into your hard-earned profits and unravel your progress and peace of mind.",
    EN: "Don’t let unnoticed errors and deductions eat into your hard-earned profits and unravel your progress and peace of mind.",
    TR: "Fark edilmeyen hataların ve kesintilerin zor kazanılmış karlarınızı yemesine ve ilerlemenizi ve huzurunuzu bozmasına izin vermeyin.",
    DE: "Lassen Sie nicht zu, dass unbemerkte Fehler und Abzüge Ihre hart verdienten Gewinne auffressen und Ihren Fortschritt und Ihre Ruhe stören.",
  },
  {
    id: "If your established business has the inventory and budget to sell on Amazon, but you haven’t yet begun, then you’re in the right place.",
    EN: "If your established business has the inventory and budget to sell on Amazon, but you haven’t yet begun, then you’re in the right place.",
    TR: "Kuruluşunuz Amazon'da satış yapacak envanter ve bütçeye sahipse, ancak henüz başlamadıysanız, doğru yerdesiniz.",
    DE: "Wenn Ihr etabliertes Geschäft das Inventar und Budget hat, um auf Amazon zu verkaufen, aber noch nicht begonnen hat, dann sind Sie hier genau richtig.",
  },
  {
    id: "Are you a business owner looking to sell on Amazon? Do you want to maximize your success on the world’s largest online marketplace? Look no further!",
    EN: "Are you a business owner looking to sell on Amazon? Do you want to maximize your success on the world’s largest online marketplace? Look no further!",
    TR: "Amazon'da satış yapmak isteyen bir işletme sahibi misiniz? Dünyanın en büyük çevrimiçi pazar yerinde başarınızı en üst düzeye çıkarmak ister misiniz? Bize Ulaşın!",
    DE: "Sind Sie ein Geschäftsinhaber, der auf Amazon verkaufen möchte? Möchten Sie Ihren Erfolg auf dem größten Online-Marktplatz der Welt maximieren? Suchen Sie nicht weiter!",
  },
  {
    id: "With the knowledge and experience to help our clients prosper on the largest online marketplace, our ecommerce consulting team develops profitable Amazon vendor account strategies tailored to your unique needs and goals.",
    EN: "With the knowledge and experience to help our clients prosper on the largest online marketplace, our ecommerce consulting team develops profitable Amazon vendor account strategies tailored to your unique needs and goals.",
    TR: "Müşterilerimizin dünyanın en büyük çevrimiçi pazar yerinde başarılı olmalarına yardımcı olacak bilgi ve deneyime sahip olan e-ticaret danışmanlık ekibimiz, benzersiz ihtiyaçlarınıza ve hedeflerinize uygun karlı Amazon satıcı hesabı stratejileri geliştirir.",
    DE: "Mit dem Wissen und der Erfahrung, um unseren Kunden auf dem größten Online-Marktplatz zu helfen, erfolgreich zu sein, entwickelt unser E-Commerce-Beratungsteam profitable Amazon-Verkäuferkontenstrategien, die auf Ihre einzigartigen Bedürfnisse und Ziele zugeschnitten sind.",
  },
  {
    id: "As your business continues to expand, finding the right space to store your inventory efficiently can be a game-changer.",
    EN: "As your business continues to expand, finding the right space to store your inventory efficiently can be a game-changer.",
    TR: "İşletmeniz büyümeye devam ederken, envanterinizi verimli bir şekilde saklayacak doğru alanı bulmak oyunun kurallarını değiştirebilir.",
    DE: "Wenn Ihr Geschäft weiter wächst, kann das Finden des richtigen Platzes zur effizienten Lagerung Ihrer Bestände ein Gamechanger sein.",
  },
  {
    id: "Amazon Storage and Warehousing Services for Ecommerce",
    EN: "Amazon Storage and Warehousing Services for Ecommerce",
    TR: "E-Ticaret Sektörüne Yönelik Amazon Depolama Hizmetleri",
    DE: "Amazon Lager- und Lagerdienste für E-Commerce",
  },
  {
    id: "Kepicker – your partner for Amazon warehousing and distribution of products in ecommerce. We specialize in storing your inventory in modern fulfillment warehouses and ensure flawless logistics operations.",
    EN: "Kepicker – your partner for Amazon warehousing and distribution of products in ecommerce. We specialize in storing your inventory in modern fulfillment warehouses and ensure flawless logistics operations.",
    TR: "Kepicker – e-ticarette Amazon depolama ve ürün dağıtımı konusunda güvenilir çözüm ortağınız. Envanterinizi modern fulfillment depolarında saklamakta tecrübeliyiz ve kusursuz lojistik operasyonları sağlarız.",
    DE: "Kepicker – Ihr Partner für Amazon-Lagerung und -Distribution von Produkten im E-Commerce. Wir sind spezialisiert auf die Lagerung Ihrer Bestände in modernen Fulfillment-Lagern und gewährleisten einwandfreie Logistikoperationen.",
  },

  {
    id: "Kepicker teaches Amazon sellers how to set up a successful marketplace account and create top-notch product listings, all while following Amazon’s specific guidelines and rules. Our Amazon seller training program is ideal for the company that has the time and employees to oversee its account daily but requires straightforward, step-by-step guidance to set up and maintain its product listings. Knowing that Amazon is always evolving, our experts provide accurate, timely and reliable information as it pertains to what Amazon expects from sellers today.",
    EN: "Kepicker teaches Amazon sellers how to set up a successful marketplace account and create top-notch product listings, all while following Amazon’s specific guidelines and rules. Our Amazon seller training program is ideal for the company that has the time and employees to oversee its account daily but requires straightforward, step-by-step guidance to set up and maintain its product listings. Knowing that Amazon is always evolving, our experts provide accurate, timely and reliable information as it pertains to what Amazon expects from sellers today.",
    TR: "Kepicker, Amazon satıcılarına başarılı bir pazar yeri hesabının nasıl kurulacağını ve Amazon'un belirli kurallarını ve yönergelerini takip ederken mükemmel ürün listelerinin nasıl oluşturulacağını öğretir. Amazon satıcı eğitim programımız, hesabını günlük olarak denetleyecek zamanı ve çalışanları olan ancak ürün listelerini kurmak ve sürdürmek için adım adım açık yönlendirmeler gerektiren şirketler için idealdir. Amazon'un sürekli olarak geliştiğini bilen uzmanlarımız, Amazon'un satıcılardan bugün ne beklediği konusunda doğru, zamanında ve güvenilir bilgiler sağlar.",
    DE: "Kepicker lehrt Amazon-Verkäufer, wie man ein erfolgreiches Marktplatzkonto einrichtet und erstklassige Produktlisten erstellt, während man sich an die spezifischen Richtlinien und Regeln von Amazon hält. Unser Amazon-Verkäufer-Trainingsprogramm ist ideal für Unternehmen, die Zeit und Mitarbeiter haben, ihr Konto täglich zu überwachen, aber eine einfache, schrittweise Anleitung benötigen, um ihre Produktlisten einzurichten und zu pflegen. Da Amazon sich ständig weiterentwickelt, bieten unsere Experten genaue, zeitnahe und zuverlässige Informationen darüber, was Amazon heute von Verkäufern erwartet.",
  },
  {
    id: "At Kepicker, our dedicated Amazon experts have been providing sell-on-Amazon training for more than a decade. Corporate Amazon seller training is vital for businesses that wish to manage their account in-house. While free tutorials and videos are available, they become outdated quickly as the marketplace evolves. Very few dive into all the intricacies of selling on Amazon, and none are as relevant as an Amazon seller training program geared to your unique business.",
    EN: "At Kepicker, our dedicated Amazon experts have been providing sell-on-Amazon training for more than a decade. Corporate Amazon seller training is vital for businesses that wish to manage their account in-house. While free tutorials and videos are available, they become outdated quickly as the marketplace evolves. Very few dive into all the intricacies of selling on Amazon, and none are as relevant as an Amazon seller training program geared to your unique business.",
    TR: "Amazon uzmanlarımız uzun süredir Amazon'da satış eğitimi sağlamaktadır. Kurumsal Amazon satıcı eğitimlerimiz, hesaplarını kendi bünyelerinde yönetmek isteyen işletmeler için hayati önem taşır. Ücretsiz dersler ve videolar mevcut olsa da, pazar yeri geliştikçe hızla güncelliğini yitirir. Amazon'da satış yapmanın tüm karmaşıklıklarına dalan çok az kaynak vardır ve hiçbiri tamamen işletmenize yönelik bir Amazon satıcı eğitim programı kadar etkili değildir.",
    DE: "Bei Kepicker bieten unsere engagierten Amazon-Experten seit mehr als einem Jahrzehnt Schulungen für den Verkauf auf Amazon an. Unternehmensinterne Amazon-Verkäufertrainings sind für Unternehmen unerlässlich, die ihr Konto intern verwalten möchten. Obwohl kostenlose Tutorials und Videos verfügbar sind, veralten sie schnell, da sich der Marktplatz weiterentwickelt. Nur sehr wenige gehen auf alle Feinheiten des Verkaufs auf Amazon ein, und keines ist so relevant wie ein auf Ihr einzigartiges Unternehmen zugeschnittenes Amazon-Verkäufertrainingsprogramm.",
  },

  {
    id: "Flexible Warehousing Solutions - We'll Handle Storage!",
    EN: "Flexible Warehousing Solutions - We'll Handle Storage!",
    TR: "Esnek Depolama Çözümleri - Depolamayı Biz Üstleniyoruz!",
    DE: "Flexible Lagerlösungen - Wir kümmern uns um die Lagerung!",
  },
  {
    id: "Looking for trusted ecommerce warehousing and storage services? Don’t let haphazard storage slow you down. Strategic warehousing minimizes order processing times and prevents inventory shrinkage. However, managing it yourself requires expertise and resources. At Kepicker, we’re committed to helping your business thrive. By choosing our warehousing solutions, you can focus on what you do best—growing your brand and serving your customers—while we take care of your inventory storage needs.",
    EN: "Looking for trusted ecommerce warehousing and storage services? Don’t let haphazard storage slow you down. Strategic warehousing minimizes order processing times and prevents inventory shrinkage. However, managing it yourself requires expertise and resources. At Kepicker, we’re committed to helping your business thrive. By choosing our warehousing solutions, you can focus on what you do best — growing your brand and serving your customers — while we take care of your inventory storage needs.",
    TR: "Güvenilir e-ticaret depolama ve saklama hizmetleri mi arıyorsunuz? Plansız depolama sizi yavaşlatmasın. Stratejik depolama, sipariş işleme sürelerini en aza indirir ve stok daralmalarını önler. Ancak bunu kendiniz yönetmek isterseniz de bu süreç uzmanlık ve kaynak gerektirir. Kepicker olarak işletmenizin gelişmesine yardımcı olmaya kararlıyız. Depolama çözümlerimizi seçerek, en iyi yaptığınız şeye odaklanabilirsiniz — markanızı büyütmek ve müşterilerinize hizmet vermek — biz ise envanter depolama ihtiyaçlarınızla ilgileniriz.",
    DE: "Suchen Sie nach vertrauenswürdigen E-Commerce-Lager- und Speicherdiensten? Lassen Sie sich nicht durch zufällige Lagerung ausbremsen. Strategische Lagerung minimiert die Bearbeitungszeiten von Bestellungen und verhindert Lagerbestandsverluste. Die Verwaltung selbst erfordert jedoch Fachkenntnisse und Ressourcen. Bei Kepicker sind wir darauf ausgerichtet, Ihrem Unternehmen zum Erfolg zu verhelfen. Indem Sie unsere Lagerlösungen wählen, können Sie sich auf das konzentrieren, was Sie am besten können - Ihre Marke wachsen lassen und Ihre Kunden bedienen - während wir uns um Ihre Lagerbedürfnisse kümmern.",
  },
  {
    id: "Ready to unlock the potential of flexible warehousing?",
    EN: "Ready to unlock the potential of flexible warehousing?",
    TR: "Esnek depolamanın potansiyelini açmaya hazır mısınız?",
    DE: "Bereit, das Potenzial flexibler Lagerung zu erschließen?",
  },
  {
    id: "Amazon Fulfillment Services (FBA) - What Is Warehousing?",
    EN: "Amazon Fulfillment Services (FBA) - What Is Warehousing?",
    TR: "Amazon Fulfillment Hizmetleri (FBA) - Depolama Nedir?",
    DE: "Amazon Fulfillment-Dienste (FBA) - Was ist Lagerung?",
  },
  {
    id: "Need advice on choosing a fulfillment warehouse?",
    EN: "Need advice on choosing a fulfillment warehouse?",
    TR: "Bir fulfillment deposu seçme konusunda tavsiye mi gerekiyor?",
    DE: "Benötigen Sie Beratung bei der Auswahl eines Fulfillment-Lagers?",
  },
  {
    id: "In the simplest and shortest explanation, warehousing refers to the physical storage of products before they are ordered and shipped. Warehouses are places where products are put for distribution. In order for the trade to continue, it is very important that the products are kept safely in the warehouses. Amazon fulfillment FBA or fulfillment by Amazon is an option offered by Amazon to its 3rd party sellers to facilitate storage, packing and shipping of products sold on the Amazon market place alongside their own Amazon cargo hub via the use of Fulfillment Centres. If you currently sell or are looking to sell on Amazon as a 3rd party seller, whether you will be using fulfilment by Amazon (FBA) centres or your own in-house delivery service, we’re here to help.",
    EN: "In the simplest and shortest explanation, warehousing refers to the physical storage of products before they are ordered and shipped. Warehouses are places where products are put for distribution. In order for the trade to continue, it is very important that the products are kept safely in the warehouses. Amazon fulfillment FBA or fulfillment by Amazon is an option offered by Amazon to its 3rd party sellers to facilitate storage, packing and shipping of products sold on the Amazon market place alongside their own Amazon cargo hub via the use of Fulfillment Centres. If you currently sell or are looking to sell on Amazon as a 3rd party seller, whether you will be using fulfilment by Amazon (FBA) centres or your own in-house delivery service, we’re here to help.",
    TR: "En basit ve en kısa açıklama ile depolama, ürünler sipariş edilip gönderilmeden önce fiziksel olarak saklanmasını ifade eder. Depolar, ürünlerin dağıtım için konulduğu yerlerdir. Ticaretin devam edebilmesi için, ürünlerin depolarda güvenle saklanması çok önemlidir. Amazon fulfillment FBA veya Amazon tarafından sağlanan fulfillment, Amazon'un üçüncü parti satıcılarına, Amazon pazar yerinde satılan ürünlerin depolanmasını, paketlenmesini ve Amazon kargo merkezi aracılığıyla kendi Fulfillment Merkezleri kullanılarak gönderilmesini kolaylaştırmak için sunulan bir seçenektir. Şu anda Amazon'da üçüncü parti bir satıcı olarak satış yapıyor veya yapmayı düşünüyorsanız, Amazon'un (FBA) fulfillment merkezlerini veya kendi iç teslimat hizmetinizi kullanıp kullanmayacağınıza bakılmaksızın, size yardımcı olmaya hazırız.",
    DE: "In der einfachsten und kürzesten Erklärung bezieht sich Lagerung auf die physische Aufbewahrung von Produkten, bevor sie bestellt und versendet werden. Lager sind Orte, an denen Produkte zur Verteilung abgelegt werden. Damit der Handel fortgesetzt werden kann, ist es sehr wichtig, dass die Produkte sicher in den Lagern aufbewahrt werden. Amazon Fulfillment FBA oder Fulfillment durch Amazon ist eine Option, die Amazon seinen Drittanbietersellern bietet, um die Lagerung, Verpackung und den Versand von Produkten, die auf dem Amazon-Marktplatz verkauft werden, zu erleichtern, neben ihrem eigenen Amazon-Frachthub über die Nutzung von Fulfillment-Zentren. Wenn Sie derzeit auf Amazon als Drittanbieter verkaufen oder dies beabsichtigen, egal ob Sie Amazon-Fulfillment-Zentren (FBA) oder Ihren eigenen internen Lieferservice nutzen werden, sind wir hier, um Ihnen zu helfen.",
  },
  {
    id: "As an Amazon seller, you know that the success of your business depends on fast, reliable, and cost-effective logistics solutions. That’s where Kepicker comes in. We’re a third-party logistics provider (3PL) dedicated to helping Amazon sellers streamline their operations and optimize their supply chain management. From warehousing and fulfillment services to freight and transportation solutions, our services cover the entire logistics spectrum for Amazon sellers. We’re here for you if you have any questions. Every customer is offered a free initial assessment because we’re not just looking for paying customers, we’re looking for growing partners.",
    EN: "As an Amazon seller, you know that the success of your business depends on fast, reliable, and cost-effective logistics solutions. That’s where Kepicker comes in. We’re a third-party logistics provider (3PL) dedicated to helping Amazon sellers streamline their operations and optimize their supply chain management. From warehousing and fulfillment services to freight and transportation solutions, our services cover the entire logistics spectrum for Amazon sellers. We’re here for you if you have any questions. Every customer is offered a free initial assessment because we’re not just looking for paying customers, we’re looking for growing partners.",
    TR: "Amazon satıcısı olarak, işletmenizin başarısının hızlı, güvenilir ve maliyet etkin lojistik çözümlere bağlı olduğunu biliyorsunuz. İşte bu noktada Kepicker devreye giriyor. Amazon satıcılarının operasyonlarını düzene sokmalarına ve tedarik zinciri yönetimlerini optimize etmelerine yardımcı olmaya adanmış bir üçüncü parti lojistik sağlayıcı (3PL) olan Kepicker ile tanışın. Depolama ve yerine getirme hizmetlerinden kargo ve taşıma çözümlerine kadar, hizmetlerimiz Amazon satıcıları için tüm lojistik spektrumunu kapsar. Herhangi bir sorunuz olursa buradayız. Her müşteriye, sadece ödeme yapan müşteriler değil, büyüyen ortaklar aradığımız için ücretsiz bir başlangıç değerlendirmesi sunulmaktadır.",
    DE: "Als Amazon-Verkäufer wissen Sie, dass der Erfolg Ihres Geschäfts von schnellen, zuverlässigen und kostengünstigen Logistiklösungen abhängt. Hier kommt Kepicker ins Spiel. Wir sind ein Drittanbieter-Logistikdienstleister (3PL), der darauf spezialisiert ist, Amazon-Verkäufern zu helfen, ihre Abläufe zu straffen und ihr Supply-Chain-Management zu optimieren. Von Lager- und Erfüllungsdiensten bis hin zu Fracht- und Transportlösungen decken unsere Dienstleistungen das gesamte Logistikspektrum für Amazon-Verkäufer ab. Wir sind hier, um Ihnen bei Fragen zur Seite zu stehen. Jeder Kunde erhält eine kostenlose Erstbewertung, denn wir suchen nicht nur nach zahlenden Kunden, sondern nach wachsenden Partnern.",
  },
  {
    id: "Streamline Your Supply Chain by Partnering with Kepicker - Optimize your eCommerce operations with our streamlined solutions. We can be your great business partner and help you growth strongly. Storage is something we do for all our customers with no space limits. As businesses grow, they need more space. However, renting a warehouse can be much more costly than you think. Moreover, you do not have a margin of flexibility against sudden growth. Kepicker is here for all your storage needs.",
    EN: "Streamline Your Supply Chain by Partnering with Kepicker - Optimize your eCommerce operations with our streamlined solutions. We can be your great business partner and help you growth strongly. Storage is something we do for all our customers with no space limits. As businesses grow, they need more space. However, renting a warehouse can be much more costly than you think. Moreover, you do not have a margin of flexibility against sudden growth. Kepicker is here for all your storage needs.",
    TR: "Tedarik Zincirinizi Kepicker ile İşbirliği Yaparak Düzenleyin - E-ticaret operasyonlarınızı optimize etmek için akıcı çözümlerimizle. Mükemmel iş ortağınız olabilir ve güçlü bir şekilde büyümenize yardımcı olabiliriz. Depolama, tüm müşterilerimiz için sınırsız alanla yaptığımız bir şeydir. İşletmeler büyüdükçe daha fazla alana ihtiyaç duyarlar. Ancak, bir depo kiralamak düşündüğünüzden çok daha maliyetli olabilir. Ayrıca, ani büyümelere karşı esneklik payınız yoktur. Kepicker, tüm depolama ihtiyaçlarınız için burada.",
    DE: "Optimieren Sie Ihre Lieferkette durch eine Partnerschaft mit Kepicker - Optimieren Sie Ihre E-Commerce-Operationen mit unseren effizienten Lösungen. Wir können Ihr großartiger Geschäftspartner sein und Ihnen ein starkes Wachstum helfen. Lagerung ist etwas, das wir für alle unsere Kunden ohne Platzlimits machen. Wenn Unternehmen wachsen, benötigen sie mehr Platz. Das Mieten eines Lagers kann jedoch viel kostspieliger sein, als Sie denken. Darüber hinaus haben Sie keine Flexibilitätsmarge gegenüber plötzlichem Wachstum. Kepicker ist hier für alle Ihre Lagerbedürfnisse.",
  },
  {
    id: "Select File Here.",
    EN: "Select File Here.",
    TR: "'Dosya Seç'e Tıklayın.",
    DE: "Datei hier auswählen.",
  },
  {
    id: "Files Supported: .XLSX",
    EN: "Files Supported: .XLSX",
    TR: "Desteklenen Dosyalar: .XLSX",
    DE: "Unterstützte DateiEN: .XLSX",
  },
  {
    id: "Choose File",
    EN: "Choose File",
    TR: "Dosya Seç",
    DE: "Datei auswählen",
  },

  {
    id: "The product has been removed from favorites!",
    EN: "The product has been removed from favorites!",
    TR: "Ürün favorilerden kaldırıldı!",
    DE: "Das Produkt wurde aus den Favoriten entfernt!",
  },
  {
    id: "The product has been added to favorites!",
    EN: "The product has been added to favorites!",
    TR: "Ürün favorilere eklendi!",
    DE: "Das Produkt wurde zu den Favoriten hinzugefügt!",
  },
  {
    id: "Product Note",
    EN: "Product Note",
    TR: "Ürün Notu",
    DE: "Produktnotiz",
  },
  {
    id: "Remove Note!",
    EN: "Remove Note!",
    TR: "Notu Sil!",
    DE: "Notiz entfernen!",
  },
  {
    id: "Write your note here:",
    EN: "Write your note here:",
    TR: "Notunuzu buraya yazın:",
    DE: "Schreiben Sie hier Ihre Notiz:",
  },
  {
    id: "Add a note..",
    EN: "Add a note..",
    TR: "Not ekleyin..",
    DE: "Eine Notiz hinzufügen..",
  },
  {
    id: "Note cannot be empty!",
    EN: "Note cannot be empty!",
    TR: "Not boş olamaz!",
    DE: "Notiz darf nicht leer sein!",
  },
  {
    id: "Note applied successfully!",
    EN: "Note applied successfully!",
    TR: "Not başarıyla uygulandı!",
    DE: "Notiz erfolgreich angewendet!",
  },
  {
    id: "Note deleted successfully!",
    EN: "Note deleted successfully!",
    TR: "Not başarıyla silindi!",
    DE: "Notiz erfolgreich gelöscht!",
  },
  {
    id: "Select which Google Sheet it should be sent to.",
    EN: "Select which Google Sheet it should be sent to.",
    TR: "Hangi Google Sheets'e gönderileceğini seçin.",
    DE: "Wählen Sie, an welches Google Sheet es gesendet werden soll.",
  },
  {
    id: "Add to Google Sheet",
    EN: "Add to Google Sheet",
    TR: "Google Sheets'e Ekle",
    DE: "Zu Google Sheet hinzufügen",
  },
  {
    id: "Asin added to Google Sheet successfully!",
    EN: "Asin added to Google Sheet successfully!",
    TR: "Asin Google Sheets'e başarıyla gönderildi!",
    DE: "Asin erfolgreich zu Google Sheet hinzugefügt!",
  },
  {
    id: "Add New Google Sheet",
    EN: "Add New Google Sheet",
    TR: "Yeni Google Sheets Ekle",
    DE: "Neues Google Sheet hinzufügen",
  },
  {
    id: "Import - Export Fees",
    EN: "Import - Export Fees",
    TR: "İthalat - İhracat Ücretleri",
    DE: "Import-Export-Gebühren",
  },
  {
    id: "Import Fee",
    EN: "Import Fee",
    TR: "İthalat Ücreti",
    DE: "Importgebühr",
  },
  {
    id: "Export Fee",
    EN: "Export Fee",
    TR: "İhracat Ücreti",
    DE: "Exportgebühr",
  },

  {
    id: "Current Setting",
    EN: "Current Setting",
    TR: "Mevcut Ayar",
    DE: "Aktuelle Einstellung",
  },
  {
    id: "You can see all the products you have favorited with the plugin here.",
    EN: "You can see all the products you have favorited with the plugin here.",
    TR: "Eklenti ile favorilere eklediğiniz tüm ürünleri burada görebilirsiniz.",
    DE: "Sie können hier alle Produkte sehen, die Sie mit dem Plugin zu Ihren Favoriten hinzugefügt haben.",
  },
  {
    id: "Favorites",
    EN: "Favorites",
    TR: "Favoriler",
    DE: "Favoriten",
  },
  {
    id: "Favorite Products",
    EN: "Favorite Products",
    TR: "Favori Ürünler",
    DE: "Lieblingsprodukte",
  },
  {
    id: "Add to Favorites",
    EN: "Add to Favorites",
    TR: "Favorilere Ekle",
    DE: "Zu Favoriten hinzufügen",
  },
  {
    id: "Monthly Sold",
    EN: "Monthly Sold",
    TR: "Aylık Satış",
    DE: "Monatlich verkauft",
  },
  {
    id: "This data comes from Keepa!",
    EN: "This data comes from Keepa!",
    TR: "Bu veri Keepa'dan geliyor!",
    DE: "Diese Daten stammen von Keepa!",
  },
  {
    id: "Private Label - Indicates that the seller with the icon is Private Label.",
    EN: "Private Label - Indicates that the seller with the icon is Private Label.",
    TR: "Private Label - Simgeye sahip satıcının Private Label olduğunu belirtir.",
    DE: "Private Label - Gibt an, dass der Verkäufer mit dem Symbol ein Private Label ist.",
  },
  {
    id: "Amazon Sells too!",
    EN: "Amazon Sells too!",
    TR: "Amazon da Satıyor!",
    DE: "Amazon verkauft auch!",
  },
  {
    id: "High Competition!",
    EN: "High Competition!",
    TR: "Yüksek Rekabet!",
    DE: "Hohe Konkurrenz!",
  },
  {
    id: "There is High Competition for This Product!",
    EN: "There is High Competition for This Product!",
    TR: "Bu Üründe Yüksek Rekabet Var!",
    DE: "Für dieses Produkt gibt es eine hohe Konkurrenz!",
  },
  {
    id: "One of the Sellers of This Product is Amazon!",
    EN: "One of the Sellers of This Product is Amazon!",
    TR: "Bu Ürünün Satıcılarından Biri Amazon!",
    DE: "Einer der Verkäufer dieses Produkts ist Amazon!",
  },
  {
    id: "Is Amazon Selling?",
    EN: "Is Amazon Selling?",
    TR: "Amazon satıyor mu?",
    DE: "Verkauft Amazon?",
  },
  {
    id: "Change table name:",
    EN: "Change table name:",
    TR: "Tablo adını değiştirin:",
    DE: "Tabellenname ändern:",
  },
  {
    id: "Table column name..",
    EN: "Table column name..",
    TR: "Tablo sütun adı..",
    DE: "Tabellenspaltenname..",
  },
  {
    id: "Table Name & Note",
    EN: "Table Name & Note",
    TR: "Tablo Adı ve Not",
    DE: "Tabellenname & Notiz",
  },
  {
    id: "Your Daily Usage Limit Has Been Reached! Please wait until the next day to use it again..",
    EN: "Your Daily Usage Limit Has Been Reached! Please wait until the next day to use it again..",
    TR: "Günlük Kullanım Limitiniz Doldu! Tekrar kullanmak için lütfen bir sonraki günü bekleyiniz..",
    DE: "Ihr tägliches Nutzungslimit ist erreicht! Bitte warten Sie bis zum nächsten Tag, um es erneut zu verwenden..",
  },
  {
    id: "Sorry, Daily Limit Reached!",
    EN: "Sorry, Daily Limit Reached!",
    TR: "Üzgünüz, Günlük Limitiniz Doldu!",
    DE: "Entschuldigung, tägliches Limit erreicht!",
  },
  {
    id: "Sorry, You're Out of Tokens! Upgrade Your Package or buy extra Tokens.",
    EN: "Sorry, You're Out of Tokens! Upgrade Your Package or buy extra Tokens.",
    TR: "Üzgünüz, Jetonlarınız Tükendi! Paketinizi Yükseltin veya Ekstra Jeton Satın Alın.",
    DE: "Entschuldigung, Sie haben keine Tokens mehr! Upgraden Sie Ihr Paket oder kaufen Sie zusätzliche Tokens.",
  },
  {
    id: "History",
    EN: "History",
    TR: "Geçmiş",
    DE: "Geschichte",
  },
  {
    id: "You can see all the products you have previously viewed here.",
    EN: "You can see all the products you have previously viewed here.",
    TR: "Daha önce görüntülediğiniz tüm ürünleri buradan görebilirsiniz.",
    DE: "Hier können Sie alle Produkte sehen, die Sie sich zuvor angesehen haben.",
  },

  {
    id: "Buybox Information",
    EN: "Buybox Information",
    TR: "Buybox Bilgileri",
    DE: "Buybox-Informationen",
  },
  {
    id: "Buybox MIN. for Kepicker Calculator.",
    EN: "Buybox MIN. for Kepicker Calculator.",
    TR: "Kepicker Hesaplayıcı için Buybox MİN.",
    DE: "Buybox MIN. für den Kepicker-Rechner.",
  },
  {
    id: "Buybox MAX. for Kepicker Calculator.",
    EN: "Buybox MAX. for Kepicker Calculator.",
    TR: "Kepicker Hesaplayıcı için Buybox MAX.",
    DE: "Buybox MAX. für den Kepicker-Rechner.",
  },
  {
    id: "Buybox AVG. for Kepicker Calculator.",
    EN: "Buybox AVG. for Kepicker Calculator.",
    TR: "Kepicker Hesaplayıcı için Buybox ORT.",
    DE: "Buybox AVG. für den Kepicker-Rechner.",
  },
  {
    id: "Extra Buybox Price for Keepa Data",
    EN: "Extra Buybox Price for Keepa Data",
    TR: "Keepa Verileri için Ekstra Buybox Fiyatı",
    DE: "Zusätzlicher Buybox-Preis für Keepa-Daten",
  },
  {
    id: "30/60/90 Days Seller Buybox Percentage Information",
    EN: "30/60/90 Days Seller Buybox Percentage Information",
    TR: "30/60/90 Günlük Satıcı Buybox Yüzdesi Bilgileri",
    DE: "30/60/90 Tage Verkäufer-Buybox-Prozent-Informationen",
  },
  {
    id: "Add Product to Favorites",
    EN: "Add Product to Favorites",
    TR: "Ürünü Favorilere Ekle",
    DE: "Produkt zu Favoriten hinzufügen",
  },
  {
    id: "Take Notes About the Product",
    EN: "Take Notes About the Product",
    TR: "Ürün Hakkında Not Alın",
    DE: "Notizen zum Produkt machen",
  },
  {
    id: "My Product History",
    EN: "My Product History",
    TR: "Ürün Geçmişim",
    DE: "Meine Produktgeschichte",
  },
  {
    id: "Product Name",
    EN: "Product Name",
    TR: "Ürün Adı",
    DE: "Produktname",
  },
  {
    id: "Image",
    EN: "Image",
    TR: "Resim",
    DE: "Bild",
  },
  {
    id: "After upgrading your plan, in the extension; Update your plugin by clicking the Settings → Other Settings (At the bottom) → Synchronize button.",
    EN: "After upgrading your plan, in the extension; Update your plugin by clicking the Settings → Other Settings (At the bottom) → Synchronize button.",
    TR: "Paketinizi yükselttikten sonra eklentide; Ayarlar → Diğer Ayarlar (En altta) → Senkronize Et butonuna tıklayarak eklentinizi güncelleyiniz.",
    DE: "Nach dem Upgrade Ihres Pakets im Add-on; Aktualisieren Sie Ihr Plugin, indem Sie auf die Schaltfläche Einstellungen → Andere Einstellungen (unten) → Synchronisieren klicken.",
  },

  {
    id: "Barcode Reader",
    EN: "Barcode Reader",
    TR: "Barkod Tarayıcı",
    DE: "Barcode-Scanner",
  },
  {
    id: "Scan the barcode with your camera by pressing the barcode reading button.",
    EN: "Scan the barcode with your camera by pressing the barcode reading button.",
    TR: "Barkod tarama butonuna basarak kameranızla barkodu tarayınız.",
    DE: "Scannen Sie den Barcode mit Ihrer Kamera, indem Sie die Barcode-Lesetaste drücken.",
  },
  {
    id: "Price & Currency",
    EN: "Price & Currency",
    TR: "Fiyat & Para Birimi",
    DE: "Preis & Währung",
  },
  {
    id: "Currency",
    EN: "Currency",
    TR: "Para Birimi",
    DE: "Währung",
  },
  {
    id: "Product Selection",
    EN: "Product Selection",
    TR: "Ürün Seçimi",
    DE: "Produktauswahl",
  },

  {
    id: "Manual Calculation",
    EN: "Manual Calculation",
    TR: "Manuel Hesaplama",
    DE: "Manuelle Berechnung",
  },
  {
    id: "Buying Price",
    EN: "Buying Price",
    TR: "Alış Fiyatı",
    DE: "Kaufpreis",
  },
  {
    id: "Buy Costs",
    EN: "Buy Costs",
    TR: "Alım Maliyetleri",
    DE: "Kaufkosten",
  },
  {
    id: "Selling Price",
    EN: "Selling Price",
    TR: "Satış Fiyatı",
    DE: "Verkaufspreis",
  },
  {
    id: "Marketplace Settings",
    EN: "Marketplace Settings",
    TR: "Pazar Yeri Ayarları",
    DE: "Marktplatz-Einstellungen",
  },
  {
    id: "Marketplace for Setting",
    EN: "Marketplace for Setting",
    TR: "Ayarlar İçin Pazar Yeri",
    DE: "Marktplatz für Einstellungen",
  },
  {
    id: "Manual Calculation Settings",
    EN: "Manual Calculation Settings",
    TR: "Manuel Hesaplama Ayarları",
    DE: "Manuelle Berechnungseinstellungen",
  },
  {
    id: "Fetch Data",
    EN: "Fetch Data",
    TR: "Verileri Getir",
    DE: "Daten abrufen",
  },
  {
    id: "BSR Drops",
    EN: "BSR Drops",
    TR: "BSR Düşüşleri",
    DE: "BSR-Abfälle",
  },
  {
    id: "Also Calculate Other Marketplaces",
    EN: "Also Calculate Other Marketplaces",
    TR: "Diğer Pazar Yerlerini de Hesapla",
    DE: "Auch andere Marktplätze berechnen",
  },
  {
    id: "Add / Remove Marketplaces",
    EN: "Add / Remove Marketplaces",
    TR: "Pazar Yerleri Ekle / Kaldır",
    DE: "Marktplätze hinzufügen / entfernen",
  },
  {
    id: "Click Here to Add..",
    EN: "Click Here to Add..",
    TR: "Eklemek İçin Buraya Tıklayın..",
    DE: "Hier klicken, um hinzuzufügen..",
  },
  {
    id: "Quickly calculate other marketplaces that come with your connected account.",
    EN: "Quickly calculate other marketplaces that come with your connected account.",
    TR: "Bağlı hesabınızla gelen diğer pazar yerlerini hızlıca hesaplayın.",
    DE: "Berechnen Sie schnell andere Marktplätze, die mit Ihrem verbundenen Konto kommen.",
  },
  {
    id: "Marketplace Selections",
    EN: "Marketplace Selections",
    TR: "Pazar Yeri Seçimleri",
    DE: "Marktplatz-Auswahlen",
  },
  {
    id: "Your marketplaces that you can use for your connected account.",
    EN: "Your marketplaces that you can use for your connected account.",
    TR: "Bağlı hesabınız için kullanabileceğiniz pazar yerleri.",
    DE: "Ihre Marktplätze, die Sie für Ihr verbundenes Konto verwenden können.",
  },

  {
    id: "Scan the barcode with your camera by pressing the barcode reading button.",
    EN: "Scan the barcode with your camera by pressing the barcode reading button.",
    TR: "Barkod okuma butonuna basarak kameranızla barkodu tarayın.",
    DE: "Scannen Sie den Barcode mit Ihrer Kamera, indem Sie die Barcode-Lesetaste drücken.",
  },
  {
    id: "Enter the price of the product and select the currency.",
    EN: "Enter the price of the product and select the currency.",
    TR: "Ürünün fiyatını girin ve para birimini seçin.",
    DE: "Geben Sie den Preis des Produkts ein und wählen Sie die Währung aus.",
  },
  {
    id: "Click on the product whose results you want to view.",
    EN: "Click on the product whose results you want to view.",
    TR: "Sonuçlarını görmek istediğiniz ürüne tıklayın.",
    DE: "Klicken Sie auf das Produkt, dessen Ergebnisse Sie anzeigen möchten.",
  },

  {
    id: "Product Tracking",
    EN: "Product Tracking",
    TR: "Ürün Takibi",
    DE: "Produktverfolgung",
  },
  {
    id: "Remove Tracking!",
    EN: "Remove Tracking!",
    TR: "Takibi Kaldır!",
    DE: "Verfolgung entfernen!",
  },
  {
    id: "The product was successfully tracked!",
    EN: "The product was successfully tracked!",
    TR: "Ürün başarıyla takibe alındı!",
    DE: "Das Produkt wurde erfolgreich verfolgt!",
  },
  {
    id: "Product Group",
    EN: "Product Group",
    TR: "Ürün Grubu",
    DE: "Produktgruppe",
  },
  {
    id: "Create ASIN List",
    EN: "Create ASIN List",
    TR: "ASIN listesi oluştur",
    DE: "ASIN-Liste erstellen",
  },

  {
    id: "View and manage the products you tracking.",
    EN: "View and manage the products you are tracking.",
    TR: "Takip ettiğiniz ürünleri görüntüleyin ve yönetin.",
    DE: "Anzeigen und verwalten Sie die Produkte, die Sie verfolgen.",
  },
  {
    id: "Tracked Date",
    EN: "Tracked Date",
    TR: "Takip Edilen Tarih",
    DE: "Verfolgtes Datum",
  },
  {
    id: "Results",
    EN: "Results",
    TR: "Sonuçlar",
    DE: "Ergebnisse",
  },
  {
    id: "Manage Tracking",
    EN: "Manage Tracking",
    TR: "Takibi Yönet",
    DE: "Verfolgung verwalten",
  },
  {
    id: "Tracked Products",
    EN: "Tracked Products",
    TR: "Takip Edilen Ürünler",
    DE: "Verfolgte Produkte",
  },
  {
    id: "Selling Actions",
    EN: "Selling Actions",
    TR: "Satış İşlemleri",
    DE: "Verkaufsaktionen",
  },
  {
    id: "Buying Actions",
    EN: "Buying Actions",
    TR: "Satın Alma İşlemleri",
    DE: "Kaufaktionen",
  },
  {
    id: "Logic",
    EN: "Logic",
    TR: "Mantık",
    DE: "Logik",
  },
  {
    id: "Product Metric",
    EN: "Product Metric",
    TR: "Ürün Metrikleri",
    DE: "Produktmetriken",
  },
  {
    id: "Add",
    EN: "Add",
    TR: "Ekle",
    DE: "Hinzufügen",
  },

  {
    id: "BuyBox Price",
    EN: "BuyBox Price",
    TR: "BuyBox Fiyatı",
    DE: "BuyBox-Preis",
  },
  {
    id: "Lowest FBA Price",
    EN: "Lowest FBA Price",
    TR: "En Düşük FBA Fiyatı",
    DE: "Niedrigster FBA-Preis",
  },
  {
    id: "Lowest FBM Price",
    EN: "Lowest FBM Price",
    TR: "En Düşük FBM Fiyatı",
    DE: "Niedrigster FBM-Preis",
  },
  {
    id: "Less",
    EN: "Less",
    TR: "Daha Azı",
    DE: "Weniger",
  },
  {
    id: "Fixed",
    EN: "Fixed",
    TR: "Sabit",
    DE: "Fest",
  },
  {
    id: "How Does It Work?",
    EN: "How Does It Work?",
    TR: "Nasıl Çalışır?",
    DE: "Wie funktioniert es?",
  },
  {
    id: "Track for:",
    EN: "Track for:",
    TR: "Takip Süresi:",
    DE: "Verfolgen für:",
  },
  {
    id: "1 week",
    EN: "1 week",
    TR: "1 hafta",
    DE: "1 Woche",
  },
  {
    id: "2 weeks",
    EN: "2 weeks",
    TR: "2 hafta",
    DE: "2 Wochen",
  },
  {
    id: "1 month",
    EN: "1 month",
    TR: "1 ay",
    DE: "1 Monat",
  },
  {
    id: "3 months",
    EN: "3 months",
    TR: "3 ay",
    DE: "3 Monate",
  },
  {
    id: "6 months",
    EN: "6 months",
    TR: "6 ay",
    DE: "6 Monate",
  },
  {
    id: "Selected Marketplaces",
    EN: "Selected Marketplaces",
    TR: "Seçili Pazar Yerleri",
    DE: "Ausgewählte Marktplätze",
  },
  {
    id: "Specify the value to track here.",
    EN: "Specify the value to track here.",
    TR: "Burada takip edilecek değeri belirtin.",
    DE: "Geben Sie hier den zu verfolgenden Wert an.",
  },
  {
    id: "Fixed = Below or above the value I entered; For example, under or over $1500.",
    EN: "Fixed = Below or above the value I entered; For example, under or over $1500.",
    TR: "Sabit = Girdiğim değerin altında ya da üstünde; Örneğin, 1500$'ın altında ya da üstünde.",
    DE: "Fest = Unter oder über dem eingegebenen Wert; Zum Beispiel unter oder über 1500$.",
  },
  {
    id: "Percentage (%) = Percentage decrease or increase, For example, if it falls 10% below or rises above the current value.",
    EN: "Percentage (%) = Percentage decrease or increase, For example, if it falls 10% below or rises above the current value.",
    TR: "Yüzde (%) = Yüzde olarak düşüş ya da artış; Örneğin, mevcut değerin %10 altına düşerse ya da üzerine çıkarsa.",
    DE: "Prozent (%) = Prozentuale Abnahme oder Zunahme; Zum Beispiel, wenn es 10% unter den aktuellen Wert fällt oder darüber steigt.",
  },
  {
    id: "Select whether it will be below or above the value you entered in the 'Value' field.",
    EN: "Select whether it will be below or above the value you entered in the 'Value' field.",
    TR: "'Değer' alanına girdiğiniz değerin altında mı yoksa üstünde mi olacağını seçin.",
    DE: "Wählen Sie, ob es unter oder über dem Wert liegt, den Sie im Feld 'Wert' eingegeben haben.",
  },
  {
    id: "Less = If it goes below the value I gave.",
    EN: "Less = If it goes below the value I gave.",
    TR: "Daha Azı = Verdiğim değerin altına düşerse.",
    DE: "Weniger = Wenn es unter den von mir angegebenen Wert fällt.",
  },
  {
    id: "More = If it exceeds the value I give.",
    EN: "More = If it exceeds the value I give.",
    TR: "Daha Fazlası = Verdiğim değerin üzerine çıkarsa.",
    DE: "Mehr = Wenn es den von mir angegebenen Wert überschreitet.",
  },
  {
    id: "You can track this product in multiple Amazon locales.",
    EN: "You can track this product in multiple Amazon locales.",
    TR: "Bu ürünü birden fazla Amazon mağazasında takip edebilirsiniz.",
    DE: "Sie können dieses Produkt in mehreren Amazon-Regionen verfolgen.",
  },
];
const lang =
  localStorage.getItem("lang") || localStorage.getItem("browserlang") || "EN";

const translate = (id) => {
  return translations?.find((t) => t.id === id)?.[lang] || id;
};

export default translate;

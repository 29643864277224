import React, { useState, useEffect } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import translate from "../../../shared/utils/translations";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, Menu, Button, Tooltip } from "antd";
import EmptyIcon from "../../../../assets/img/empty.png";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";

const TrackingTable = ({
  data,
  isTrackingModal,
  setTrackingModal,
  handleApplyTracking,
  handleCancelTracking,
}) => {
  let navigate = useNavigate();
  const [isTableSort, setTableSort] = useState(() => {
    const savedSort = localStorage.getItem("tableSort");
    return savedSort !== null ? savedSort : "list";
  });
  useEffect(() => {
    localStorage.setItem("tableSort", isTableSort);
  }, [isTableSort]);

  const columns = [
    {
      title: translate("Image"),
      dataIndex: "image",
      key: "image",
      render: (src) => (
        <>
          <img className="product-photo" src={src} alt="" />
        </>
      ),
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (item) => (
        <>
          <div className="column_info">ASIN</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Product Name"),
      dataIndex: "title",
      key: "title",
      render: (item) => (
        <>
          <div className="column_info">{translate("Product Name")}</div>
          <div className="prddt_title">{item}</div>
        </>
      ),
    },
    {
      title: translate("Tracked Date"),
      dataIndex: "trackedDate",
      key: "trackedDate",
      render: (item) => (
        <>
          <div className="column_info">{translate("Tracked Date")}</div>
          <div className="trckd_date">{item}</div>
        </>
      ),
    },

    {
      title: translate("Action"),
      key: "action",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>

          <div className="flex_table_buttons new__fix">
            <Tooltip title={translate("Refresh")} placement="top">
              <Button
                icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-repeat "
                  >
                    <g>
                      <polyline points="17 1 21 5 17 9"></polyline>
                      <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                      <polyline points="7 23 3 19 7 15"></polyline>
                      <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
                    </g>
                  </svg>
                }
                className={"table_icon"}
              />
            </Tooltip>
            <Tooltip
              title={translate("Show Product Based Info")}
              placement="left"
            >
              <NavLink
                className={"pbi__link table_icon"}
                to={`/product-based-info/${record.asin}`}
                target="_blank"
              >
                <FeatherIcon icon={"package"} />
              </NavLink>
            </Tooltip>

            <Tooltip title={translate("Manage Tracking")} placement="top">
              <Button
                onClick={() => setTrackingModal(true)}
                icon={<FeatherIcon icon="activity" />}
                className={"table_icon"}
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        className={`dif__ablee ${isTableSort === "list" ? " " : " block_m"}`}
      >
        <div className="merged___area">
          <div className="table__sortn">
            <span
              onClick={() => setTableSort("list")}
              className={`${isTableSort === "list" ? "active" : ""}`}
            >
              <FeatherIcon icon={"list"} />
            </span>
            <span
              onClick={() => setTableSort("grid")}
              className={`${isTableSort === "grid" ? "active" : ""}`}
            >
              <FeatherIcon icon={"grid"} />
            </span>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            locale: {
              items_per_page: translate("Page"),
            },
            position: ["topRight", "bottomRight"],
          }}
          locale={{
            emptyText: (
              <span className="empty__tble">
                <img src={EmptyIcon} />
              </span>
            ),
          }}
          className="mob_bts has_pag_btn"
        />
      </div>
    </>
  );
};

export default TrackingTable;

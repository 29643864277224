import React, { useState } from "react";
import { Tooltip, Modal, Button, Input } from "antd";
import translate from "../../../../shared/utils/translations";
// erdem gy
const ManualOtherMarketplaces = ({ dummyCards }) => {
  const [isMarketplacesModal, setMarketplacesModal] = useState(false);

  return (
    <>
      <Modal
        title={translate("Marketplace Selections")}
        centered
        visible={isMarketplacesModal}
        onOk={() => setMarketplacesModal(false)}
        onCancel={() => setMarketplacesModal(false)}
        footer={null}
      >
        <div className="coppyy_Asin_Area">
          <p className="ant-copiedd-text">
            {translate(
              "Your marketplaces that you can use for your connected account."
            )}
          </p>
          <div className="mrktss__sare">
            <div className="setting__fieldset">
              <div className="radio__boxt rrt">
                {/* ITEM 1 */}
                <label className={`checkbox s_check rd`} for="com">
                  <input
                    className="checkbox__input"
                    type="checkbox"
                    name="marketplaces"
                    id="com"
                    defaultChecked
                  />

                  <span className="checkbox__inner">
                    <svg className="icon icon-plus">
                      <use xlinkHref="#icon-plus"></use>
                    </svg>
                    <svg className="icon icon-check">
                      <use xlinkHref="#icon-check"></use>
                    </svg>
                    <span className="checkbox__text">amazon.com</span>
                  </span>
                </label>

                {/* ITEM 2 */}
                <label className={`checkbox s_check rd`} for="co_uk">
                  <input
                    className="checkbox__input"
                    type="checkbox"
                    name="marketplaces"
                    id="co_uk"
                  />

                  <span className="checkbox__inner">
                    <svg className="icon icon-plus">
                      <use xlinkHref="#icon-plus"></use>
                    </svg>
                    <svg className="icon icon-check">
                      <use xlinkHref="#icon-check"></use>
                    </svg>
                    <span className="checkbox__text">amazon.co.uk</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="market__cards_wrappera">
        <div className="mrkt__hdr">
          <div>
            <h4>{translate("Also Calculate Other Marketplaces")}</h4>
            <p>
              {translate(
                "Also quick view calculation results for other marketplaces."
              )}
            </p>
          </div>

          <button
            onClick={() => setMarketplacesModal(true)}
            className="button-stroke button-small onex_wl__button button_upgtty"
          >
            {translate("Add / Remove Marketplaces")}
          </button>
        </div>

        {dummyCards?.length >= 1 ? (
          <>
            <div className="market__cards_area">
              {dummyCards?.map((card, index) => (
                <div key={index} className="mini_market__card_web">
                  <div className="mini_market__card_body_web">
                    <div className="m_markt_hheader">
                      <a
                        href="https://www.amazon.ca"
                        target="_blank"
                        className="m_markt_name_web"
                      >
                        {card?.domain}
                      </a>
                    </div>
                    <div className="m_markt_infos">
                      <div className="m_markt_lis_web">
                        <div className="m_markt_lis_web_item_web">
                          <h6>ROI</h6>
                          <div
                            className={`def_vlluee_web roi__vlle_web ${
                              parseFloat(card?.roi) <= 1
                                ? "red"
                                : parseFloat(card?.roi) > 1 &&
                                  parseFloat(card?.roi) <= 30
                                ? "orange"
                                : parseFloat(card?.roi) > 30 &&
                                  parseFloat(card?.roi) <= 50
                                ? "orange-soft"
                                : parseFloat(card?.roi) > 50 &&
                                  parseFloat(card?.roi) <= 75
                                ? "green-soft"
                                : parseFloat(card?.roi) > 75
                                ? "green"
                                : "default"
                            }`}
                          >
                            {card?.roi ? card?.roi : " - "}
                          </div>
                        </div>
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("Profit")}</h6>
                          <div
                            className={`def_vlluee_web prft__vlle_web ${
                              parseFloat(card?.profit) <= 0
                                ? "red"
                                : parseFloat(card?.profit) > 0
                                ? "green"
                                : "default"
                            }`}
                          >
                            {card?.profit}
                          </div>
                        </div>
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("Price")}</h6>
                          <div className="def_vlluee_web">
                            {card?.price + " " + card?.currency}
                          </div>
                        </div>
                      </div>
                      <div className="m_markt_lis_web">
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("Category")}</h6>
                          <div className="def_vlluee_web">
                            {card?.salesRankCategory
                              ? card?.salesRankCategory
                              : " - "}
                          </div>
                        </div>
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("Sales Rank")}</h6>
                          <div className="def_vlluee_web">
                            {card?.salesRank ? card?.salesRank : " - "}
                          </div>
                        </div>
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("BSR Drops")}</h6>
                          <div className="def_vlluee_web">
                            {card?.salesRankDrops30
                              ? card?.salesRankDrops30 +
                                " / " +
                                card?.salesRankDrops90 +
                                " / " +
                                card?.salesRankDrops180
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="m_markt_footer_web">
                      <button className="button-stroke button-small onex_wl__button button_upgtty">
                        {translate("Fetch Data")}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div
              onClick={() => setMarketplacesModal(true)}
              className="more_markets_btn_web"
              role="button"
            >
              <div className="earth_markets_vector_web css_vals_web"></div>
              <div className="more_markets_middle_web">
                <div className="icn__add_m_web"></div>
                <h4>{translate("Click Here to Add..")}</h4>

                <p>
                  {translate(
                    "Quickly calculate other marketplaces that come with your connected account."
                  )}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ManualOtherMarketplaces;

import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import translate from "../../../shared/utils/translations";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
// erdem gy
const BarcodeReaderProducts = ({ setPage }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("load", handleScrollToTop);

    return () => window.removeEventListener("load", handleScrollToTop);
  }, []);

  return (
    <>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper readr_err">
            <div className="barcode_readder_wrapper">
              <div className="barcode_readr__body">
                <div className="rdr_b_hdr">
                  <h5>{translate("Product Selection")}</h5>

                  <p>
                    {translate(
                      "Click on the product whose results you want to view."
                    )}
                  </p>
                </div>
                <div className="rdr_b_middle">
                  <div className="prdcts__care">
                    <div className="prdt__itm">
                      <div className="prdt__left">
                        <div className="prdtcs__immg">
                          <img src="/assets/img/pp_1.jpg" />
                        </div>

                        <div className="prdt__infss">
                          <div className="onex_ts__prd_h">
                            Amazon Basics Micro SDXC Memory Card with Full Size
                            Adapter, A2, U3, Read Speed up to 100 MB/s, 128 GB,
                            Black
                          </div>
                          <div className="onex_ts__prd_p">
                            Compatible with smartphones, tablets, cameras,
                            GoPro/action cameras, laptops, desktop computers.
                          </div>
                        </div>
                      </div>
                      <div className="prdt__right">
                        {" "}
                        <NavLink
                          className="prdt_link"
                          to={`/product-based-info-manual/${"B0CV4FQPY1"}`}
                        >
                          {" "}
                          <FeatherIcon
                            icon="chevron-right"
                            className={"icon f_icn"}
                          />
                        </NavLink>
                      </div>
                    </div>
                    <div className="prdt__itm">
                      <div className="prdt__left">
                        <div className="prdtcs__immg">
                          <img src="/assets/img/pp_2.jpg" />
                        </div>

                        <div className="prdt__infss">
                          <div className="onex_ts__prd_h">
                            Amazon Basics Micro SDXC Memory Card with Full Size
                            Adapter, A2, U3, Read Speed up to 100 MB/s, 128 GB,
                            Black
                          </div>
                          <div className="onex_ts__prd_p">
                            Compatible with smartphones, tablets, cameras,
                            GoPro/action cameras, laptops, desktop computers.
                          </div>
                        </div>
                      </div>
                      <div className="prdt__right">
                        {" "}
                        <NavLink
                          className="prdt_link"
                          to={`/product-based-info-manual/${"B0CV4FQPY1"}`}
                        >
                          {" "}
                          <FeatherIcon
                            icon="chevron-right"
                            className={"icon f_icn"}
                          />
                        </NavLink>
                      </div>
                    </div>
                    <div className="prdt__itm">
                      <div className="prdt__left">
                        <div className="prdtcs__immg">
                          <img src="/assets/img/pp_3.jpg" />
                        </div>

                        <div className="prdt__infss">
                          <div className="onex_ts__prd_h">
                            Amazon Basics Micro SDXC Memory Card with Full Size
                            Adapter, A2, U3, Read Speed up to 100 MB/s, 128 GB,
                            Black
                          </div>
                          <div className="onex_ts__prd_p">
                            Compatible with smartphones, tablets, cameras,
                            GoPro/action cameras, laptops, desktop computers.
                          </div>
                        </div>
                      </div>
                      <div className="prdt__right">
                        {" "}
                        <NavLink
                          className="prdt_link"
                          to={`/product-based-info-manual/${"B0CV4FQPY1"}`}
                        >
                          {" "}
                          <FeatherIcon
                            icon="chevron-right"
                            className={"icon f_icn"}
                          />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rdr_b_footr">
                  <div className="rdr__dots">
                    <div className="rdr__dot_itm"></div>
                    <div className="rdr__dot_itm"></div>
                    <div className="rdr__dot_itm active"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarcodeReaderProducts;

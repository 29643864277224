import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import TrackingTable from "./_tracking_table";

import translate from "../../../shared/utils/translations";
import { requestHandler } from "../../../shared/utils/helpers";
import Swal from "sweetalert2";
import TrackingModal from "./_tracking_modal";

const Tracking = ({ setPage }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });

  const [isTrackingModal, setTrackingModal] = useState(false);
  const [isProductTracking, setProductTracking] = useState(false);
  const handleApplyTracking = () => {
    toast.success(translate("The product was successfully tracked!"));
    setTrackingModal(false);
    setProductTracking(true);
  };

  const handleCancelTracking = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setTrackingModal(false);
        setProductTracking(false);
      }
    });
  };

  const dummyData = [
    {
      key: "1",
      image: "/assets/img/figure-2.png",
      asin: "B08NT2K2CL",
      title: "Yellow Couch",
      trackedDate: "13.09.2024",
      results: "RESULTS",
    },
  ];

  return (
    <>
      <TrackingModal
        isTrackingModal={isTrackingModal}
        setTrackingModal={setTrackingModal}
        handleApplyTracking={handleApplyTracking}
        handleCancelTracking={handleCancelTracking}
      />
      <div className="outer__inner trckdd_wrapper">
        <div className="onex_wl bg_white hstrry">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">
                {translate("Product Tracking")}
              </h2>

              <div className="keys__info">
                {translate("View and manage the products you tracking.")}
              </div>
            </div>

            {/* <div className="keys__info w_cl horizontal__Scroll">
              <div className="page_details">
                <div className="item_c">
                  <h4>{translate("Tracked Products")}</h4>
                  <p>3</p>
                </div>
                <div className="item_c">
                  <h4>{translate("Usage Limit")}</h4>
                  <p>3 / 5</p>
                </div>
              </div>
            </div> */}

            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__info"></div>
                <div className="keys__list">
                  <div className="keys__item ss">
                    <TrackingTable
                      data={dummyData}
                      isTrackingModal={isTrackingModal}
                      setTrackingModal={setTrackingModal}
                      handleApplyTracking={handleApplyTracking}
                      handleCancelTracking={handleCancelTracking}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tracking;

import React, { useEffect, useLayoutEffect, useState } from "react";
import { Drawer, Tooltip } from "antd";
import Input from "../../../common/form_elements/input";
import Select from "../../../common/form_elements/select";
import translate from "../../utils/translations";
import FeatherIcon from "feather-icons-react";
import { notify, requestHandler } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";

// erdem gy

const SettingFormManual = ({
  isSettingFormManual,
  setSettingFormManual,
  isBtnLoading,
  submit,
}) => {
  const [formData, setFormData] = useState({});

  const otherOnChange = (e) => {
    let { name, value } = e.target;
    console.log("name", name, "value", value, typeof value);
    if (
      name === "include_discounts" ||
      name === "uses_keepa" ||
      name === "is_fba"
    ) {
      value = value === "true" ? true : false;
    }
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Drawer
        title={translate("Manual Calculation Settings")}
        placement="right"
        onClose={() => setSettingFormManual(false)}
        open={isSettingFormManual}
        width={"50%"}
        className="payment__-drw tkns"
      >
        <>
          <div className={`buy_tokens_detials`}>
            <div className="mang_setting_area">
              <div className="setting__section">
                <div className="setting__fieldset">
                  <div className="setting__row">
                    <Select
                      label={translate("Marketplace for Setting")}
                      options={["amazon.com", "amazon.co.uk"]}
                      name="marketplace_domain"
                      value={"amazon.com"}
                    />
                  </div>
                </div>

                <div className="setting__category tbg upt">
                  {translate("Shipping Pricing Settings")}
                  <p>
                    {translate(
                      "Select the shipping pricing settings you want to use for your products."
                    )}
                  </p>

                  <div
                    className="setting__fieldset"
                    style={{ marginTop: "25px" }}
                  >
                    <div className="setting__row">
                      <Select
                        label={translate("Currency")}
                        options={["USD", "GBP"]}
                        name="marketplace_domain"
                        value={"USD"}
                      />
                    </div>
                  </div>
                </div>

                <div className="setting__fieldset">
                  <div className="radio__boxt">
                    <label className={`checkbox s_check rd`} for="shipping1">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="shipping_type"
                        id="shipping1"
                        checked={formData?.shipping_type === "fixed"}
                        onChange={otherOnChange}
                        value="fixed"
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>

                        <Tooltip
                          title={translate(
                            "Apply a constant shipping rate for each product, irrespective of its weight or size. Specify the fixed shipping cost in your preferred currency for each item."
                          )}
                          placement="top"
                        >
                          <span className="checkbox__text">
                            {translate("Fixed Shipping Rate")}{" "}
                            <FeatherIcon icon={"help-circle"} />
                          </span>
                        </Tooltip>
                      </span>

                      <input
                        className="field__input"
                        type="number"
                        placeholder={translate("10 etc.")}
                        name="fixed_deal"
                        onChange={otherOnChange}
                        value={formData?.fixed_deal}
                      />
                    </label>
                    <label className={`checkbox s_check rd`} for="shipping2">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="shipping_type"
                        id="shipping2"
                        checked={formData?.shipping_type === "weight"}
                        onChange={otherOnChange}
                        value="weight"
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>

                        <Tooltip
                          title={translate(
                            "Calculate the shipping cost based on the weight of the product. Enter a rate in your preferred currency per kilogram. This rate encompasses the cost of labeling each product."
                          )}
                          placement="top"
                        >
                          <span className="checkbox__text">
                            {translate("Weight-Based Shipping")}{" "}
                            <FeatherIcon icon={"help-circle"} />
                          </span>
                        </Tooltip>
                      </span>

                      <input
                        className="field__input"
                        type="number"
                        placeholder={translate("10 etc.")}
                        name="shipping_deal"
                        onChange={otherOnChange}
                        value={formData?.shipping_deal}
                      />
                    </label>
                    <label className={`checkbox s_check rd`} for="shipping3">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="shipping_type"
                        id="shipping3"
                        onChange={otherOnChange}
                        checked={formData?.shipping_type === "weightLabel"}
                        value="weightLabel"
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>

                        <Tooltip
                          title={translate(
                            "Determine shipping cost based on product weight and include a distinct fee for labeling. Provide two rates: a weight-based shipping rate in your preferred currency per kilogram and an additional fixed labeling fee per item. This option offers a detailed cost breakdown, separating weight handling charges from labeling costs."
                          )}
                          placement="top"
                        >
                          <span className="checkbox__text">
                            {translate("Weight-Based + Labeling")}{" "}
                            <FeatherIcon icon={"help-circle"} />
                          </span>
                        </Tooltip>
                      </span>

                      <div className="flex___inpts">
                        <input
                          className="field__input"
                          type="number"
                          placeholder={translate("10 etc.")}
                          name="shipping_deal"
                          onChange={otherOnChange}
                          value={formData?.shipping_deal}
                        />
                        <input
                          className="field__input"
                          type="number"
                          placeholder={translate("10 etc.")}
                          name="labeling_deal"
                          onChange={otherOnChange}
                          value={formData?.labeling_deal}
                        />
                      </div>
                    </label>
                  </div>
                </div>

                <div className="setting__category tbg upt">
                  {translate("FBA/FBM Setting")}

                  <p>
                    {translate(
                      "Switching the Fulfillment Type for Amazon Listings (FBA/FBM)"
                    )}
                  </p>
                </div>

                <div className="setting__fieldset">
                  <div className="radio__boxt rrt">
                    <div
                      className={`fba__Select_Area ${
                        formData?.shipping_type === "customWeightMap"
                          ? " not__aallow"
                          : " "
                      }`}
                    >
                      <label className={`checkbox s_check rd`} for="fba">
                        <input
                          className="checkbox__input"
                          type="radio"
                          name="is_fba"
                          id="fba"
                          onChange={otherOnChange}
                          checked={formData?.is_fba}
                          value={true}
                        />

                        <span className="checkbox__inner">
                          <svg className="icon icon-plus">
                            <use xlinkHref="#icon-plus"></use>
                          </svg>
                          <svg className="icon icon-check">
                            <use xlinkHref="#icon-check"></use>
                          </svg>
                          <span className="checkbox__text">
                            {translate("FBA (Fullfilled by Amazon)")}
                          </span>
                        </span>
                      </label>
                      {formData?.shipping_type === "customWeightMap" ? (
                        <div className="fbaam__warn">
                          <FeatherIcon icon={"alert-circle"} />
                          {translate(
                            "If Custom Shipping Setting is active, you can't select FBA!"
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <label className={`checkbox s_check rd`} for="fbm">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="is_fba"
                        id="fbm"
                        checked={!formData?.is_fba}
                        onChange={otherOnChange}
                        value={false}
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>
                        <span className="checkbox__text">
                          {translate("FBM (Fulfilled by Merchant)")}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>

                <div className="setting__category tbg upt">
                  {translate("Show Keepa Data?")}

                  <p>
                    {translate(
                      "Do you want to include historical data by Keepa in Product Based Info?"
                    )}
                  </p>
                </div>
                <div className="setting__fieldset">
                  <div className="radio__boxt rrt">
                    <label className={`checkbox s_check rd`} for="show-keepa">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="uses_keepa"
                        id="show-keepa"
                        checked={formData?.uses_keepa}
                        onChange={otherOnChange}
                        value={true}
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>
                        <span className="checkbox__text">
                          {translate("Show")}
                        </span>
                      </span>
                    </label>
                    <label className={`checkbox s_check rd`} for="hide-keepa">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="uses_keepa"
                        id="hide-keepa"
                        checked={!formData?.uses_keepa}
                        onChange={otherOnChange}
                        value={false}
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>
                        <span className="checkbox__text">
                          {translate("Hide")}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>

                <div className="setting__category tbg upt">
                  {translate("VAT Settings")}

                  <p>
                    {translate(
                      "Please select correct VAT settings for your seller account."
                    )}
                  </p>
                </div>
                <div className="setting__fieldset">
                  <div className="radio__boxt">
                    <label className={`checkbox s_check rd`} for="vat1">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="vat_type"
                        onChange={otherOnChange}
                        checked={formData?.vat_type === "noVat"}
                        id="vat1"
                        value="noVat"
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>

                        <Tooltip
                          title={translate(
                            "If you’re a UK seller and you’re not registered for VAT then you need to select the Non-VAT Registered scheme. As a non-registered VAT seller, you still have to pay VAT on the cost of the product and you have to pay 20% VAT on top of the Amazon fees(if you’re a UK-based company)"
                          )}
                          placement="top"
                        >
                          <span className="checkbox__text">
                            {translate("Exempt from VAT (No VAT)")} (%){" "}
                            <FeatherIcon icon={"help-circle"} />
                          </span>
                        </Tooltip>
                      </span>

                      <strong>0%</strong>
                    </label>
                    <label className={`checkbox s_check rd`} for="vat2">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="vat_type"
                        onChange={otherOnChange}
                        checked={formData?.vat_type === "vatOnProfit"}
                        id="vat2"
                        value="vatOnProfit"
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>

                        <Tooltip
                          title={translate(
                            "When sending goods to a US fulfillment center, you don’t have to pay sales tax in the US. However, as soon as you ship inventory to Europe (for example to a fulfillment center when using Fulfillment by Amazon, or FBA), you must pay that country’s import VAT."
                          )}
                          placement="top"
                        >
                          <span className="checkbox__text">
                            {translate("VAT on profit")} (%){" "}
                            <FeatherIcon icon={"help-circle"} />
                          </span>
                        </Tooltip>
                      </span>

                      <input
                        className="field__input"
                        type="number"
                        placeholder={translate("10 etc.")}
                        name="vat_percentage"
                        onChange={otherOnChange}
                        value={formData?.vat_percentage}
                      />
                    </label>
                    <label className={`checkbox s_check rd`} for="vat3">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="vat_type"
                        onChange={otherOnChange}
                        checked={formData?.vat_type === "vatOnSales"}
                        id="vat3"
                        value="vatOnSales"
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>

                        <Tooltip
                          title={translate(
                            "Unlike in the US, where sales tax is added to the list price, VAT is included in the list price in the EU. Let’s say that you sell a product on Amazon.com for $100. To sell it for the equivalent price in the UK, you would list it on Amazon.co.uk for $120 (assuming 20% VAT). This is important to remember when analyzing competitive pricing on Amazon’s European marketplaces."
                          )}
                          placement="top"
                        >
                          <span className="checkbox__text">
                            {translate("VAT on sales")} (%){" "}
                            <FeatherIcon icon={"help-circle"} />
                          </span>
                        </Tooltip>
                      </span>

                      <input
                        className="field__input"
                        type="number"
                        placeholder={translate("10 etc.")}
                        name="vat_percentage"
                        onChange={otherOnChange}
                        value={formData?.vat_percentage}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="drw__btm_actns">
              <button className="button setting__button" onClick={submit}>
                {translate("Save")}{" "}
                {isBtnLoading && <span className="btn-spinner"></span>}
              </button>
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default SettingFormManual;
